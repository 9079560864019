/**
* Created on Sat Jan 23 2021 18:29:58
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Box from "../../shared/models/Box";
import Customer from "../../shared/models/Customer";
import Pagination from "../../shared/models/Pagination";
import RequestParams from "../../shared/models/RequestParams";

export const ERROR_BOXES = '@@boxes/ERROR_BOXES';
export type ERROR_BOXES = typeof ERROR_BOXES;

export const LOAD_BOXES = '@@boxes/LOAD_BOXES';
export type LOAD_BOXES = typeof LOAD_BOXES;

export const LOAD_BOXES_SUCCESS = '@@boxes/LOAD_BOXES_SUCCESS';
export type LOAD_BOXES_SUCCESS = typeof LOAD_BOXES_SUCCESS;

export const ADD_BOXES = '@@boxes/ADD_BOXES';
export type ADD_BOXES = typeof ADD_BOXES;

export const ADD_BOXES_SUCCESS = '@@boxes/ADD_BOXES_SUCCESS';
export type ADD_BOXES_SUCCESS = typeof ADD_BOXES_SUCCESS;

export const LOAD_CUSTOMERS = '@@boxes/LOAD_CUSTOMERS';
export type LOAD_CUSTOMERS = typeof LOAD_CUSTOMERS;

export const LOAD_CUSTOMERS_SUCCESS = '@@boxes/LOAD_CUSTOMERS_SUCCESS';
export type LOAD_CUSTOMERS_SUCCESS = typeof LOAD_CUSTOMERS_SUCCESS;

export const errorBoxesAction = createAction(ERROR_BOXES)<string>();

export const loadBoxesAction = createAction(LOAD_BOXES)<RequestParams>();
export const loadBoxesSuccessAction = createAction(LOAD_BOXES_SUCCESS)<Pagination<Box>>();

export const loadCustomersAction = createAction(LOAD_CUSTOMERS)();
export const loadCustomersSuccessAction = createAction(LOAD_CUSTOMERS_SUCCESS)<Customer[]>();

export const addBoxesAction = createAction(ADD_BOXES)<Object>();
export const addBoxesSuccessAction = createAction(ADD_BOXES_SUCCESS)<Box[]>();

export type BoxesAction = ActionType<
    | typeof loadBoxesAction
    | typeof loadBoxesSuccessAction
    | typeof errorBoxesAction
    | typeof loadCustomersAction
    | typeof loadCustomersSuccessAction
    | typeof addBoxesAction
    | typeof addBoxesSuccessAction
>;