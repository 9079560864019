/**
* Created on Fri Feb 05 2021 10:03:34
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import OrderParams from "./OrderParams";
import PageParams from "./PageParams";

export default class RequestParams {

    public page?: PageParams;
    public order?: OrderParams;
    constructor(
        public otherParams: string = ""
    ){}

    public params(): string{
        let returnParams = this.otherParams
        if (this.page){
            returnParams = this.page.params(returnParams)
        }
        if (this.order){
            returnParams = this.order.params(returnParams)
        }
        
        return returnParams === "" ? "" : "?"+returnParams
    }

}