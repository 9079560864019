/**
 * Created on Wed Feb 17 2021 11:14:11
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import React, {useEffect, useState} from 'react';
import {FaClipboardCheck, FaTimes, FaTrashAlt} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Button from '../../../shared/components/buttons/Button';
import {Card, SubTitle} from '../../../shared/components/card';
import Image from '../../../shared/components/Image';
import {PageContent} from '../../../shared/components/page';
import Staff from '../../../shared/components/Staff';
import Box from '../../../shared/models/Box';
import BoxState, {StatesNum} from '../../../shared/models/BoxState';
import RequestParams from '../../../shared/models/RequestParams';
import {deleteBoxAction, loadBoxAction, updateBoxAction} from '../../actions/box.actions';
import {getBox, loadedBox} from '../../selectors/box.selectors';
import BoxContents from './boxcontents';
import BoxImages from './BoxImages';
import Informations from './Informations';
import Position from './Postition';
import History from './history';
import YesNo from '../../../shared/components/overlays/YesNo';
import MetaData from './MetaData';
import {isStaff} from '../../../shared/selectors/auth.selector';

interface RouterProps {
    id: string;
}

interface BoxProps extends RouteComponentProps<RouterProps> {
}

function BoxDetail(
    {
        match
    }: BoxProps) {

    const id = parseInt(match.params.id)
    const box = useSelector(getBox);
    const loaded = useSelector(loadedBox)
    const staff = useSelector(isStaff)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadBoxAction(id, new RequestParams()))
    }, [dispatch, id])

    const showPosition = () => {
        if (box?.position) {
            return true
        }
        return false
    }

    const handleOnClickMarked = () => {
        const b: Box = Object.assign(box!)
        b.state = BoxState.fromKey("MARKED_FOR_DESTROYED")
        dispatch(updateBoxAction(b))
    }

    const [showYesNoDestroy, setShowYesNoDestroy] = useState(false);

    const handleDestroy = () => {
        const b: Box = Object.assign(box!)
        b.state = BoxState.fromKey("DESTROYED")
        dispatch(updateBoxAction(b))
        setShowYesNoDestroy(false)
    }

    const [showYesNoDelete, setShowYesNoDelete] = useState(false);

    const handleDelete = () => {
        dispatch(deleteBoxAction(box!) )
        setShowYesNoDelete(false)
    }

    return (
        <div>
            <YesNo
                title="Vernichten"
                text={` Box ${box?.code} wirklich vernichten?`}
                type="danger"
                open={showYesNoDestroy}
                onClose={() => setShowYesNoDestroy(false)}
                onYes={() => handleDestroy()}
                onNo={() => setShowYesNoDestroy(false)}
            />
            <YesNo
                title={"Löschen"}
                text={` Box ${box?.code} wirklich löschen? `}
                type="danger"
                open={showYesNoDelete}
                onClose={() => setShowYesNoDelete(false)}
                onYes={() => handleDelete()}
                onNo={() => setShowYesNoDelete(false)}
            />
            <PageContent loading={!loaded}>
                <Card
                    title={
                        <div className="flex items-center justify-between">
                            <div>
                                Box {box?.formatCode()}
                            </div>
                            <Staff>
                                <div className="flex gap-x-2">
                                    {box?.state.num !== StatesNum.DESTROYED &&
                                    <div className="flex gap-x-2">
                                        <Button
                                            onClick={handleOnClickMarked}
                                            color="warning"
                                            icon={<FaClipboardCheck/>}
                                        >
                                            {box?.state.num !== StatesNum.MARKED_FOR_DESTROYED ?
                                                <div>
                                                    Box markieren für Vernichtung
                                                </div>
                                                :
                                                <div>
                                                    Box markieren für Vernichtung (reset)
                                                </div>
                                            }
                                        </Button>
                                        <Button
                                            onClick={() => setShowYesNoDestroy(true)}
                                            color="danger"
                                            icon={<FaTrashAlt/>}
                                        >
                                            Box vernichten
                                        </Button>
                                    </div>
                                    }
                                    {box?.state.num === StatesNum.CREATED &&
                                    <Button
                                        onClick={() => setShowYesNoDelete(true)}
                                        color="danger"
                                        icon={<FaTimes/>}
                                    >
                                        Box löschen
                                    </Button>
                                    }
                                </div>
                            </Staff>
                        </div>
                    }
                >
                    <div className="max-w-xs p-2">
                        <Image url={box?.barcode?.image!}/>
                    </div>
                    {showPosition() && staff ?
                        <div className="flex flex-col lg:flex-row">
                            <div className="w-full lg:w-1/2">
                                <SubTitle title="Informationen"/>
                                <div className="p-2">
                                    <Informations box={box!}/>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2">
                                <SubTitle title="Position"/>
                                <div className="p-2">
                                    <Position box={box!}/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="flex flex-col">
                            <SubTitle title="Informationen"/>
                            <div className="w-full lg:w-1/2">
                                <div className="p-2">
                                    <Informations box={box!}/>
                                </div>
                            </div>
                        </div>
                    }
                    {(box?.metaDestroyYear || box?.metaFirstDeliveryCustomer || box?.metaYear) &&
                    < div className="flex flex-col">
                        <SubTitle title="Meta Daten"/>
                        <div className="w-full lg:w-1/2">
                            <div className="p-2">
                                <MetaData box={box!}/>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-full lg:w-1/2">
                            <SubTitle title={`Inhaltsbilder ( ${box?.images!.length} )`}/>
                            <div className="p-2">
                                <BoxImages box={box!}/>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2">
                            <SubTitle title={`Inhalte ( ${box?.content.length} )`}/>
                            <div className="p-2">
                                <BoxContents box={box!}/>
                            </div>
                        </div>
                    </div>
                    <Staff>
                        <History box={box!}/>
                    </Staff>
                </Card>
            </PageContent>
        </div>
    )
}

export default withRouter(BoxDetail)