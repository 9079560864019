/**
* Created on Tue Apr 20 2021 22:51:34
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';

enum WeekdayText {
    MONDAY = "MO",
    TUESDAY = "DI",
    WEDNESDAY = "MI",
    THURSDAY = "DO",
    FRIDAY = "FR",
    SATURDAY = "SA",
    SUNDAY = "SO"
}

enum WeekdayNum {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 7
}

interface Props {
    name?: string,
    days?: number[],
    disabled?: boolean,
    title?: string,
    onChange?: (e: React.ChangeEvent<any> | any) => void,
    onBlur?: (e: React.FocusEvent<any> | any) => void,
    controlId?: string,
    required?: boolean,
    isInvalid?: boolean,
    error?: string | any,
}

export default function WeekdaySelect({
    days,
    disabled,
    title,
    name,
    onChange,
    onBlur,
    controlId,
    required,
    isInvalid,
    error,
}: Props) {

    const [selected, setSelected] = useState<number[]>([])

    useEffect(() => {
        if (days) {
            setSelected(days)
        }
    }, [days])

    const handleClick = (key: keyof typeof WeekdayText) => {
        const index = selected.indexOf(WeekdayNum[key])
        if (index === -1) {
            const newSelected = [...selected, WeekdayNum[key]].sort((a, b) => a - b)
            setSelected(newSelected)
            if (onChange) {
                onChange(newSelected)
            }
        } else {
            const newSelected = selected.filter((item) => item !== WeekdayNum[key]).sort((a, b) => a - b)
            setSelected(newSelected)
            if (onChange) {
                onChange(newSelected)
            }
        }
    }

    const bgClass = (key: keyof typeof WeekdayText) => {
        const index = selected.indexOf(WeekdayNum[key])
        if (index !== -1 && index < selected.length) {
            var c = "bg-gray-400 text-white"
            if (index + 1 === selected.length) {
                c = c + " rounded-r-full"
            }
            if (index === 0) {
                c = c + " rounded-l-full"
            }
            if (selected[index - 1] !== (selected[index] - 1)) {
                c = c + " rounded-l-full"
            }
            if (selected[index + 1] !== (selected[index] + 1)) {
                c = c + " rounded-r-full"
            }
            return c
        }
        return ""
    }

    return (
        <div>
            { title &&
                <div className="flex items-baseline justify-between">
                    <div className="font-medium text-gray-600">
                        Wochentage {required && "*"}
                    </div>
                    <div className="text-sm text-red-500">
                        {isInvalid &&
                            <div >
                                {error}
                            </div>
                        }
                    </div>
                </div>
            }
            <ul className={`flex my-1 rounded-full w-min ${isInvalid && error && "bg-red-100 border-2 border-red-500"} border-2 border-gray-200`}>
                {Object.entries(WeekdayText).map(
                    ([key, state], i) =>
                        <li key={`weekday-select-${i}`} className={`select-none px-2 ${!disabled && "cursor-pointer"} ${bgClass(key as keyof typeof WeekdayText)}`}
                            onClick={!disabled ? () => handleClick(key as keyof typeof WeekdayText) : () => { }}
                        >
                            {WeekdayText[key as keyof typeof WeekdayText]}
                        </li>
                )
                }
            </ul>
        </div>
    )
}