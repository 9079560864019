import axios from "axios";
import { combineEpics, Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import api from "../../api";
import { notifySuccessAction } from "../../shared/actions/notify.actions";
import Notification from "../../shared/models/Notification";
import Row from "../../shared/models/Row";
import { token } from "../../shared/utils";
import { handleRequestError } from "../../shared/utils/errors";
import { loadRowSuccessAction, LOAD_ROW, updateRowSuccessAction, UPDATE_ROW, UPDATE_ROW_SUCCESS } from "../actions/row.actions";

const loadRow: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_ROW),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/rows/${payload}/`
            return from(axios.get<Row>(url, token(state$))).pipe(
                map(({ data }) => loadRowSuccessAction(Row.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const updateRow: Epic = (action$, state$) =>
    action$.pipe(
        ofType(UPDATE_ROW),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/rows/${payload.id}/`
            return from(axios.patch<Row>(url, Row.toApi(payload), token(state$))).pipe(
                map(({ data }) => updateRowSuccessAction(Row.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const successUpdateRow: Epic = action$ =>
    action$.pipe(
        ofType(UPDATE_ROW_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Reihe aktualisiert.`)))
    )

export default combineEpics(
    loadRow,
    updateRow,
    successUpdateRow,
);