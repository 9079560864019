/**
* Created on Sat Jan 23 2021 18:34:50
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Area from "../../shared/models/Area";

export const ERROR = '@@areas/ERROR';
export type ERROR = typeof ERROR;

export const LOAD_AREAS = '@@areas/LOAD_AREAS';
export type LOAD_AREAS = typeof LOAD_AREAS;

export const LOAD_AREAS_SUCCESS = '@@areas/LOAD_AREAS_SUCCESS';
export type LOAD_AREAS_SUCCESS = typeof LOAD_AREAS_SUCCESS;

export const ADD_AREA = '@@areas/ADD_AREA';
export type ADD_AREA = typeof ADD_AREA;

export const ADD_AREA_SUCCESS = '@@areas/ADD_AREA_SUCCESS';
export type ADD_AREA_SUCCESS = typeof ADD_AREA_SUCCESS;

export const errorAction = createAction(ERROR)<string>();

export const loadAreasAction = createAction(LOAD_AREAS)<void>();
export const loadAreasSuccessAction = createAction(LOAD_AREAS_SUCCESS)<Area[]>();

export const addAreaAction = createAction(ADD_AREA)<Area>();
export const addAreaSuccessAction = createAction(ADD_AREA_SUCCESS)<Area>();

export type AreasAction = ActionType<
    | typeof errorAction
    | typeof loadAreasAction
    | typeof loadAreasSuccessAction
    | typeof addAreaAction
    | typeof addAreaSuccessAction
>;