/**
* Created on Fri Apr 09 2021 10:02:00
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Area from "../../shared/models/Area";

export function getArea(state: AppState): Area {
    return state.stock.area.data!
}

export function loadingArea(state: AppState): boolean {
    return state.stock.area.load.loading
}

export function loadedArea(state: AppState): boolean {
    return state.stock.area.load.loaded
}