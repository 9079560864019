/**
* Created on Sun Apr 18 2021 13:36:59
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { PageContent } from '../../../shared/components/page';
import { FcServices } from 'react-icons/fc';
import { useDispatch } from 'react-redux';
import { FaArrowRight, FaFilePdf } from 'react-icons/fa';
import { downloadCollectListAction } from '../../actions/services.actions';
import { history } from '../../../store/history';


export default function () {

    const dispatch = useDispatch()

    return (
        <PageContent>
            <div className="flex flex-col items-center py-16">
                <FcServices className="w-16 h-16" />
                <div className="text-2xl font-bold text-gray-600">
                    Services
                </div>
            </div>
            <div>
                <ul className="mx-20">
                    <li className="grid items-center grid-cols-3 pb-5 hover:underline">
                        <div className="pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/services/labelprints") }>
                            Etikettendruck
                        </div>
                        <div className="col-span-2">
                            <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer " onClick={() => history.push("/services/labelprints") }/>
                        </div>
                    </li>
                    <li className="grid items-center grid-cols-3 pb-5 hover:underline">
                        <div className="pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/services/boxesoverview") }>
                            Boxen Lager Übersicht
                        </div>
                        <div className="col-span-2">
                            <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer " onClick={() => history.push("/services/boxesoverview") }/>
                        </div>
                    </li>
                    <li className="grid items-center grid-cols-3 pb-5 hover:underline">
                        <div className="pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/services/archivelists") }>
                            Archivlisten / Inhaltsbilder
                        </div>
                        <div className="col-span-2" >
                            <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer " onClick={() => history.push("/services/archivelists") }/>
                        </div>
                    </li>
                    <li className="grid items-center grid-cols-3 pb-5 hover:underline">
                        <div className="pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/services/destroyrequests") }>
                            Vernichtungsanfrage
                        </div>
                        <div className="col-span-2">
                            <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer "  onClick={() => history.push("/services/destroyrequests") }/>
                        </div>
                    </li>
                    <li className="grid items-center grid-cols-3 pb-5 hover:underline">
                        <div className="pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/services/destroylists") }>
                            Vernichtungslisten
                        </div>
                        <div className="col-span-2">
                            <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer "  onClick={() => history.push("/services/destroylists") }/>
                        </div>
                    </li>
                    <li className="grid items-center grid-cols-3 pb-5 hover:underline">
                        <div className="pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/services/destruction") }>
                            Sammelvernichtung
                        </div>
                        <div className="col-span-2">
                            <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer "  onClick={() => history.push("/services/destruction") }/>
                        </div>
                    </li>
                    <li className="grid items-center grid-cols-3 pb-5 hover:underline">
                        <div className="pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => dispatch(downloadCollectListAction())} >
                            Sammelliste
                        </div>
                        <div className="col-span-2" >
                            <FaFilePdf className="w-6 h-6 text-red-600 cursor-pointer" onClick={() => dispatch(downloadCollectListAction())} />
                        </div>
                    </li>
                    <li className="grid items-center grid-cols-3 pb-5 hover:underline">
                        <div className="pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/services/metaupdates") }>
                            Metadaten Update
                        </div>
                        <div className="col-span-2" >
                            <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer " onClick={() => history.push("/services/metaupdates") }/>
                        </div>
                    </li>
                </ul>
            </div>
        </PageContent>

    )
}