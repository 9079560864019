/**
* Created on Fri Feb 26 2021 10:05:16
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import DeliveryOption from "./DeliveryOption";
import User from "./User";


export default class Customer {

    public id?: number;
    public code?: string;
    public countBoxes?: number;
    public countMeters?: number;
    public countBoximages?: number;
    public countBoxcontents?: number;
    public countDeliveryoptions?: number;
    public deliveryoptions?: DeliveryOption[];
    public addressCity?: string;
    public addressName?: string;
    public addressPostCode?: string;
    public addressStreet?: string;
    public notes?: string;
    public meterPrice?: number;
    public users?: User[];
    constructor(
        public name: string,
    ) {};

    static fromAPI(model?: any): Customer {
        const customer = new Customer(
            model.name
        )
        customer.code = model.code;
        customer.id = model.id;
        customer.countBoxes = model.count_boxes;
        customer.countBoximages = model.count_boximages;
        customer.countBoxcontents = model.count_boxcontents;
        customer.countDeliveryoptions = model.count_deliveryoptions;
        customer.countMeters = model.count_meters;
        customer.addressCity = model.address_city;
        customer.addressName = model.address_name;
        customer.addressPostCode = model.address_postcode;
        customer.addressStreet = model.address_street;
        customer.notes = model.notes;
        customer.meterPrice = model.meter_price;
        if (model.deliveryoptions) {
            customer.deliveryoptions = model.deliveryoptions.map((o: any) => DeliveryOption.fromAPI(o))
        }
        if (model.users) {
            customer.users = model.users.map(( o:any ) => User.fromAPI(o))
        }
        return customer;
    }

    static toApi(c: Customer): object {
        let data:any = {
            name: c.name
        }
        if (c.code) {
            data.code = c.code
        }
        if (c.addressCity) {
            data.address_city = c.addressCity
        }
        if (c.addressName) {
            data.address_name = c.addressName
        }
        if (c.addressPostCode) {
            data.address_postcode = c.addressPostCode
        }
        if (c.addressStreet) {
            data.address_street = c.addressStreet
        }
        if (c.notes) {
            data.notes = c.notes
        }
        if (typeof(c.meterPrice) === 'number' || typeof(c.meterPrice) === "string") {
            data.meter_price = c.meterPrice
        }
        if (c.users) {
            data.user_ids = c.users.map(u => u.id)
        }
        return data
    }

}