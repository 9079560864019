/**
* Created on Mon Feb 08 2021 15:06:38
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { PropsWithChildren } from 'react';

interface Props {
    title?: string | React.ReactNode
}

export default function Card({
    children,
    title
}: PropsWithChildren<Props>) {

    return (
        <div className="border-4 border-adl-1">
            { title &&
                <div className="px-4 py-2 font-semibold text-white bg-adl-1">
                    {title}
                </div>
            }
            {children}
        </div>
    )
}