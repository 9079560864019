/**
* Created on Sat Feb 20 2021 15:51:35
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import axios from "axios";
import { push } from "connected-react-router";
import { combineEpics, Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import api from "../../api";
import { notifySuccessAction } from "../../shared/actions/notify.actions";
import Box from "../../shared/models/Box";
import Customer from "../../shared/models/Customer";
import Notification from "../../shared/models/Notification";
import Pagination from "../../shared/models/Pagination";
import { token } from "../../shared/utils";
import { handleRequestError } from "../../shared/utils/errors";
import { addBoxesSuccessAction, ADD_BOXES, ADD_BOXES_SUCCESS, loadBoxesSuccessAction, loadCustomersSuccessAction, LOAD_BOXES, LOAD_CUSTOMERS } from "../actions/boxes.actions";
import {DELETE_BOX_SUCCESS} from "../actions/box.actions";


const loadBoxes: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_BOXES),
        switchMap(( { payload } ) => {
            const url = `${api.BASE_URL_V1}/boxes/${payload.params()}`
            return from(axios.get<Box[]>(url, token(state$) )).pipe(
                map(({data}) => loadBoxesSuccessAction( Pagination.fromAPI(data, Box.fromAPI ))),
                catchError(handleRequestError)
            )
        })
    )

const loadCustomers: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_CUSTOMERS),
        switchMap(() => {
            const url = `${api.BASE_URL_V1}/customers/?query={id, name, code}`
            return from(axios.get<Customer[]>(url, token(state$))).pipe(
                map(({ data }) => loadCustomersSuccessAction(data.map((c: any) =>Customer.fromAPI(c)))),
                catchError(handleRequestError)
            )
        })
    )

const addBoxes: Epic = (action$, state$) =>
    action$.pipe(
        ofType(ADD_BOXES),
        switchMap(( { payload } ) => {
            const url = `${api.BASE_URL_V1}/boxes/`
            return from(axios.post<Box[]>(url, payload, token(state$) )).pipe(
                map(({data}) => addBoxesSuccessAction( data.map((b: any) => Box.fromAPI(b) ))),
                catchError(handleRequestError)
            )
        })
    )

const successAddBoxes: Epic = action$ =>
    action$.pipe(
        ofType(ADD_BOXES_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Box(en) hinzugefügt.`)))
    )

const redirectToBoxes: Epic = action$ =>
    action$.pipe(
        ofType(ADD_BOXES_SUCCESS, DELETE_BOX_SUCCESS),
        map(() => {
            return push('/stock/boxes')
        })
    )

export default combineEpics(
    loadBoxes,
    loadCustomers,
    addBoxes,
    successAddBoxes,
    redirectToBoxes
)