/**
* Created on Sat Apr 17 2021 11:15:56
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Delivery from "../../shared/models/Delivery";

export const LOAD_DELIVERY = '@@delivery/LOAD_DELIVERY';
export type LOAD_DELIVERY = typeof LOAD_DELIVERY;

export const LOAD_DELIVERY_SUCCESS = '@@delivery/LOAD_DELIVERY_SUCCESS';
export type LOAD_DELIVERY_SUCCESS = typeof LOAD_DELIVERY_SUCCESS;

export const DOWNLOAD_DELIVERY_NOTE = '@@delivery/DOWNLOAD_DELIVERY_NOTE';
export type  DOWNLOAD_DELIVERY_NOTE = typeof DOWNLOAD_DELIVERY_NOTE;

export const DOWNLOAD_DELIVERY_NOTE_SUCCESS = '@@delivery/DOWNLOAD_DELIVERY_NOTE_SUCCESS';
export type  DOWNLOAD_DELIVERY_NOTE_SUCCESS = typeof DOWNLOAD_DELIVERY_NOTE_SUCCESS;

export const CANCEL_DELIVERY = '@@delivery/CANCEL_DELIVERY';
export type CANCEL_DELIVERY = typeof CANCEL_DELIVERY;

export const CANCEL_DELIVERY_SUCCESS = '@@delivery/CANCEL_DELIVERY_SUCCESS';
export type CANCEL_DELIVERY_SUCCESS = typeof CANCEL_DELIVERY_SUCCESS;

export const UPDATE_DELIVERY = '@@delivery/UPDATE_DELIVERY';
export type UPDATE_DELIVERY = typeof UPDATE_DELIVERY;

export const UPDATE_DELIVERY_SUCCESS = '@@delivery/UPDATE_DELIVERY_SUCCESS';
export type UPDATE_DELIVERY_SUCCESS = typeof UPDATE_DELIVERY_SUCCESS;

export const loadDeliveryAction = createAction(LOAD_DELIVERY)<number>();
export const loadDeliverySuccessAction = createAction(LOAD_DELIVERY_SUCCESS)<Delivery>();

export const downloadDeliveryNoteAction = createAction(DOWNLOAD_DELIVERY_NOTE)<Delivery>();
export const downloadDeliveryNoteSuccessAction = createAction(DOWNLOAD_DELIVERY_NOTE_SUCCESS)<any>();

export const cancelDeliveryAction = createAction(CANCEL_DELIVERY)<Delivery>();
export const cancelDeliverySuccessAction = createAction(CANCEL_DELIVERY_SUCCESS)<Delivery>();

export const updateDeliveryAction = createAction(UPDATE_DELIVERY)<Delivery>();
export const updateDeliverySuccessAction = createAction(UPDATE_DELIVERY_SUCCESS)<Delivery>();

export type DeliveryAction = ActionType<
    | typeof loadDeliveryAction
    | typeof loadDeliverySuccessAction
    | typeof downloadDeliveryNoteAction
    | typeof downloadDeliveryNoteSuccessAction
    | typeof cancelDeliveryAction
    | typeof cancelDeliverySuccessAction
    | typeof updateDeliveryAction
    | typeof updateDeliverySuccessAction
>