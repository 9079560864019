/**
* Created on Wed Apr 14 2021 13:25:04
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { Route, Switch } from 'react-router';
import { Page } from '../../shared/components/page';
import TopBar from '../../shared/components/topbar';

import Overview from './overview';
import LabelPrintForm from './labelprint';
import ArchiveListForm from './archivelist';
import DestroyListForm from './destroylist';
import DestroyRequestForm from './destroyrequest';
import MetaUpdateForm from './metaupdate';
import DestructionForm from './destruction';
import BoxesOverviewForm from './boxesoverview';

export default function () {

    return(
        <Page>
            <TopBar
                title="Services"
                links={[
                    { name: "Etikettendruck", to: "/services/labelprints" },
                    { name: "Boxen-Lager", to: "/services/boxesoverview" },
                    { name: "Archivlisten", to: "/services/archivelists" },
                    { name: "Vernichtungsanfrage", to: "/services/destroyrequests" },
                    { name: "Vernichtungslisten", to: "/services/destroylists" },
                    { name: "Sammelvernichtung", to: "/services/destruction" },
                    { name: "Metadaten", to: "/services/metaupdates" },
                ]}
            />
            <Switch>
                <Route exact path="/services" component={Overview}/>,
                <Route path="/services/boxesoverview" component={BoxesOverviewForm}/>
                <Route path="/services/labelprints" component={LabelPrintForm}/>
                <Route path="/services/archivelists" component={ArchiveListForm}/>
                <Route path="/services/destroyrequests" component={DestroyRequestForm}/>
                <Route path="/services/destroylists" component={DestroyListForm}/>
                <Route path="/services/destruction" component={DestructionForm}/>
                <Route path="/services/metaupdates" component={MetaUpdateForm} />
            </Switch>
        </Page>
    )
}