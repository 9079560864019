/**
* Created on Tue Mar 30 2021 14:53:59
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { FaCheckCircle, FaExclamationCircle, FaInfoCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { removeNotifyAction } from '../../actions/notify.actions';
import Notification from '../../models/Notification';

interface Props {
    notification: Notification
}

export default function Toast({
    notification
}: Props) {

    const dispatch = useDispatch()
    
    return(
        <div
            className={
                `my-4 text-sm font-bold shadow-xl first:m-0 w-72
                ${notification.type === "INFO" && "bg-blue-400 text-white"}
                ${notification.type === "SUCCESS" && "bg-green-500 text-white"}
                ${notification.type === "WARING" && "bg-yellow-500 text-white"}
                ${notification.type === "ERROR" && "bg-red-500 text-white"}
                `
            }
        >
            <div className="relative py-5 pl-4 pr-8">
                <div className="flex items-center">
                <div className="mr-4">
                    {notification.type === "INFO" &&  <FaInfoCircle className="text-lg text-blue-800"/>}
                    {notification.type === "SUCCESS" && <FaCheckCircle className="text-lg text-green-800"/>}
                    {notification.type === "WARING" && <FaExclamationCircle className="text-lg text-yellow-800"/>}
                    {notification.type === "ERROR" && <FaTimesCircle className="text-lg text-red-800"/>}
                </div>
                <div>
                    {notification.msg}
                </div>
                </div>
                <div className="absolute top-0 right-0">
                    <button className="p-2 focus:outline-none" onClick={() => dispatch(removeNotifyAction(notification))}>
                        <FaTimes className="text-base"/>
                    </button>
                </div>
            </div>
        </div>
    )
}