/**
* Created on Wed Feb 24 2021 12:58:00
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Card } from '../../../shared/components/card';
import { PageContent } from '../../../shared/components/page';
import Table from '../../../shared/components/table';
import RequestParams from '../../../shared/models/RequestParams';
import { loadDeliveriesAction } from '../../actions/delieveries.actions';
import PageParams from '../../../shared/models/PageParams';
import { getDeliveries, loadingDeliveries } from '../../selectors/deliveries.selector';
import { formatDate } from '../../../shared/utils';
import Delivery from '../../../shared/models/Delivery';
import Button from '../../../shared/components/buttons/Button';
import { history } from '../../../store/history';
import Filter, { FilterObject } from '../../../shared/models/Filter';
import ExtendedFilter from "./filter";

export default function () {

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        const params = new RequestParams(location.search)
        const page = search.get('page')
        if (page) {
            params.page = new PageParams(parseInt(page))
        } else {
            params.page = new PageParams()
        }
        dispatch(loadDeliveriesAction(params))
    }, [dispatch, location])

    const deliveries = useSelector(getDeliveries)
    // column filters
    const filterKeys =  ["code"]
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const setFilterParams = (filter: FilterObject) => {
        if (timer) {
            clearTimeout(timer)
            setTimer(setTimeout(() => {
                history.push(location.pathname + "?" + new Filter(filter, filterKeys).params(location.search))
                setTimer(undefined)
            }, 1000))
        } else {
            setTimer(setTimeout(() => {
                history.push(location.pathname + "?" + new Filter(filter, filterKeys).params(location.search))
                setTimer(undefined)
            }, 1000))
        }
    }

    const loading = useSelector(loadingDeliveries)
    const columns = [
        { name: "Nummer", key: "code" },
        { name: "Kunde", key: "customer", render: (d: Delivery) => <div>( {d.customer.code} ) {d.customer.name}</div> },
        { name: "#Boxen", key: "boxes", render: (d: Delivery) => d.boxes.length },
        { name: "#Box Inhalte", key: "boxcontens", render: (d: Delivery) => d.boxcontents.length },
        { name: "Art", key: "kind", render: (d: Delivery) => d.kind?.text },
        { name: "Status", key: "state", render: ((d: Delivery) => d.state?.text) },
        { name: "Datum", key: "time", render: ((d: Delivery) => formatDate(d.time!.toString(), "DD.MM.YYYY")) }
    ]

    return (
        <PageContent>
            <Card title={
                <div className="flex items-center justify-between">
                    <div>
                        Lieferungen ( {deliveries.totalItems} )
                    </div>
                    <div>
                        <Button onClick={() => history.push("/deliveries/create")}>
                            Neue Lieferung
                        </Button>
                    </div>
                </div>
            }>
                <ExtendedFilter />
                <Table
                    columns={columns}
                    data={deliveries.data}
                    loading={loading}
                    pagination={deliveries}
                    onFilter={setFilterParams}
                    filter={filterKeys}
                    onClickRow={( d: Delivery ) => history.push(`/deliveries/${d.id}`) }
                />
            </Card>
        </PageContent>
    )
}