/**
* Created on Sat Jan 23 2021 13:43:25
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { NavLink } from 'react-router-dom';
import Staff from '../Staff';
import logo from './../../../shared/images/adl-logo.gif';
import tschudenITSolutionsLogo from './../../../shared/images/logo-tschuden-itsolutions.png'

interface Link {
    to: string,
    name: string,
    icon?: JSX.Element
    staff?: boolean
}

interface Props {
    links: Link[]
}

export default function Sidebar({
    links
}: Props) {

    return (
        <div className="fixed h-screen bg-gray-200 border-r-4 w-14 border-adl-1 lg:w-72">
            <div className="flex flex-col items-center justify-center">
                <img src={logo} alt="" className="w-10 mt-4 mb-2 lg:w-14" />
                <div className="invisible h-0 mb-8 text-lg lg:visible lg:h-auto">
                    ADL Management System
                </div>
            </div>
            <nav className="flex flex-col">
                {links.map((l, i) =>
                    l.staff ?
                        <Staff key={`sidebar-item-${i}`}>
                            <NavLink className="flex items-center py-2 pl-4" activeClassName="bg-adl-1 text-white font-semibold" to={l.to}>
                                <div className="w-5 mr-2">
                                    {l.icon &&
                                        l.icon
                                    }
                                </div>
                                <div className="invisible w-0 lg:w-auto lg:visible truncate">
                                    {l.name}
                                </div>
                            </NavLink>
                        </Staff>
                        :
                        <NavLink key={`sidebar-item-${i}`} className="flex items-center py-2 pl-4" activeClassName="bg-adl-1 text-white font-semibold" to={l.to}>
                            <div className="w-5 mr-2">
                                {l.icon &&
                                    l.icon
                                }
                            </div>
                            <div className="invisible w-0 lg:w-auto lg:visible">
                                {l.name}
                            </div>
                        </NavLink>
                )}
            </nav>
            <div className="absolute bottom-0 w-full">
                <div className="flex justify-center">
                    <a className="flex flex-col items-center" href="https://tschuden-itsolutions.de" target="_blank" rel="noopener noreferrer">
                        <div className="invisible text-sm text-gray-400 lg:visible">
                            Ein Lösung von
                        </div>
                        <img src={tschudenITSolutionsLogo} alt="" className="w-10 my-1 lg:w-12" />
                        <span className="invisible h-0 mb-1 text-xs text-gray-400 lg:visible lg:h-auto">{process.env.REACT_APP_VERSION}</span>
                    </a>
                </div>
            </div>
        </div>
    )
}