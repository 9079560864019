import React from 'react';
import {Provider} from 'react-redux';
import axios from 'axios';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { history } from './store/history';

import Sidebar from './shared/components/sidebar';
import Home from './home/components';
import Stock from './stock/components'
import Login from './login/components';
import Users from './users/components';
import Deliveries from './deliveries/components';
import Customers from './customers/components';
import Services from './services/components';
//import Lists from './lists/components';
import Apps from './apps/components';

import {FaCubes, FaHome, FaTools, FaTruck, FaUserFriends, FaUsers, FaWindowRestore } from 'react-icons/fa';
import Auth from './shared/components/Auth';
import ToastContainer from './shared/components/toast/ToastContainer';

let configureStore: Function;
if (process.env.NODE_ENV === 'development') {
  configureStore = require('./store/configureStore.dev').configureStore;
} else {
  configureStore = require('./store/configureStore.prod').configureStore;
}

const store = configureStore();

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

function App() {

  return (
    <div>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ToastContainer/>
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/login">
              <Auth>
                <Login/>  
              </Auth>
            </Route>
            <Route path="/">
              <Auth>
                <div className="flex">
                  <Sidebar links={[
                    { name: "Home", to: "/home", icon: <FaHome /> },
                    { name: "Lager", to: "/stock", icon: <FaCubes /> },
                    { name: "Kunden", to: "/customers", icon: <FaUserFriends/>, staff: true },
                    { name: "Lieferungen", to: "/deliveries", icon: <FaTruck/> },
                    { name: "Services", to: "/services", icon: <FaTools/>, staff: true },
                    //{ name: "Listen", to: "/lists", icon: <FaList/>, staff: true },
                    { name: "Users", to: "/users", icon:  <FaUsers />, staff: true },
                    { name: "Apps & Scripts", to:"/apps", icon: <FaWindowRestore />, staff: true}
                  ]} />
                  <Route path="/home" component={Home} />
                  <Route path="/stock" component={Stock} />
                  <Route path="/customers" component={Customers} />
                  <Route path="/services" component={Services} />
                  {/**<Route path="/lists" component={Lists}/>*/}
                  <Route path="/users" component={Users} />
                  <Route path="/deliveries" component={Deliveries} />
                  <Route path="/apps" component={Apps} />
                </div>
              </Auth>
            </Route>
          </Switch>
        </ConnectedRouter>
      </Provider>
    </div>
  );
}

export default App;
