/**
* Created on Sun Jan 24 2021 16:24:08
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { createRef, RefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authUser } from '../../selectors/auth.selector';
import { MdArrowDropDown } from 'react-icons/md';
import { IconContext } from 'react-icons';
import { FaSignOutAlt, FaSlidersH, FaUser } from 'react-icons/fa';
import { logoutAction } from '../../actions/auth.actions';



const useOnClickOutside = (ref: RefObject<HTMLDivElement>, handler: any) => {
    useEffect(
        () => {
            const listener = (event: any) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            document.addEventListener('keydown', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
                document.removeEventListener('keydown', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}

export default function () {

    const user = useSelector(authUser)
    const dispatch = useDispatch()
    const [showPopupMenu, setShowPopupMenu] = useState(false)
    const ref = createRef<HTMLDivElement>()
    useOnClickOutside(ref, () => setShowPopupMenu(false))


    return (
        <div className="relative">
            <div className="flex items-center cursor-pointer" onClick={() => setShowPopupMenu(true)}>
                <div className="font-medium text-white">
                    <span className='whitespace-nowrap'>{user?.firstName} {user?.lastName}</span>
                </div>
                <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
                    <MdArrowDropDown />
                </IconContext.Provider>
            </div>
            {showPopupMenu &&
                <div className="absolute right-0 mt-6 bg-white border-2 shadow border-adl-1" ref={ref}>
                    <IconContext.Provider value={{ size: "0.7em" }}>
                        <div className="flex items-center px-3 py-1 cursor-not-allowed hover:bg-gray-200">
                            <FaUser className="mr-2" />
                            <div>
                                Profil
                        </div>
                        </div>
                        <div className="flex items-center px-3 py-1 cursor-not-allowed hover:bg-gray-200">
                            <FaSlidersH className="mr-2" />
                            <div>
                                Einstellungen
                            </div>
                        </div>
                        <hr className="border-b border-adl-1" />
                        <div className="flex items-center px-3 py-1 cursor-pointer hover:bg-gray-200" onClick={() => { dispatch(logoutAction()) }}>
                            <FaSignOutAlt className="mr-2" />
                            <div>
                                abmelden
                        </div>
                        </div>
                    </IconContext.Provider>
                </div>
            }
        </div>
    )
}