/**
 * Created on Thu Apr 15 2021 08:39:33
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import User from "./User";
import parse from "html-react-parser";
import Position from "./Position";
import {formatDate} from "../utils";
import BoxContent from "./BoxContent";

export enum StatesText {
    CREATED = "erstellt",
    MOVED = "bewegt",
    ORDERED = "angefordert",
    OUTSOURCED = "ausgelagert",
    RETOUR = "retour",
    DESTROYED = "vernichtet",
    REACTIVATED = "reaktiviert",
    MARKED_DESTROY = "markiert zur Vernichtung",
    MARKED_DESTROY_RESET = "markiert zur Vernichtung (reset)",
    META_YEAR = "Meta-Daten geändert",
    META_YEAR_RESET = "Meta-Daten reset",
    META_DESTROY_YEAR = "Meta-Daten geändert",
    META_DESTROY_YEAR_RESET = "Meta-Daten reset",
    META_FIRST_DELIVERY_CUSTOMER = "Meta-Daten geändert",
    META_FIRST_DELIVERY_CUSTOMER_RESET = "Meta-Daten reset",
    BOX_SIZE_CHANGE = "Größe geändert",
    BOX_PICKED = "entnommen",
    CONTENT_PICKED = "Inhalt entnommen",
    CONTENT_PUT_IN = "Inhalt hineingelegt",
    CONTENT_NON_EXISTENT = "Inhalt existiert nicht",
}

export enum StatesNum {
    CREATED = 0,
    MOVED = 1,
    ORDERED = 2,
    OUTSOURCED = 3,
    RETOUR = 4,
    DESTROYED = 6,
    REACTIVATED = 7,
    MARKED_DESTROY = 8,
    MARKED_DESTROY_RESET = 9,
    META_YEAR = 10,
    META_YEAR_RESET = 11,
    META_DESTROY_YEAR = 12,
    META_DESTROY_YEAR_RESET = 13,
    META_FIRST_DELIVERY_CUSTOMER = 14,
    META_FIRST_DELIVERY_CUSTOMER_RESET = 15,
    BOX_SIZE_CHANGE = 16,
    BOX_PICKED = 17,
    CONTENT_PICKED = 18,
    CONTENT_PUT_IN = 19,
    CONTENT_NON_EXISTENT = 20,
}

export default class BoxHistory {

    public id?: number;
    public created?: string;
    public user?: User;
    public meta?: any;
    public metaHtml?: ""

    constructor(
        public readonly key: keyof typeof StatesText,
        public readonly text: string,
        public readonly num: number,
    ) {
    }

    static fromAPI(model: any): BoxHistory {
        const h = new BoxHistory(
            model.state as keyof typeof StatesText,
            StatesText[model.state as keyof typeof StatesText],
            StatesNum[model.state as keyof typeof StatesNum],
        )
        h.created = model.created
        h.id = model.id
        if (model.user) {
            h.user = User.fromAPI(model.user)
        }
        if (model.meta) {
            h.meta = model.meta
        }
        return h
    }

    static fromNumber(num: number): BoxHistory | undefined {
        let index = this.states().findIndex(s => s.num === num)
        if (index !== -1) {
            return this.states()[index]
        }
        return undefined
    }

    static fromKey(key: keyof typeof StatesText): BoxHistory {
        return new BoxHistory(
            key as keyof typeof StatesText,
            StatesText[key as keyof typeof StatesText],
            StatesNum[key as keyof typeof StatesNum]
        )
    }

    static states(): BoxHistory[] {
        return Object.entries(StatesText).map(
            ([key, state], i) => (
                new BoxHistory(
                    key as keyof typeof StatesText,
                    StatesText[key as keyof typeof StatesText],
                    StatesNum[key as keyof typeof StatesNum]
                )
            ))
    }

    static parseMeta(h: BoxHistory): string | JSX.Element | JSX.Element[] {
        switch (h.num) {
            case StatesNum.CREATED:
                return ""
            case StatesNum.MOVED:
                const position = Position.fromAPI(h.meta)
                return parse(
                    `<div class="flex gap-x-1">
                                <div class="font-semibold">
                                    Reihe:
                                </div>
                                <div>
                                    ${position.row.area?.name} ${position.row.number} 
                                </div>
                                <div class="font-semibold">
                                    Teil:
                                </div>
                                <div>
                                    ${position.part}
                                </div>
                                <div class="font-semibold">
                                    Höhe:
                                </div>
                                <div>
                                    ${position.height}
                                </div>
                           </div>`
                )
            case StatesNum.MARKED_DESTROY:
                return ""
            case StatesNum.MARKED_DESTROY_RESET:
                return ""
            case StatesNum.META_FIRST_DELIVERY_CUSTOMER:
                return parse(
                    `<div class="flex gap-x-1">
                        <div class="font-semibold">Anlieferung:</div>
                        <div>${formatDate(h.meta, "DD.MM.YYYY")}</div>
                    </div>`
                )
            case StatesNum.META_FIRST_DELIVERY_CUSTOMER_RESET:
                return parse(`<div class="font-semibold">Anlieferung</div>`)
            case StatesNum.META_DESTROY_YEAR:
                return parse(
                    `<div class="flex gap-x-1">
                        <div class="font-semibold">Vernichtung Jahrgang:</div>
                        <div>${h.meta}</div>
                    </div>`
                )
            case StatesNum.META_DESTROY_YEAR_RESET:
                return parse(`<div class="font-semibold">Vernichtung Jahrgang</div>`)
            case StatesNum.META_YEAR:
                return parse(
                    `<div class="flex gap-x-1">
                        <div class="font-semibold">Jahrgang:</div>
                        <div>${h.meta}</div>
                    </div>`
                )
            case StatesNum.META_YEAR_RESET:
                return parse(`<div class="font-semibold">Jahrgang</div>`)
            case StatesNum.REACTIVATED:
                return ""
            case StatesNum.BOX_PICKED:
                return ""
            case StatesNum.DESTROYED:
                return ""
            case StatesNum.CONTENT_PICKED:
                return parse(`<div>${BoxContent.fromAPI(h.meta).text}</div>`)
            case StatesNum.CONTENT_NON_EXISTENT:
                return parse(`<div>${BoxContent.fromAPI(h.meta).text}</div>`)
            case StatesNum.CONTENT_PUT_IN:
                return parse(`<div>${BoxContent.fromAPI(h.meta).text}</div>`)
            case StatesNum.BOX_SIZE_CHANGE:
                return parse( `<div class="flex gap-x-1">
                    <div class="font-semibold whitespace-nowrap">Größe geändert von:</div>
                    <div>${h.meta.from}mm</div>
                    <div class="font-semibold">zu</div>
                    <div>${h.meta.to}mm</div>
                </div>`)
            default:
                return parse(`<div class="italic">unknown data</div>`)
        }
    }
}