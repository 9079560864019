/**
* Created on Wed Jan 20 2021 10:40:26
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';

interface Props {
    controlId?: string,
    title: string,
    type?: "text" | "number" | "password" | "date" | "time",
    value?: string | number,
    isError?: boolean,
    isInvalid?: boolean,
    isValid?: boolean,
    error?: string,
    onChange?: (e: React.ChangeEvent<any> | any) => void,
    onBlur?: (e: React.FocusEvent<any> | any) => void,
    name?: string,
    required?: boolean,
    min?: number,
    step?: number
    disabled?: boolean,
}


export default function Input({
    title,
    type,
    value,
    onChange,
    onBlur,
    isError,
    isInvalid,
    isValid,
    error,
    controlId,
    name,
    required,
    min,
    step,
    disabled
}: Props) {

    return (
        <div>
            <div className="flex items-baseline justify-between">
                <div className="font-medium text-gray-600">
                    {title} {required && "*"}
                </div>
                <div className="text-sm text-red-500">
                    {isInvalid &&
                        <div >
                            {error}
                        </div>
                    }
                </div>
            </div>
            <input
                name={name}
                id={controlId}
                className={`w-full px-2 py-2 bg-gray-200 rounded outline-none leading-tight focus:outline-none focus:bg-white focus:border-adl-1 border-gray-200 border-2 ${isInvalid && "border-red-500 border-2 bg-red-100"} ${disabled && "cursor-not-allowed"}`}
                type={type ? type : 'text'}
                value={value ? value : ""}
                onChange={onChange}
                onBlur={onBlur}
                min={min}
                step={step}
                disabled={disabled}
            />
        </div>
    )
}