/**
* Created on Sat Feb 20 2021 16:47:00
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

interface Props {
    value: boolean
}

export default function BooleanCell({
    value
}: Props) {

    return (
        <div>
            {value ?
                <div className="text-green-500">
                    <FaCheckCircle />
                </div>
                :
                <div className="text-red-500">
                    <FaTimesCircle />
                </div>
            }
        </div>
    )
}