/**
* Created on Fri Mar 05 2021 10:06:25
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Staff from '../../../shared/components/Staff';
import Box from '../../../shared/models/Box';
import { StatesNum } from '../../../shared/models/BoxState';
import { formatDate, utcDate } from '../../../shared/utils';
import { updateBoxAction } from '../../actions/box.actions';

interface Props {
    box: Box
}

export default function Informations({
    box
}: Props) {

    const [destroyed, setDestroyed] = useState(box.destroyed!)
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const dispatch = useDispatch()
    const handleOnChange = (e: any) => {
        setDestroyed(e.target.value)
        if (timer) {
            clearTimeout(timer)
            setTimer(setTimeout(() => {
                const b: Box = Object.assign(box)
                b.destroyed = utcDate(e.target.value)
                dispatch(updateBoxAction(b))
                setTimer(undefined)
            }, 1000))
        } else {
            setTimer(setTimeout(() => {
                const b: Box = Object.assign(box)
                b.destroyed = utcDate(e.target.value)
                dispatch(updateBoxAction(b))
                setTimer(undefined)
            }, 1000))
        }
    }


    return (
        <div className="grid grid-cols-2 gap-x-2">
            <div className="font-semibold text-gray-700">Kunde:</div>
            <div>
                {
                    //TODO href to customer
                }
                {box.owner?.name}
            </div>
            <div className="font-semibold text-gray-700">erstellt:</div>
            <div>
                {formatDate(box.created.toString())}
            </div>
            <div className="font-semibold text-gray-700">Größe (mm):</div>
            <div>
                {box.size!}
            </div>
            <div className="font-semibold text-gray-700">Zustand:</div>
            <div className={`${box.state!.num === StatesNum.MARKED_FOR_DESTROYED && "text-red-500 font-semibold"}`}>
                {box.state!.text} {box.state!.num === StatesNum.DESTROYED && `( ${formatDate(destroyed, "DD.MM.YYYY")} )`}
            </div>
            <Staff>
                {box.state!.num === StatesNum.DESTROYED &&
                    <div className="col-span-2 mt-2">
                        <div className="font-medium text-gray-600">
                            vernichtet
                    </div>
                        <input
                            type="date"
                            className={`w-full px-2 py-2 bg-gray-200 rounded outline-none leading-tight focus:outline-none focus:bg-white focus:border-adl-1 border-gray-200 border-2`}
                            value={formatDate(destroyed, "YYYY-MM-DD")}
                            onChange={handleOnChange}
                        />
                    </div>
                }
            </Staff>
        </div>
    )
}