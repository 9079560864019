import axios from 'axios';
import { combineEpics, ofType, Epic } from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { AUTH, authSuccessAction } from '../actions/auth.actions';
import api from '../../api';
import User from '../models/User';
import { token } from '../utils';
import { handleRequestError } from '../utils/errors';

const auth: Epic = (action$, state$) => 
    action$.pipe(
        ofType(AUTH),
        switchMap(({ payload: id }) =>
            from(axios.get(`${api.BASE_URL_V1}/users/${id}`, token(state$) )).pipe(
                map(({ data }) => authSuccessAction( User.fromAPI(data) )),
                catchError(handleRequestError)
            )
        )
    )

export default combineEpics(
    auth,
)