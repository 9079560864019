/**
* Created on Fri Feb 26 2021 09:34:01
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Customer from "../../shared/models/Customer";
import Pagination from "../../shared/models/Pagination";
import { LoadState } from "../../shared/models/RequestState";
import { 
    loadCustomersAction,
    loadCustomersSuccessAction,
    LOAD_CUSTOMERS,
    LOAD_CUSTOMERS_SUCCESS 
} from "../actions/customers.actions";



export interface State {
    data: Pagination<Customer>,
    load: LoadState,
}

export default function (
    state: State = {
        data: new Pagination<Customer>(),
        load: LoadState.init()
    },
    action: ActionType<
        | typeof loadCustomersAction
        | typeof loadCustomersSuccessAction
    >
): State {

    switch (action.type) {
        case LOAD_CUSTOMERS:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_CUSTOMERS_SUCCESS:
            return update(state, {
                data: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        default:
            return state
    }

}