/**
* Created on Sat Jan 23 2021 17:59:58
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PageContent } from '../../../shared/components/page';
import Table from '../../../shared/components/table';
import Box from '../../../shared/models/Box';
import PageParams from '../../../shared/models/PageParams';
import RequestParams from '../../../shared/models/RequestParams';
import OrderParams from '../../../shared/models/OrderParams';

import { loadBoxesAction } from '../../actions/boxes.actions';
import { getBoxes, loadingBoxes } from '../../selectors/boxes.selectors';
import { Card } from '../../../shared/components/card';
import ExtendedFilter from './filter';
import BoxFilter from '../../../shared/models/BoxFilter';
import { history } from '../../../store/history';
import Button from '../../../shared/components/buttons/Button';
import Filter, { FilterObject } from '../../../shared/models/Filter';
import Staff from '../../../shared/components/Staff';
import { isStaff } from '../../../shared/selectors/auth.selector';


export default function Boxes() {

    const location = useLocation();
    const dispatch = useDispatch();
    const staff = useSelector(isStaff);
    useEffect(() => {
        const search = new URLSearchParams(location.search)
        const page = search.get('page')
        const orderBy = search.get('orderBy')
        //TODO "?" -> haut irgendiw nicht hin wenn es bei der suche dabei ist
        const params = new RequestParams(location.search)
        const boxFilter = new BoxFilter().fromParams(search.toString())
        if (page) {
            params.page = new PageParams(parseInt(page))
        } else {
            params.page = new PageParams()
        }
        if (orderBy) {
            params.order = new OrderParams(orderBy)
        }
        if (boxFilter) {
            params.otherParams = boxFilter.params(params.otherParams)
        }
        dispatch(loadBoxesAction(params));
    }, [dispatch, location])

    // column filters
    const filterKeys = ["position__row__area__name", "position__row__number", "position__part"]
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const setFilterParams = (filter: FilterObject) => {
        if (timer) {
            clearTimeout(timer)
            setTimer(setTimeout(() => {
                history.push(location.pathname + "?" + new Filter(filter, filterKeys).params(location.search))
                setTimer(undefined)
            }, 1000))
        } else {
            setTimer(setTimeout(() => {
                history.push(location.pathname + "?" + new Filter(filter, filterKeys).params(location.search))
                setTimer(undefined)
            }, 1000))
        }
    }

    const boxes = useSelector(getBoxes)
    const loading = useSelector(loadingBoxes)
    const columns = [
        { name: "Code", key: "code", orderBy: true, render: (b: Box) => b.formatCode()},
        { name: "Kunde", key: "owner__name", orderBy: true, render: (b: Box) => b.owner?.name },
        { name: "Bereich", key: "position__row__area__name", orderBy: true, render: (b: Box) => b.position?.row.area!.name, hidden: !staff },
        { name: "Reihe", key: "position__row__number", render: (b: Box) => b.position?.row.number, hidden: !staff  },
        { name: "Teil", key: "position__part", render: (b: Box) => b.position?.part, hidden: !staff  },
        { name: "Höhe", key: "position_height", render: (b: Box) => b.position?.height, hidden: !staff },
        { name: "#Inhalt", key: "content", render: (b: Box) => b.content.length },
        { name: "#Bilder", key: "images", render: (b: Box) => b.images!.length },
        { name: "State", key: "state", render: (b: Box) => b.state.text },
    ]

    return (
        <PageContent>
            <Card title={<div className="flex items-center justify-between">
                <div>
                    Boxen ( {boxes.totalItems} )
                </div>
                <div>
                    <Staff>
                        <Button onClick={() => history.push(`/stock/boxes/new`)}>
                            Neue Box(en) hinzufügen
                    </Button>
                    </Staff>
                </div>
            </div>}>
                <ExtendedFilter />
                <Table
                    columns={columns}
                    data={boxes.data}
                    pagination={boxes}
                    loading={loading}
                    onClickRow={(b: Box) => history.push(`/stock/boxes/${b.id}`)}
                    onFilter={setFilterParams}
                    filter={filterKeys}
                />
            </Card>
        </PageContent>
    )
}