import { createAction, ActionType } from 'typesafe-actions'
import Token from '../../shared/models/Token';

export const LOGIN = '@@login/LOGIN';
export type LOGIN =  typeof LOGIN;

export const LOGIN_SUCCESS = '@@login/LOGIN_SUCCESS';
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS;

export const LOGIN_FAILURE = '@@login/LOGIN_FAILURE';
export type LOGIN_FAILURE = typeof LOGIN_FAILURE;

export const ERROR = '@@login/ERROR';
export type ERROR = typeof ERROR;

export const loginAction = createAction(LOGIN)<{
    username: string;
    password: string;
}>();
export const loginSuccessAction = createAction(LOGIN_SUCCESS)<Token>();
export const loginFailureAction = createAction(LOGIN_FAILURE)<string>();

export const errorAction = createAction(ERROR)<string>();

export type LoginActions = ActionType<
    | typeof loginAction
    | typeof loginSuccessAction
    | typeof loginFailureAction
    | typeof errorAction
>