import { urlHttps } from "../utils"

export default class BoxImage {

    constructor(
        public id: number,
        public image: string,
        public created: Date,
        public viewed: boolean,
    ) {}

    static fromAPI(model?: any): BoxImage {
        const image = new BoxImage(
            model.id,
            urlHttps(model.image), 
            model.created,
            model.viewed
        )
        return image
    }

}