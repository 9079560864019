/**
* Created on Sat Apr 17 2021 12:45:52
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import {ActionType} from 'typesafe-actions';
import Delivery from '../../shared/models/Delivery';
import {LoadState} from '../../shared/models/RequestState';
import {
    CANCEL_DELIVERY_SUCCESS,
    cancelDeliverySuccessAction,
    DOWNLOAD_DELIVERY_NOTE_SUCCESS,
    downloadDeliveryNoteSuccessAction,
    LOAD_DELIVERY,
    LOAD_DELIVERY_SUCCESS,
    loadDeliveryAction,
    loadDeliverySuccessAction,
    UPDATE_DELIVERY_SUCCESS,
    updateDeliverySuccessAction
} from '../actions/delivery.actions';
import download from "downloadjs";


export interface State {
    data: Delivery | undefined,
    load: LoadState,
}

export default function(
    state: State = {
        data: undefined,
        load: LoadState.init(),
    },
    action: ActionType<
        | typeof loadDeliveryAction
        | typeof loadDeliverySuccessAction
        | typeof downloadDeliveryNoteSuccessAction
        | typeof cancelDeliverySuccessAction
        | typeof updateDeliverySuccessAction
    >
): State {

    switch(action.type) {
        case LOAD_DELIVERY:
            return update(state, {
                load: { $set: LoadState.loading() } 
            })
        case LOAD_DELIVERY_SUCCESS:
            return update(state, {
                load: { $set: LoadState.loaded() },
                data: { $set: action.payload }
            })
        case DOWNLOAD_DELIVERY_NOTE_SUCCESS:
            download(action.payload.data, `Lieferung.pdf`)
            return state
        case CANCEL_DELIVERY_SUCCESS:
            return update(state, {
                data: { $set: action.payload }
            })
        case UPDATE_DELIVERY_SUCCESS:
            return update(state, {
                data: { $set: action.payload }
            })
        default:
            return state
    }

}