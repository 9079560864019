/**
* Created on Sun Apr 18 2021 22:25:19
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import StockOverview from "../../shared/models/StockOverview";

export const LOAD_OVERVIEW = '@@overview/LOAD_OVERVIEW';
export type LOAD_OVERVIEW = typeof LOAD_OVERVIEW;

export const LOAD_OVERVIEW_SUCCESS = '@@overview/LOAD_OVERVIEW_SUCCESS';
export type LOAD_OVERVIEW_SUCCESS = typeof LOAD_OVERVIEW_SUCCESS;

export const loadOverviewAction = createAction(LOAD_OVERVIEW)();
export const loadOverviewSuccessAction = createAction(LOAD_OVERVIEW_SUCCESS)<StockOverview>(); 

export type OverViewAction = ActionType<
    | typeof loadOverviewAction
    | typeof loadOverviewSuccessAction
>