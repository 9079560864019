/**
* Created on Mon Jan 25 2021 10:29:20
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { isStaff } from '../selectors/auth.selector';



export default function Staff({
    children,
}: PropsWithChildren<{}>) {

    const staff = useSelector(isStaff)

    return(
        <div>
            { staff && children}
        </div>
    )
}