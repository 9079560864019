/**
* Created on Fri Apr 09 2021 10:02:00
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Row from "../../shared/models/Row";

export function getRow(state: AppState): Row {
    return state.stock.row.data!
}

export function loadingRow(state: AppState): boolean {
    return state.stock.row.load.loading
}

export function loadedRow(state: AppState): boolean {
    return state.stock.row.load.loaded
}