/**
* Created on Thu Apr 15 2021 09:18:18
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { SubTitle } from '../../../../shared/components/card';
import Box from '../../../../shared/models/Box';
import BoxHistory from '../../../../shared/models/BoxHIstory';
import { formatDate } from '../../../../shared/utils';

interface Props {
    box: Box
}

export default function ({
    box
}: Props) {

    return (
        <div>
            <SubTitle title="History" />
            <ul className="m-2">
                {
                    box.history.map(
                        (h: BoxHistory) => (
                            <li key={`box-history-${h.id}`}>
                                <div className="grid grid-cols-4 gap-x-2">
                                    <div>
                                        { formatDate(h.created!)}
                                    </div>
                                    <div className="font-bold">
                                        { h.text }
                                    </div>
                                    <div>
                                        { BoxHistory.parseMeta(h) }
                                    </div>
                                    <div className="italic text-right">
                                        { h.user?.firstName } {h.user?.lastName}
                                    </div>
                                </div>
                            </li>
                        )
                    )
                }
            </ul>
        </div>
    )
}