/**
* Created on Wed Feb 24 2021 12:54:50
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Page } from '../../shared/components/page';
import TopBar from '../../shared/components/topbar';

import Overview from './overview';
import DeliveryForm from './delivery-form';
import Delivery from './delivery';

export default function () {

    return(
        <Page>
            <TopBar
                title="Lieferungen"
                links={[
                    { name: "Überblick", to: "/deliveries/overview" },
                ]}
            />
            <Switch>
                <Route exact path="/deliveries"
                    render={ () => { return <Redirect to="/deliveries/overview" /> } }
                />
                <Route path="/deliveries/overview" component={Overview}/>
                <Route path="/deliveries/create" exact component={DeliveryForm}/>
                <Route path="/deliveries/:id" component={Delivery}/>
            </Switch>
        </Page>
    )
}