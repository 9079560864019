/**
* Created on Sat Apr 17 2021 13:35:46
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Delivery from "../../shared/models/Delivery";

export function getDelivery(state: AppState): Delivery | undefined {
    return state.delivery.data!
}

export function loadedDelivery(state: AppState): boolean {
    return state.delivery.load.loaded
}