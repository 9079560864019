/**
* Created on Sat Jan 23 2021 13:30:44
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Page } from '../../shared/components/page';
import TopBar from '../../shared/components/topbar';
import Overview from './overview';
import UserForm from './users-form';

export default function () {

    return(
        <Page>
            <TopBar
                title="Users"
                links={[]}
            />
            <Switch>
                <Route exact path="/users" component={Overview} />
                <Route path="/users/new" component={UserForm} />
            </Switch>
        </Page>
    )
}