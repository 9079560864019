/**
* Created on Fri Apr 09 2021 09:22:58
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Area from "../../shared/models/Area";
import { LoadState } from '../../shared/models/RequestState';
import { loadAreaAction, loadAreaSuccessAction, LOAD_AREA, LOAD_AREA_SUCCESS } from "../actions/area.actions";

export interface State {
    data: Area | undefined,
    load: LoadState
}

export default function(
    state: State = {
        data: undefined,
        load: LoadState.init()
    },
    action: ActionType<
        | typeof loadAreaAction
        | typeof loadAreaSuccessAction
    >
): State {
    switch (action.type) {
        case LOAD_AREA:
            return update( state, {
                load: { $set: LoadState.loading()}
            })
        case LOAD_AREA_SUCCESS:
            return update(state, {
                data: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        default:
            return state
    }
}