import axios from "axios";
import { push } from "connected-react-router";
import { combineEpics, Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import api from "../../api";
import { notifySuccessAction } from "../../shared/actions/notify.actions";
import Area from "../../shared/models/Area";
import Notification from "../../shared/models/Notification";
import Pagination from "../../shared/models/Pagination";
import Row from "../../shared/models/Row";
import { token } from "../../shared/utils";
import { handleRequestError } from "../../shared/utils/errors";
import { deleteRowSuccessAction, DELETE_ROW, DELETE_ROW_SUCCESS } from "../actions/row.actions";
import { addRowSuccessAction, ADD_ROW, ADD_ROW_SUCCESS, loadAreasSuccessAction, loadRowsSuccessAction, LOAD_AREAS, LOAD_ROWS } from "../actions/rows.actions";

const loadRows: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_ROWS),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/rows/${payload.params()}`
            return from(axios.get<Row[]>(url, token(state$))).pipe(
                map(({ data }) => loadRowsSuccessAction(Pagination.fromAPI(data, Row.fromAPI))),
                catchError(handleRequestError)
            )
        })
    )

const deleteRow: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DELETE_ROW),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/rows/${payload.id}/`
            return from(axios.delete<Row>(url, token(state$))).pipe(
                map(() => deleteRowSuccessAction()),
                catchError(handleRequestError)
            )
        })
    )

const loadAreas: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_AREAS),
        switchMap(() => {
            const url = `${api.BASE_URL_V1}/areas/?query={id,name}`
            return from(axios.get<Area[]>(url, token(state$))).pipe(
                map(({ data }) => loadAreasSuccessAction(data.map((a: any) => Area.fromAPI(a)))),
                catchError(handleRequestError)
            )
        })
    )

const addRow: Epic = (action$, state$) =>
    action$.pipe(
        ofType(ADD_ROW),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/rows/`
            return from(axios.post<Area>(url, Row.toApi(payload), token(state$))).pipe(
                map(({ data }) => addRowSuccessAction(Row.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )


const successDeleteRow: Epic = action$ =>
    action$.pipe(
        ofType(DELETE_ROW_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Reihe gelöscht.`)))
    )

const successAddRow: Epic = action$ =>
    action$.pipe(
        ofType(ADD_ROW_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Reihe hinzugefügt.`)))
    )

const redirectToRows: Epic = action$ =>
    action$.pipe(
        ofType(DELETE_ROW_SUCCESS, ADD_ROW_SUCCESS),
        map(() => {
            return push('/stock/rows')
        })
    )


export default combineEpics(
    loadRows,
    deleteRow,
    successDeleteRow,
    redirectToRows,
    loadAreas,
    addRow,
    successAddRow
);