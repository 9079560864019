/**
* Created on Wed Feb 17 2021 11:39:36
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Box from "../../shared/models/Box";
import BoxContent from "../../shared/models/BoxContent";
import BoxImage from "../../shared/models/BoxImage";
import RequestParams from "../../shared/models/RequestParams";

export const ERROR_BOX = '@@box/ERROR_BOX';
export type ERROR_BOX = typeof ERROR_BOX;

export const LOAD_BOX = '@@box/LOAD_BOX';
export type LOAD_BOX = typeof LOAD_BOX;

export const LOAD_BOX_SUCCESS = '@@box/LOAD_BOX_SUCCESS';
export type LOAD_BOX_SUCCESS = typeof LOAD_BOX_SUCCESS;

export const UPDATE_BOX = '@@box/UPDATE_BOX';
export type UPDATE_BOX = typeof UPDATE_BOX;

export const UPDATE_BOX_SUCCESS = '@@box/UPDATE_BOX_SUCCESS';
export type UPDATE_BOX_SUCCESS = typeof UPDATE_BOX_SUCCESS;

export const DELETE_BOX = '@@box/DELETE_BOX';
export type DELETE_BOX = typeof DELETE_BOX;

export const DELETE_BOX_SUCCESS = '@@box/DELETE_BOX_SUCCESS';
export type DELETE_BOX_SUCCESS = typeof DELETE_BOX_SUCCESS;

export const DELETE_BOX_IMAGE = '@@box/DELETE_BOX_IMAGE';
export type DELETE_BOX_IMAGE = typeof DELETE_BOX_IMAGE;

export const DELETE_BOX_IMAGE_SUCCESS = '@@box/DELETE_BOX_IMAGE_SUCCESS';
export type DELETE_BOX_IMAGE_SUCCESS = typeof DELETE_BOX_IMAGE_SUCCESS;

export const VIEWED_BOX_IMAGE = '@@box/VIEWED_BOX_IMAGE';
export type VIEWED_BOX_IMAGE = typeof VIEWED_BOX_IMAGE;

export const VIEWED_BOX_IMAGE_SUCCESS = '@@box/VIEWED_BOX_IMAGE_SUCCESS';
export type VIEWED_BOX_IMAGE_SUCCESS = typeof VIEWED_BOX_IMAGE_SUCCESS;

export const ADD_CONTENT = '@@box/ADD_CONTENT';
export type ADD_CONTENT = typeof ADD_CONTENT;

export const ADD_CONTENT_SUCCESS = '@@box/ADD_CONTENT_SUCCESS';
export type ADD_CONTENT_SUCCESS = typeof ADD_CONTENT_SUCCESS;

export const UPDATE_CONTENT = '@@box/UPDATE_CONTENT';
export type UPDATE_CONTENT = typeof UPDATE_CONTENT;

export const UPDATE_CONTENT_SUCCESS = '@@box/UPDATE_CONTENT_SUCCESS';
export type UPDATE_CONTENT_SUCCESS = typeof UPDATE_CONTENT_SUCCESS;

export const DELETE_CONTENT = '@@box/DELETE_CONTENT';
export type DELETE_CONTENT = typeof DELETE_CONTENT;

export const DELETE_CONTENT_SUCCESS = '@@box/DELETE_CONTENT_SUCCESS';
export type DELETE_CONTENT_SUCCESS = typeof DELETE_CONTENT_SUCCESS;

export const errorBoxAction = createAction(ERROR_BOX)<string>();

export const loadBoxAction = createAction(LOAD_BOX)<number, RequestParams>();
export const loadBoxSuccessAction = createAction(LOAD_BOX_SUCCESS)<Box>();

export const updateBoxAction = createAction(UPDATE_BOX)<Box>();
export const updateBoxSuccessAction = createAction(UPDATE_BOX_SUCCESS)<Box>();

export const deleteBoxAction = createAction(DELETE_BOX)<Box>();
export const deleteBoxSuccessAction = createAction(DELETE_BOX_SUCCESS)<void>();

export const deleteBoxImageAction = createAction(DELETE_BOX_IMAGE)<BoxImage>();
export const deleteBoxImageSuccessAction = createAction(DELETE_BOX_IMAGE_SUCCESS)<number>();

export const viewedBoxImageAction = createAction(VIEWED_BOX_IMAGE)<BoxImage>();
export const viewedBoxImageSuccessAction = createAction(VIEWED_BOX_IMAGE_SUCCESS)<BoxImage>();

export const addContentAction = createAction(ADD_CONTENT)<BoxContent>();
export const addContentSuccessAction = createAction(ADD_CONTENT_SUCCESS)<BoxContent>();

export const updateContentAction = createAction(UPDATE_CONTENT)<BoxContent>();
export const updateContentSuccessAction = createAction(UPDATE_CONTENT_SUCCESS)<BoxContent>();

export const deleteContentAction = createAction(DELETE_CONTENT)<BoxContent>();
export const deleteContentSuccessAction = createAction(DELETE_CONTENT_SUCCESS)<number>();

export type BoxAction = ActionType<
    | typeof errorBoxAction
    | typeof loadBoxAction
    | typeof loadBoxSuccessAction
    | typeof deleteBoxImageAction
    | typeof deleteBoxImageSuccessAction
    | typeof viewedBoxImageAction
    | typeof viewedBoxImageSuccessAction
    | typeof addContentAction
    | typeof addContentSuccessAction
    | typeof updateContentAction
    | typeof updateContentSuccessAction
    | typeof deleteContentAction
    | typeof deleteContentSuccessAction
    | typeof deleteBoxAction
    | typeof deleteBoxSuccessAction
>;