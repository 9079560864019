/**
* Created on Sat Feb 20 2021 15:51:35
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import axios from "axios";
import { push } from "connected-react-router";
import { combineEpics, Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import api from "../../api";
import { notifySuccessAction } from "../../shared/actions/notify.actions";
import Notification from "../../shared/models/Notification";
import Pagination from "../../shared/models/Pagination";
import User from "../../shared/models/User";
import { token } from "../../shared/utils";
import { handleRequestError } from "../../shared/utils/errors";
import { addUserSuccessAction, ADD_USER, ADD_USER_SUCCESS, changeUserPasswordSuccessAction, CHANGE_USER_PASSWORD, CHANGE_USER_PASSWORD_SUCCESS, loadUsersSuccessAction, LOAD_USERS } from "../actions/users.actions";


const loadUsers: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_USERS),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/users/${payload.params()}`
            return from(axios.get<User[]>(url, token(state$))).pipe(
                map(({ data }) => loadUsersSuccessAction(Pagination.fromAPI(data, User.fromAPI))),
                catchError(handleRequestError)
            )
        })
    )


const addUser: Epic = (action$, state$) =>
    action$.pipe(
        ofType(ADD_USER),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/users/`
            return from(axios.post<User>(url, User.toAPI(payload), token(state$))).pipe(
                map(({ data }) => addUserSuccessAction(User.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const changePassword: Epic = (action$, state$) =>
    action$.pipe(
        ofType(CHANGE_USER_PASSWORD),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/users/${state$.value.auth.user.id}/password/`
            return from(axios.put(url, payload, token(state$))).pipe(
                map(({ data }) => changeUserPasswordSuccessAction()),
                catchError(handleRequestError)
            )
        })
    )

const successAddUser: Epic = action$ =>
    action$.pipe(
        ofType(ADD_USER_SUCCESS),
        map(() => notifySuccessAction(new Notification(`User hinzugefügt.`)))
    )

const successChangePassword: Epic = action$ =>
    action$.pipe(
        ofType(CHANGE_USER_PASSWORD_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Passwort geändert.`)))
    )

const redirectToUsers: Epic = action$ =>
    action$.pipe(
        ofType(ADD_USER_SUCCESS),
        map(() => {
            return push('/users')
        })
    )

export default combineEpics(
    loadUsers,
    addUser,
    redirectToUsers,
    successAddUser,
    changePassword,
    successChangePassword,
)