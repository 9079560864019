/**
* Created on Sun Jan 24 2021 15:37:17
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";

export function isAuth(state: AppState) {
    return state.auth.auth;
}

export function getToken(state: AppState) {
    return state.auth.token;
}

export function isStaff(state: AppState) {
    if (state.auth.user) {
        return state.auth.user?.isStaff
    }
    return false
}

export function isLoading(state: AppState) {
    return state.auth.loading;
}

export function isLoaded(state: AppState) {
    return state.auth.loaded;
}

export function authUser(state: AppState) {
    return state.auth.user;
}