/**
* Created on Wed Jan 20 2021 10:08:07
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '../../shared/components/buttons/Button';
import Input from '../../shared/components/inputs/Input';
import { loginAction } from '../actions/login.actions';
import logo from './../../shared/images/adl-logo.gif';
import validationSchema from './validationSchema';
import tschudenITSolutionsLogo from './../../shared/images/logo-tschuden-itsolutions.png'

function Login({
    location
}: RouteComponentProps) {

    const dispatch = useDispatch();

    const initValues = {
        username: "",
        password: ""
    }

    return (
        <div className="w-full h-screen bg-gray-200">
            <div className="flex items-center justify-center h-full">
                <div className="border-4 border-adl-1">
                    <div className="p-8 bg-white">
                        <div className="flex flex-col items-center">
                            <img src={logo} alt="" className="h-24 pb-4" />
                            <div className="text-xl font-semibold tracking-tight">
                                ADL Management System
                            </div>
                        </div>
                        <div className="mt-8">
                            <Formik
                                initialValues={initValues}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false)
                                    dispatch(loginAction({
                                        ...values,
                                        username: values.username.toLocaleLowerCase(),
                                    }))
                                }}
                            >
                                {({
                                    isSubmitting,
                                    isValid,
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <div className="mb-2">
                                            <Input
                                                title="E-Mail"
                                                type="text"
                                                name="username"
                                                controlId={"login-username"}
                                                value={values.username}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={touched.username && !!errors.username}
                                                isValid={touched.username && !errors.username}
                                                error={errors.username}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <Input
                                                title="Passwort"
                                                type="password"
                                                name="password"
                                                controlId={"login-password"}
                                                value={values.password}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={touched.password && !!errors.password}
                                                isValid={touched.password && !errors.password}
                                                error={errors.password}
                                            />
                                        </div>
                                        <Button
                                            type="submit"
                                            disabled={!isValid || isSubmitting}
                                        >
                                            Anmelden
                                    </Button>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 w-full">
                <div className="flex justify-center">
                    <a className="flex flex-col items-center" href="https://tschuden-itsolutions.de" target="_blank" rel="noopener noreferrer">
                        <div className="text-sm text-gray-400">
                            Ein Lösung von
                        </div>
                        <img src={tschudenITSolutionsLogo} alt="" className="w-12 my-1" />
                        <span className="mb-1 text-xs text-gray-400">v{process.env.REACT_APP_VERSION}</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Login)