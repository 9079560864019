/**
* Created on Sat Apr 17 2021 14:57:20
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect } from 'react';
import { FaFileDownload, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import { PageContent } from '../../../shared/components/page';
import Table from '../../../shared/components/table';
import Box from '../../../shared/models/Box';
import BoxFilter from '../../../shared/models/BoxFilter';
import OrderParams from '../../../shared/models/OrderParams';
import PageParams from '../../../shared/models/PageParams';
import RequestParams from '../../../shared/models/RequestParams';
import { formatDate } from '../../../shared/utils';
import { downloadLabelPrintAction, loadBoxesAction, resetLabelPrintAction } from '../../actions/services.actions';
import { getBoxes, getLabelPrints, loadingBoxes } from '../../selector/labelprint.selector';
import ActionsButton from './ActionsButton';
import ExtendedFilter from './filter';

export default function () {

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        const page = search.get('page')
        const orderBy = search.get('orderBy')
        //TODO "?" -> haut irgendiw nicht hin wenn es bei der suche dabei ist
        const params = new RequestParams(location.search)
        const boxFilter = new BoxFilter().fromParams(search.toString())
        if (page) {
            params.page = new PageParams(parseInt(page))
        } else {
            params.page = new PageParams()
        }
        if (orderBy) {
            params.order = new OrderParams(orderBy)
        }
        if (boxFilter) {
            params.otherParams = boxFilter.params(params.otherParams)
        }
        dispatch(loadBoxesAction(params))
    }, [dispatch, location])

    const selectedBoxes = useSelector(getLabelPrints)

    const boxInLabelPrint = (b: Box): boolean => {
        const index = selectedBoxes.findIndex(l => l.code === b.code)
        if (index !== -1) {
            return true
        } else {
            return false
        }
    }

    const countBox = (b: Box): number => {
        const index = selectedBoxes.findIndex(l => l.code === b.code)
        if (index !== -1) {
            return selectedBoxes[index].amount
        }
        return 0
    }

    const boxes = useSelector(getBoxes)
    const loading = useSelector(loadingBoxes)
    const columns = [
        { name: "Code", key: "code", orderBy: true, render: (b: Box) => b.formatCode() },
        { name: "State", key: "state", render: (b: Box) => b.state.text },
        { name: "erstellt", key: "created", render: (b: Box) => formatDate(b.created!) },
        { name: `Anzahl ( ${selectedBoxes.length} )`, key: "", render: (b: Box) => countBox(b).toString() },
        { name: "Action", key: "", render: (b: Box) => <ActionsButton boxes={[b]} /> }
    ]


    return (
        <PageContent>
            <Card title={<div className="flex items-center justify-between">
                <div>
                    Etikettendruck
                </div>
                <div className="flex gap-x-2">
                    <Button
                        color="warning"
                        icon={<FaTimes />}
                        type="button"
                        disabled={ selectedBoxes.length === 0 }
                        onClick={() => dispatch(resetLabelPrintAction()) }
                    >
                        Reset
                    </Button>
                    <Button
                        icon={<FaFileDownload />}
                        type="button"
                        onClick={() => dispatch(downloadLabelPrintAction(selectedBoxes))}
                        disabled={ selectedBoxes.length === 0 }
                    >
                        Download
                    </Button>
                </div>
            </div>}
            >
                <ExtendedFilter />
                <div className="p-2 border-b-4 border-adl-1">
                    <div className="pb-2 font-semibold text-gray-600">
                        Action für alle in der Tabelle zu sehenden Boxen
                    </div>
                    <ActionsButton boxes={boxes.data} />
                </div>
                <Table
                    data={boxes.data}
                    pagination={boxes}
                    loading={loading}
                    columns={columns}
                    rowHighlight={(b: Box) => {
                        if (boxInLabelPrint(b)) {
                            return "bg-adl-2"
                        }
                        return ""
                    }}
                />
            </Card>
        </PageContent>
    )
}