/**
* Created on Mon Feb 08 2021 14:44:46
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageContent } from '../../../shared/components/page';
import Table from '../../../shared/components/table';
import RequestParams from '../../../shared/models/RequestParams';
import { loadUsersAction } from '../../actions/users.actions';
import PageParams from '../../../shared/models/PageParams';
import { useLocation } from 'react-router-dom';
import { getUsers, loadingUsers } from '../../selectors/users.selector';
import User from '../../../shared/models/User';
import BooleanCell from '../../../shared/components/table/BooleanCell';
import { Card } from '../../../shared/components/card';
import OrderParams from '../../../shared/models/OrderParams';
import Button from '../../../shared/components/buttons/Button';
import { history } from '../../../store/history';
import Filter, { FilterObject } from '../../../shared/models/Filter';
import { formatDate } from '../../../shared/utils';

export default function () {

    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        const search = new URLSearchParams(location.search)
        const page = search.get('page')
        const orderBy = search.get('orderBy')
        const params = new RequestParams(location.search)
        if (page) {
            params.page = new PageParams(parseInt(page))
        } else {
            params.page = new PageParams()
        }
        if (orderBy) {
            params.order = new OrderParams(orderBy)
        }
        dispatch(loadUsersAction(params))
    }, [dispatch, location])

    const users = useSelector(getUsers)
    const loading = useSelector(loadingUsers)

    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const setFilterParams = (filter: FilterObject) => {
        if (timer) {
            clearTimeout(timer)
            setTimer(setTimeout(() => {
                history.push(location.pathname + "?" + new Filter(filter, ["last_name", "email"]).params(location.search))
                setTimer(undefined)
            }, 1000))
        } else {
            setTimer(setTimeout(() => {
                history.push(location.pathname + "?" + new Filter(filter, ["last_name", "email"]).params(location.search))
                setTimer(undefined)
            }, 1000))
        }
    }

    const columns = [
        { name: "Anrede", key: "gender", render: (u: User) => u.gender!.text },
        { 
            name: "Name", 
            key: "last_name",
            orderBy: true, 
            render: (u: User) => `${u.lastName}${u.firstName ? ", " + u.firstName : ""}`
        },
        { name: "E-Mail", key: "email" },
        { name: "Tel.", key: "phone1" },
        { name: "Zugriff Kunde", key: "" },
        { name: "Letzter Login", key: "lastLogin", render: (u: User) => formatDate(u.lastLogin!) },
        { name: "Inital-Password", key: "", render: (u: User) => <BooleanCell value={u.initPassword!} /> },
        { name: "Admin", key: "isStaff", render: (u: User) => <BooleanCell value={u.isStaff!} /> },
    ]

    return (
        <PageContent>
            <Card title={
                <div className="flex items-center justify-between">
                    <div>
                        Users ( {users.totalItems} )
                    </div>
                    <div>
                        <Button onClick={() => history.push("/users/new")}>
                            Neuen User hinzufügen
                        </Button>
                    </div>
                </div>
            }>
                <Table
                    columns={columns}
                    data={users.data}
                    pagination={users}
                    loading={loading}
                    onFilter={setFilterParams}
                    filter={["last_name", "email"]}
                />
            </Card>
        </PageContent>
    )
}
