/**
* Created on Thu Feb 18 2021 15:19:10
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import Input from '../../../shared/components/inputs/Input';
import Select from '../../../shared/components/inputs/Select';
import { PageContent } from '../../../shared/components/page';
import Customer from '../../../shared/models/Customer';
import { addBoxesAction, loadCustomersAction } from '../../actions/boxes.actions';
import {creatingBoxes, possibleCustomers} from '../../selectors/boxes.selectors';
import validationSchema from './validationSchema';

export default function () {

    const initValues = {
        owner: undefined,
        start: 0,
        amount: 1,
        size: 400,
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCustomersAction())
    }, [dispatch])

    const customers = useSelector(possibleCustomers)
    const creating = useSelector(creatingBoxes)

    return (
        <PageContent>
            <Card title={"Boxen hinzufügen"}>
                <div className="p-1">
                    <Formik
                        initialValues={initValues}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false)
                            const data:any = {
                                ...values,
                                owner_id: JSON.parse(values.owner!).id
                            }
                            dispatch(addBoxesAction(data))
                        }}
                        
                        validationSchema={validationSchema}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            errors,
                            touched,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <div className="p-1">
                                    <Select
                                        title="Kunde"
                                        name="owner"
                                        controlId="create-box-owner"
                                        data={customers}
                                        renderOption={(c: Customer) => `( ${c.code} ) ${c.name}`}
                                        value={values.owner}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.owner && !!errors.owner}
                                        error={errors.owner}
                                        required={true}
                                    />
                                </div>
                                <div className="p-1">
                                    <Input
                                        title="Nummer Start"
                                        type="number"
                                        name="start"
                                        controlId="create-box-start"
                                        value={values.start}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.start && !!errors.start}
                                        isValid={touched.start && !errors.start}
                                        error={errors.start}
                                        required={true}
                                    />
                                </div>
                                <div className="p-1">
                                    <Input
                                        title="Anzahl"
                                        name="amount"
                                        controlId="create-box-amount"
                                        value={values.amount}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.amount && !!errors.amount}
                                        isValid={touched.amount && !errors.amount}
                                        error={errors.amount}
                                        required={true}
                                    />
                                </div>
                                <div className="p-1">
                                    <Input
                                        title="Boxgröße in mm"
                                        name="size"
                                        controlId="create-box-size"
                                        value={values.size}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.size && !!errors.size}
                                        isValid={touched.size && !errors.size}
                                        error={errors.size}
                                        required={true}
                                    />
                                </div>
                                <div className="px-1 py-2">
                                    <Button
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                        pending={creating}
                                    >
                                        Hinzufügen
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Card>
        </PageContent>
    )
}