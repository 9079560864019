/**
* Created on Mon Feb 08 2021 15:41:29
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import Input from '../../../../shared/components/inputs/Input';
import Select from '../../../../shared/components/inputs/Select';

import BoxFilter from '../../../../shared/models/BoxFilter';
import BoxState from '../../../../shared/models/BoxState';
import { history } from '../../../../store/history';
import validationSchema from './validationSchema';

export default function ExtendedFilter() {

    const location = useLocation();
    const [filter, setFilter] = useState(new BoxFilter().fromParams(location.search))
    const [timer, setTimer] = useState<NodeJS.Timeout>()

    useEffect(() => {
        setFilter(new BoxFilter().fromParams(location.search))
    }, [location])

    const setFilterParams = (e: React.FormEvent<HTMLInputElement>) => {
        const newFilter = BoxFilter.fromEvent(filter, e)        
        validationSchema.validate(newFilter).then(
            _ => {
                if (timer) {
                    clearTimeout(timer)
                    setTimer(setTimeout(() => {
                        history.push(location.pathname + filter.toParams())
                        setTimer(undefined)
                    }, 1000))
                } else {
                    setTimer(setTimeout(() => {
                        history.push(location.pathname + filter.toParams())
                        setTimer(undefined)
                    }, 1000))
                }
            }
        ).catch(
            _ => console.log("invalid filter")
        )
        setFilter(newFilter)
    }


    return (
        <div className="flex items-center py-2 border-b-4 border-adl-1">
            <div className="p-4">
                <FaSearch />
            </div>
            <Formik
                validationSchema={validationSchema}
                initialValues={new BoxFilter().fromParams(location.search)}
                onSubmit={(values) => { console.log(values); }}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    touched,
                    errors,
                    isValid
                }) => (
                    <form noValidate className="flex flex-wrap items-center" onSubmit={handleSubmit}>
                        <div className="pr-2">
                            <Input
                                title="Kunden Nummer"
                                type="number"
                                name="owner"
                                controlId={"box-filter-owner"}
                                value={values.owner}
                                onBlur={handleBlur}
                                onChange={e => { handleChange(e); setFilterParams(e); }}
                                isInvalid={touched.owner && !!errors.owner}
                                isValid={touched.owner && !errors.owner}
                                error={errors.owner}
                            />
                        </div>
                        <div className="pr-2">
                            <Input
                                title="von"
                                type="number"
                                name="from"
                                value={values.from}
                                controlId={"box-filter-from"}
                                onBlur={handleBlur}
                                onChange={e => { handleChange(e); setFilterParams(e); }}
                                isInvalid={touched.from && !!errors.from}
                                isValid={touched.from && !errors.from}
                                error={errors.from}
                            />
                        </div>
                        <div className="pr-2">
                            <Input
                                title="bis"
                                type="number"
                                name="to"
                                value={values.to}
                                controlId={"box-filter-to"}
                                onBlur={handleBlur}
                                onChange={e => { handleChange(e); setFilterParams(e); }}
                                isInvalid={touched.to && !!errors.to}
                                isValid={touched.to && !errors.to}
                                error={errors.to}
                            />
                        </div>
                        <div className="pr-2">
                            <Select
                                title="Status"
                                data={BoxState.states()}
                                showKey={"text"}
                                name="state"
                                controlId={"box-filter-state"}
                                onChange={e => { handleChange(e); setFilterParams(e); }}
                                onBlur={handleBlur}
                                value={values.state}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}