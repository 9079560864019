/**
* Created on Mon Apr 26 2021 14:46:28
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import Button from '../../shared/components/buttons/Button';
import { Card } from '../../shared/components/card';
import Input from '../../shared/components/inputs/Input';
import validationSchema from './validationSchema';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { changeUserPasswordAction } from '../../users/actions/users.actions';

export default function () {

    const initValues = {
        old: "",
        new1: "",
        new2: ""
    }

    const dispatch = useDispatch()

    return (
        <div>
            <Card
                title="Bitte ändern Sie ihr Initial-Passwort!"
            >
                <div className="p-1">
                    <Formik
                        initialValues={initValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false)
                            const data:any = {
                                old_password: values.old,
                                new_password: values.new2 
                            }
                            dispatch(changeUserPasswordAction(data))
                        }}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            errors,
                            touched,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <div className="p-1">
                                    <Input
                                        title="Aktuelles Passwort"
                                        type="password"
                                        name="old"
                                        controlId="change-password-old"
                                        value={values.old}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.old && !!errors.old}
                                        isValid={touched.old && !errors.old}
                                        error={errors.old}
                                        required={true}
                                    />
                                </div>
                                <div className="p-1">
                                    <Input
                                        title="Neues Passwort"
                                        type="password"
                                        name="new1"
                                        controlId="change-password-new1"
                                        value={values.new1}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.new1 && !!errors.new1}
                                        isValid={touched.new1 && !errors.new1}
                                        error={errors.new1}
                                        required={true}
                                    />
                                </div>
                                <div className="p-1">
                                    <Input
                                        title="Neues Passwort wiederholen"
                                        type="password"
                                        name="new2"
                                        controlId="change-password-new2"
                                        value={values.new2}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.new2 && !!errors.new2}
                                        isValid={touched.new2 && !errors.new2}
                                        error={errors.new2}
                                        required={true}
                                    />
                                </div>
                                <div className="px-1 py-2">
                                    <Button
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Passwort ändern
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Card>
        </div>
    )
}