import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import { loginSuccessAction, LOGIN_SUCCESS } from "../../login/actions/login.actions";
import { changeUserPasswordSuccessAction, CHANGE_USER_PASSWORD_SUCCESS } from '../../users/actions/users.actions';
import { AUTH, authAction, authSuccessAction, AUTH_SUCCESS, LOGOUT, logoutAction, noTokenAction, NO_TOKEN } from '../actions/auth.actions';
import { FORBIDDEN, forbiddenAction } from '../actions/error.actions';
import Token from '../models/Token';
import User from '../models/User';

export interface State {
    token: Token | undefined,
    auth: boolean,
    user: User | undefined,
    loading: boolean,
    loaded: boolean,
}

export default function (
    state: State = {
        token: Token.fromStorage(),
        auth: false,
        user: undefined,
        loading: false,
        loaded: false,
    },
    action: ActionType<
        | typeof authAction
        | typeof loginSuccessAction
        | typeof authSuccessAction
        | typeof logoutAction
        | typeof noTokenAction
        | typeof forbiddenAction
        | typeof changeUserPasswordSuccessAction
    >
): State {

    switch(action.type){
        case AUTH:
            return update(state, {
                loading: { $set: true }
            })
        case LOGIN_SUCCESS:
            action.payload.toStorage()
            return update(state, {
                auth: { $set: true},
                loading: { $set: false },
                loaded: { $set: true },
                user: { $set: action.payload.user },
                token: { $set: action.payload }
            })
        case NO_TOKEN:
            return update( state, {
                auth: { $set: false},
                loaded: { $set: true },
            })
        case AUTH_SUCCESS:
            return update(state, {
                auth: { $set: true },
                loading: { $set: false },
                loaded: { $set: true },
                user: { $set: action.payload }
            })
        case LOGOUT:
            Token.clear()
            return update(state, {
                auth: { $set: false },
                user: { $set: undefined },
                token: { $set: undefined }
            })
        case FORBIDDEN:
            Token.clear()
            return update(state, {
                auth: { $set: false },
                user: { $set: undefined },
                token: { $set: undefined }
            })
        case CHANGE_USER_PASSWORD_SUCCESS:
            return update(state, {
                user: {
                    initPassword: { $set: false }
                }
            })
        default:
            return state
    }

}