import * as Yup from 'yup'

function emptyStringToNull(value: number, originalValue: any) {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null;
    }
    return value;
  }

const validationSchema = Yup.object().shape({
    owner: Yup.number().min(0,"Ungültig").max(9999,"Ungültig").transform(emptyStringToNull).nullable(),
    from: Yup.number().min(0,"Ungültig").max(999999,"Ungültig").transform(emptyStringToNull).nullable(),
    to: Yup.number().min(0,"Ungültig").max(999999,"Ungültig").transform(emptyStringToNull).nullable()
})

export default validationSchema;