/**
* Created on Fri Feb 05 2021 09:52:16
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import RequestParams from "./RequestParams";

export default class OrderParams {

    constructor(
        public key: string | null
    ){}

    public params(other?: string): string {
        const params = new URLSearchParams(other);
        if (this.key){
            params.set('order_by', this.key!)
            params.delete('orderBy')
            return params.toString();
        } 
        return params.toString()
    }

    static fromSearch(search: string, requestParams: RequestParams) {
        const s = new URLSearchParams(search)
        const orderBy = s.get('orderBy')
        if (orderBy) {
            requestParams.order = new OrderParams(orderBy)
        }
    }

}