/**
 * Created on Thu Feb 25 2021 11:34:51
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import {combineEpics, Epic, ofType} from "redux-observable";
import {switchMap, map, catchError} from "rxjs/operators";
import api from "../../api";
import {from} from "rxjs";
import axios from "axios";
import Delivery from "../../shared/models/Delivery";
import {token} from "../../shared/utils";
import Pagination from "../../shared/models/Pagination";
import {
    addDeliverySuccessAction,
    ADD_DELIVERY,
    ADD_DELIVERY_SUCCESS,
    loadBoxesSuccessAction,
    loadCustomersSuccessAction,
    loadDeliveriesSuccessAction,
    LOAD_BOXES,
    LOAD_CUSTOMERS,
    LOAD_DELIVERIES
} from "../actions/delieveries.actions";
import Box from "../../shared/models/Box";
import Customer from "../../shared/models/Customer";
import {push} from "connected-react-router";
import {
    loadDeliverySuccessAction,
    LOAD_DELIVERY,
    DOWNLOAD_DELIVERY_NOTE,
    downloadDeliveryNoteSuccessAction,
    CANCEL_DELIVERY,
    cancelDeliverySuccessAction,
    UPDATE_DELIVERY,
    updateDeliverySuccessAction, UPDATE_DELIVERY_SUCCESS
} from "../actions/delivery.actions";
import {handleRequestError} from "../../shared/utils/errors";
import {notifySuccessAction} from "../../shared/actions/notify.actions";
import Notification from "../../shared/models/Notification";

const loadDeliveries: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_DELIVERIES),
        switchMap(({payload}) => {
            const url = `${api.BASE_URL_V1}/deliveries/${payload.params()}`
            return from(axios.get<Delivery[]>(url, token(state$))).pipe(
                map(({data}) => loadDeliveriesSuccessAction(Pagination.fromAPI(data, Delivery.fromAPI))),
                //catchError(handleRequestError)
            )
        })
    )

// load possible boxes
const loadBoxes: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_BOXES),
        switchMap(({payload}) => {
            const url = `${api.BASE_URL_V1}/boxes/${payload.params()}`
            return from(axios.get(url, token(state$))).pipe(
                map(({data}) => loadBoxesSuccessAction(Pagination.fromAPI(data, Box.fromAPI))),
                catchError(handleRequestError)
            )
        })
    )


const loadCustomers: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_CUSTOMERS),
        switchMap(({payload}) => {
            const url = `${api.BASE_URL_V1}/customers/${payload.params()}`
            return from(axios.get(url, token(state$))).pipe(
                map(({data}) => loadCustomersSuccessAction(data.map((c: any) => Customer.fromAPI(c)))),
                catchError(handleRequestError)
            )
        })
    )

const cancelDelivery: Epic = (action$, state$) =>
    action$.pipe(
        ofType(CANCEL_DELIVERY),
        switchMap(({payload}) => {
            const url = `${api.BASE_URL_V1}/deliveries/${payload.id}/cancel/`
            return from(axios.get(url, token(state$))).pipe(
                map(({data}) => cancelDeliverySuccessAction(Delivery.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const addDelivery: Epic = (action$, state$) =>
    action$.pipe(
        ofType(ADD_DELIVERY),
        switchMap(({payload: data}) => {
            const url = `${api.BASE_URL_V1}/deliveries/`
            return from(axios.post(url, Delivery.toAPI(data), token(state$))).pipe(
                map(({data}) => addDeliverySuccessAction(Delivery.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const loadDelivery: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_DELIVERY),
        switchMap(({payload}) => {
            const url = `${api.BASE_URL_V1}/deliveries/${payload}/`
            return from(axios.get<Delivery>(url, token(state$))).pipe(
                map(({data}) => loadDeliverySuccessAction(Delivery.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const updateDelivery: Epic = (action$, state$ ) =>
    action$.pipe(
        ofType(UPDATE_DELIVERY),
        switchMap(({payload}) => {
            const url = `${api.BASE_URL_V1}/deliveries/${payload.id}/`
            return from(axios.patch<Delivery>(url, Delivery.toAPI(payload), token(state$))).pipe(
                map(({data}) => updateDeliverySuccessAction(Delivery.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const downloadDeliveryNote: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DOWNLOAD_DELIVERY_NOTE),
        switchMap(({payload}) => {
            const url = `${api.BASE_URL_V1}/deliveries/${payload.id}/note/`
            return from(axios.get(url, {...token(state$), responseType: 'blob'})).pipe(
                map((response) => downloadDeliveryNoteSuccessAction(response)),
                catchError(handleRequestError)
            )
        })
    )

const redirectToDelivery: Epic = action$ =>
    action$.pipe(
        ofType(ADD_DELIVERY_SUCCESS),
        map(() => {
            return push("/deliveries")
        })
    )

const successUpdateDelivery: Epic = action$ =>
    action$.pipe(
        ofType(UPDATE_DELIVERY_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Lieferung aktualisiert`)))
    )

export default combineEpics(
    loadDeliveries,
    loadBoxes,
    loadCustomers,
    addDelivery,
    redirectToDelivery,
    loadDelivery,
    downloadDeliveryNote,
    cancelDelivery,
    updateDelivery,
    successUpdateDelivery,
)