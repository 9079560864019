/**
* Created on Sat Jan 23 2021 18:27:10
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Area from "../../shared/models/Area";
import { LoadState } from '../../shared/models/RequestState';
import { 
    loadAreasAction,
    loadAreasSuccessAction,
    LOAD_AREAS,
    LOAD_AREAS_SUCCESS
} from "../actions/areas.actions";

export interface State {
    data: Area[],
    load: LoadState,
}

export default function (
    state: State = {
        data: [],
        load: LoadState.init()
    },
    action: ActionType<
        | typeof loadAreasAction
        | typeof loadAreasSuccessAction
    >
): State {
    switch (action.type) {
        case LOAD_AREAS:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_AREAS_SUCCESS:
            return update(state, {
                data: { $set: action.payload.map(area => Area.fromAPI(area)) },
                load: { $set: LoadState.loaded() }
            })
        default:
            return state
    }
}