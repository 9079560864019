/**
* Created on Sat Jan 23 2021 18:58:27
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import { PageContent } from '../../../shared/components/page';
import Table from '../../../shared/components/table';
import Area from '../../../shared/models/Area';
import { history } from '../../../store/history';
import { loadAreasAction } from '../../actions/areas.actions';
import { getAreas, loadingAreas } from '../../selectors/areas.selectors';

export default function Areas() {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(loadAreasAction());
    }, [dispatch])

    const loading = useSelector(loadingAreas);
    const areas = useSelector(getAreas);
    const columns = [
        { name: "Name", key: "name" },
        { name: "#Reihen", key: "rowsCount" },
        { name: "#Boxen", key: "boxesCount" },

    ]

    return (
        <PageContent>
            <Card title={<div className="flex items-center justify-between">
                <div>
                    Bereiche ( {areas.length} )
                </div>
                <div>
                    <Button onClick={() => history.push(`/stock/areas/new`)}>
                        Neuen Bereich hinzufügen
                    </Button>
                </div>
            </div>
            }>
                <Table
                    columns={columns}
                    data={areas}
                    loading={loading}
                    onClickRow={(a: Area) => history.push(`/stock/areas/${a.id}`)}
                />
            </Card>
        </PageContent>
    )
}