/**
* Created on Fri Mar 05 2021 09:20:17
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getToken } from '../selectors/auth.selector';

interface Props {
    url: string
}

export default function Image({
    url
}: Props) {

    const token = useSelector(getToken)
    const [ image, setImage ] = useState("")
    const [mounted, setMounted] = useState(true)


    useEffect(() => {
        loadImage()
        return () => {
            setMounted(false)
        }
    }, [mounted])

    const loadImage = () => {
        fetch(url, {
            headers: {
                Authorization: 'Token ' + token?.token
            }
        })
        .then(response => response.blob())
        .then(blob => {
            if(mounted) {
                setImage(URL.createObjectURL(blob))
            }
        })
    }

    return(
        <a href={image} target="_blank" rel="noopener noreferrer">
            <img src={image} alt=""/>
        </a>
    )
}