/**
* Created on Sat Apr 17 2021 20:45:01
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Box from "../../shared/models/Box";
import Pagination from '../../shared/models/Pagination';
import { LoadState } from '../../shared/models/RequestState';
import { add24LabelAction, ADD24_LABEL, addLabelAction, ADD_LABEL, downloadLabelPrintSuccessAction, DOWNLOAD_LABEL_PRINT_SUCCESS, loadBoxesAction, loadBoxesSuccessAction, LOAD_BOXES, LOAD_BOXES_SUCCESS, removeLabelAction, REMOVE_LABEL, resetLabelAction, resetLabelPrintAction, RESET_LABEL, RESET_LABEL_PRINT } from "../actions/services.actions";
import download from 'downloadjs'
import { v4 as uuidv4 } from 'uuid'
import LabelPrint from '../../shared/models/LabelPrint';

export interface State {
    boxes: Pagination<Box>
    load: LoadState
    create: LabelPrint[]
}

export default function (
    state: State = {
        boxes: new Pagination<Box>(),
        load: LoadState.init(),
        create: []
    },
    action: ActionType<
        | typeof loadBoxesAction
        | typeof loadBoxesSuccessAction
        | typeof downloadLabelPrintSuccessAction
        | typeof addLabelAction
        | typeof removeLabelAction
        | typeof add24LabelAction
        | typeof resetLabelAction
        | typeof resetLabelPrintAction
    >
): State {
    switch (action.type) {
        case LOAD_BOXES:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_BOXES_SUCCESS:
            return update(state, {
                boxes: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        case ADD_LABEL:
            var index = state.create.findIndex(l => l.code === action.payload.code)
            if (index !== -1) {
                return update(state, {
                    create: {
                        [index]: {
                            amount: { $set: state.create[index].amount + 1 }
                        }
                    }
                })
            } else {
                return update(state, {
                    create: { $push: [new LabelPrint(action.payload.code, 1)] }
                })
            }
        case REMOVE_LABEL:
            var index = state.create.findIndex(l => l.code === action.payload.code)
            if (index !== -1) {
                var amount = state.create[index].amount - 1
                if (amount <= 0) {
                    return update(state, {
                        create: {
                            $splice: [[index!, 1]]
                        }
                    })
                } else {
                    return update(state, {
                        create: {
                            [index]: {
                                amount: { $set: state.create[index].amount - 1 }
                            }
                        }
                    })
                }
            } else {
                return state
            }
        case ADD24_LABEL:
            var index = state.create.findIndex(l => l.code === action.payload.code)
            if (index !== -1) {
                return update(state, {
                    create: {
                        [index]: {
                            amount: { $set: state.create[index].amount + 24 }
                        }
                    }
                })
            } else {
                return update(state, {
                    create: { $push: [new LabelPrint(action.payload.code, 24)] }
                })
            }
        case RESET_LABEL:
            var index = state.create.findIndex(l => l.code === action.payload.code)
            if (index !== -1) {
                return update(state, {
                    create: {
                        $splice: [[index!, 1]]
                    }
                })
            } else {
                return state
            }
        case RESET_LABEL_PRINT:
            return update(state, {
                create: { $set: [] }
            })
        case DOWNLOAD_LABEL_PRINT_SUCCESS:
            download(action.payload.data, `${uuidv4()}.pdf`)
            return state
        default:
            return state
    }
}