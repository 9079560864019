/**
* Created on (Thu) 21.04.2022 13:22:11
*
* @author Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { useEffect, useState } from "react"

export const useDebounce = (value: any, delay:number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;

}
