/**
* Created on Fri Apr 09 2021 08:50:24
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/
 
import { ActionType, createAction } from "typesafe-actions";
import Row from "../../shared/models/Row";

export const LOAD_ROW = '@@row/LOAD_ROW';
export type LOAD_ROW = typeof LOAD_ROW;

export const LOAD_ROW_SUCCESS = '@@row/LOAD_ROW_SUCCESS';
export type LOAD_ROW_SUCCESS = typeof LOAD_ROW_SUCCESS;

export const DELETE_ROW = '@@row/DELETE_ROW';
export type DELETE_ROW = typeof DELETE_ROW;

export const DELETE_ROW_SUCCESS = '@@row/DELETE_ROW_SUCCESS';
export type DELETE_ROW_SUCCESS = typeof DELETE_ROW_SUCCESS;

export const UPDATE_ROW = '@@row/UPDATE_ROW';
export type UPDATE_ROW = typeof UPDATE_ROW;

export const UPDATE_ROW_SUCCESS = '@@row/UPDATE_ROW_SUCCESS';
export type UPDATE_ROW_SUCCESS = typeof UPDATE_ROW_SUCCESS;

export const loadRowAction = createAction(LOAD_ROW)<number>();
export const loadRowSuccessAction = createAction(LOAD_ROW_SUCCESS)<Row>();

export const deleteRowAction = createAction(DELETE_ROW)<Row>();
export const deleteRowSuccessAction = createAction(DELETE_ROW_SUCCESS)();

export const updateRowAction = createAction(UPDATE_ROW)<Row>();
export const updateRowSuccessAction = createAction(UPDATE_ROW_SUCCESS)<Row>();

export type RowAction = ActionType<
    | typeof loadRowAction
    | typeof loadRowSuccessAction
    | typeof deleteRowAction
    | typeof deleteRowSuccessAction
    | typeof updateRowAction
    | typeof updateRowSuccessAction
>;