/**
* Created on Sat Apr 17 2021 13:56:52
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

export enum StatesText {
    OPEN = "Offen",
    PROCESSING = "In Bearbeitung",
    COMPLETED = "Abgeschlossen",
    CANCELED = "Storniert"
}

export enum StatesNum {
    OPEN = 0,
    PROCESSING = 1,
    COMPLETED = 2,
    CANCELED = 3
}

export default class DeliveryState {
    
    public text?: string;
    public num?: number;
    constructor(
        public readonly key: keyof typeof StatesText,
    ){
        this.text = StatesText[key as keyof typeof StatesText]
        this.num =  StatesNum[key as keyof typeof StatesNum]
    }

    static fromKey(key: keyof typeof StatesText): DeliveryState {
        const d = new DeliveryState(
            key as keyof typeof StatesText,
        );
        d.text = StatesText[key as keyof typeof StatesText]
        d.num = StatesNum[key as keyof typeof StatesNum]
        return d
    }

    static fromAPI(key: string): DeliveryState {
        return new DeliveryState(
            key as keyof typeof StatesText
        )
    }

    static states(): DeliveryState[]{
        return Object.entries(StatesText).map(
            ([key, state], i) => (
                new DeliveryState(
                    key as keyof typeof StatesText
                )
            )
        )
    }
}