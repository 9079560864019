import Barcode from "./Barcode";
import BoxContent from "./BoxContent";
import BoxHistory from "./BoxHIstory";
import BoxImage from "./BoxImage";
import BoxState from "./BoxState";
import Customer from "./Customer";
import Position from "./Position";

export default class Box {

    public id?: number;
    public owner?: Customer;
    public position?: Position;
    public barcode?: Barcode;
    public size?: number;
    public images?: BoxImage[];
    public content: BoxContent[] = [];
    public history: BoxHistory[] = [];
    public destroyed?: string;
    public metaYear?: number;
    public metaDestroyYear?: number;
    public metaFirstDeliveryCustomer?: string;

    constructor(
        public code: string,
        public created: string,
        public state: BoxState,
    ) { };

    static fromAPI(model?: any): Box {
        const box = new Box(
            model.code,
            model.created,
            BoxState.fromAPI(model.state),
        )
        box.id = model.id;
        if (model.content){
            box.content =  model.content.map((c: any) => BoxContent.fromAPI(c))
        }
        if (model.images){
            box.images = model.images.map((i: any) => BoxImage.fromAPI(i))
        }
        if(model.owner){
            box.owner = Customer.fromAPI(model.owner)
        }
        if (model.position) {
            box.position = Position.fromAPI(model.position)
        }
        if (model.barcode) {
            box.barcode = Barcode.fromAPI(model.barcode)
        }
        if (model.size) {
            box.size = model.size
        }
        if (model.history) {
            box.history = model.history.map(
                (h: any) => BoxHistory.fromAPI(h)).sort(
                    (a:BoxHistory, b:BoxHistory) => {return new Date(a.created!).getTime() - new Date(b.created!).getTime()})
        }
        if (model.destroyed) {
            box.destroyed = model.destroyed
        }
        if (model.meta_year) {
            box.metaYear = model.meta_year
        }
        if (model.meta_destroy_year) {
            box.metaDestroyYear = model.meta_destroy_year
        }
        if (model.meta_first_delivery_customer) {
            box.metaFirstDeliveryCustomer = model.meta_first_delivery_customer
        }
        return box
    }

    public formatCode(): string {
        if (this.code) {
            return this.code.slice(0, 4) + "." + this.code.slice(4, 7) + "." + this.code.slice(7)
        }
        return ""
    }

    static toAPI(box: Box): Object {
        const data = {
            state: box.state.num,
            destroyed: box.destroyed
        }
        return data
    }

}