/**
* Created on Sat Apr 17 2021 20:23:15
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Box from "../../shared/models/Box";
import Customer from "../../shared/models/Customer";
import LabelPrint from "../../shared/models/LabelPrint";
import Pagination from "../../shared/models/Pagination";
import RequestParams from "../../shared/models/RequestParams";


export const LOAD_BOXES = '@@services/LOAD_BOXES';
export type LOAD_BOXES = typeof LOAD_BOXES;

export const LOAD_BOXES_SUCCESS = '@@services/LOAD_BOXES_SUCCESS';
export type LOAD_BOXES_SUCCESS = typeof LOAD_BOXES_SUCCESS;

export const ADD_LABEL = '@@services/ADD_LABEL';
export type ADD_LABEL = '@@services/ADD_LABEL';

export const REMOVE_LABEL = '@@services/REMOVE_LABEL';
export type REMOVE_LABEL = '@@services/REMOVE_LABEL';

export const ADD24_LABEL = '@@services/ADD24_LABEL';
export type ADD24_LABEL = '@@services/ADD24_LABEL';

export const RESET_LABEL = '@@services/RESET_LABEL';
export type RESET_LABEL = '@@services/RESET_LABEL';

export const RESET_LABEL_PRINT = '@@services/RESET_LABEL_PRINT';
export type RESET_LABEL_PRINT = typeof RESET_LABEL_PRINT;

export const DOWNLOAD_LABEL_PRINT = '@@services/DOWNLOAD_LABEL_PRINT';
export type DOWNLOAD_LABEL_PRINT = typeof DOWNLOAD_LABEL_PRINT;

export const DOWNLOAD_LABEL_PRINT_SUCCESS = '@@services/DOWNLOAD_LABEL_PRINT_SUCCESS';
export type DOWNLOAD_LABEL_PRINT_SUCCESS = typeof DOWNLOAD_LABEL_PRINT_SUCCESS;

export const LOAD_CUSTOMERS = '@@service/LOAD_CUSTOMERS';
export type LOAD_CUSTOMERS = typeof LOAD_CUSTOMERS;

export const LOAD_CUSTOMERS_SUCCESS = '@@service/LOAD_CUSTOMERS_SUCCESS';
export type LOAD_CUSTOMERS_SUCCESS = typeof LOAD_CUSTOMERS_SUCCESS;

export const DOWNLOAD_CONTENT_IMAGES = '@@services/DOWNLOAD_CONTENT_IMAGES';
export type DOWNLOAD_CONTENT_IMAGES = typeof DOWNLOAD_CONTENT_IMAGES;

export const DOWNLOAD_CONTENT_IMAGES_SUCCESS = '@@services/DOWNLOAD_CONTENT_IMAGES_SUCCESS';
export type DOWNLOAD_CONTENT_IMAGES_SUCCESS = typeof DOWNLOAD_CONTENT_IMAGES_SUCCESS;

export const DOWNLOAD_CONTENT_IMAGES_PROGRESS = '@@services/DOWNLOAD_CONTENT_IMAGES_PROGRESS';
export type DOWNLOAD_CONTENT_IMAGES_PROGRESS = typeof DOWNLOAD_CONTENT_IMAGES_PROGRESS;

export const DOWNLOAD_ARCHIVE_LIST = '@@services/DOWNLOAD_ARCHIVE_LIST';
export type DOWNLOAD_ARCHIVE_LIST = typeof DOWNLOAD_ARCHIVE_LIST;

export const DOWNLOAD_ARCHIVE_LIST_SUCCESS = '@@services/DOWNLOAD_ARCHIVE_LIST_SUCCESS';
export type DOWNLOAD_ARCHIVE_LIST_SUCCESS = typeof DOWNLOAD_ARCHIVE_LIST_SUCCESS;

export const DOWNLOAD_ARCHIVE_LIST_PROGRESS = '@@services/DOWNLOAD_ARCHIVE_LIST_PROGRESS';
export type DOWNLOAD_ARCHIVE_LIST_PROGRESS = typeof DOWNLOAD_ARCHIVE_LIST_PROGRESS;

export const DOWNLOAD_COLLECT_LIST = '@@services/DOWNLOAD_COLLECT_LIST';
export type DOWNLOAD_COLLECT_LIST = typeof DOWNLOAD_COLLECT_LIST;

export const DOWNLOAD_COLLECT_LIST_SUCCESS = '@@services/DOWNLOAD_COLLECT_LIST_SUCCESS';
export type DOWNLOAD_COLLECT_LIST_SUCCESS = typeof DOWNLOAD_COLLECT_LIST_SUCCESS;

export const DOWNLOAD_DESTROY_LIST = '@@services/DOWNLOAD_DESTROY_LIST';
export type DOWNLOAD_DESTROY_LIST = typeof DOWNLOAD_DESTROY_LIST;

export const DOWNLOAD_DESTROY_LIST_SUCCESS = '@@services/DOWNLOAD_DESTROY_LIST_SUCCESS';
export type DOWNLOAD_DESTROY_LIST_SUCCESS = typeof DOWNLOAD_DESTROY_LIST_SUCCESS;

export const DOWNLOAD_DESTROY_REQUEST = '@@services/DOWNLOAD_DESTROY_REQUEST';
export type DOWNLOAD_DESTROY_REQUEST = typeof DOWNLOAD_DESTROY_REQUEST;

export const DOWNLOAD_DESTROY_REQUEST_SUCCESS = '@@services/DOWNLOAD_DESTROY_REQUEST_SUCCESS';
export type DOWNLOAD_DESTROY_REQUEST_SUCCESS = typeof DOWNLOAD_DESTROY_REQUEST_SUCCESS;

export const DOWNLOAD_BOXES_OVERVIEW = '@@services/DOWNLOAD_BOXES_OVERVIEW';
export type DOWNLOAD_BOXES_OVERVIEW = typeof DOWNLOAD_BOXES_OVERVIEW;

export const DOWNLOAD_BOXES_OVERVIEW_SUCCESS = '@@services/DOWNLOAD_BOXES_OVERVIEW_SUCCESS';
export type DOWNLOAD_BOXES_OVERVIEW_SUCCESS = typeof DOWNLOAD_BOXES_OVERVIEW_SUCCESS;

export const RESET_META = '@@services/RESET_META';
export type RESET_META = typeof RESET_META;

export const RESET_META_SUCCESS = '@@services/RESET_META_SUCCESS';
export type RESET_META_SUCCESS = typeof RESET_META_SUCCESS;

export const UPDATE_META = '@@services/UPDATE_META';
export type UPDATE_META = typeof UPDATE_META;

export const UPDATE_META_SUCCESS = '@@services/UPDATE_META_SUCCESS';
export type UPDATE_META_SUCCESS = typeof UPDATE_META_SUCCESS;

export const LOAD_MARKED_FOR_DESTROYED_BOXES = '@@services/LOAD_MARKED_FOR_DESTROYED_BOXES';
export type LOAD_MARKED_FOR_DESTROYED_BOXES = typeof LOAD_MARKED_FOR_DESTROYED_BOXES;

export const LOAD_MARKED_FOR_DESTROYED_BOXES_SUCCESS = '@@services/LOAD_MARKED_FOR_DESTROYED_BOXES_SUCCESS';
export type LOAD_MARKED_FOR_DESTROYED_BOXES_SUCCESS = typeof LOAD_MARKED_FOR_DESTROYED_BOXES_SUCCESS;

export const DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES = '@@services/DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES';
export type DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES = typeof DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES;

export const DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES_SUCCESS = '@@services/DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES_SUCCESS';
export type DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES_SUCCESS = typeof DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES_SUCCESS;

export const loadBoxesAction = createAction(LOAD_BOXES)<RequestParams>();
export const loadBoxesSuccessAction = createAction(LOAD_BOXES_SUCCESS)<Pagination<Box>>();

export const downloadLabelPrintAction = createAction(DOWNLOAD_LABEL_PRINT)<LabelPrint[]>();
export const downloadLabelPrintSuccessAction  = createAction(DOWNLOAD_LABEL_PRINT_SUCCESS)<any>();

export const addLabelAction = createAction(ADD_LABEL)<Box>();
export const removeLabelAction = createAction(REMOVE_LABEL)<Box>();
export const add24LabelAction = createAction(ADD24_LABEL)<Box>();
export const resetLabelAction = createAction(RESET_LABEL)<Box>();
export const resetLabelPrintAction = createAction(RESET_LABEL_PRINT)();

export const loadCustomersAction = createAction(LOAD_CUSTOMERS)<string>();
export const loadCustomersSuccessAction = createAction(LOAD_CUSTOMERS_SUCCESS)<Customer[]>();

export const downloadContentImages = createAction(DOWNLOAD_CONTENT_IMAGES)<Customer, string>();
export const downloadContentSuccessImages = createAction(DOWNLOAD_CONTENT_IMAGES_SUCCESS)<any>();
export const downloadContentImagesProgressAction = createAction(DOWNLOAD_CONTENT_IMAGES_PROGRESS)<any>();

export const downloadArchiveListAction = createAction(DOWNLOAD_ARCHIVE_LIST)<Customer, string>();
export const downloadArchiveListSuccessAction = createAction(DOWNLOAD_ARCHIVE_LIST_SUCCESS)<any>();
export const downloadArchiveListProgressAction = createAction(DOWNLOAD_ARCHIVE_LIST_PROGRESS)<number>();

export const downloadCollectListAction = createAction(DOWNLOAD_COLLECT_LIST)();
export const downloadCollectListSuccessAction = createAction(DOWNLOAD_COLLECT_LIST_SUCCESS)<any>();

export const downloadDestroyListAction = createAction(DOWNLOAD_DESTROY_LIST)<Customer,string>();
export const downloadDestroyListSuccessAction = createAction(DOWNLOAD_DESTROY_LIST_SUCCESS)<any>();

export const downloadDestroyRequestAction = createAction(DOWNLOAD_DESTROY_REQUEST)<Customer>();
export const downloadDestroyRequestSuccessAction = createAction(DOWNLOAD_DESTROY_REQUEST_SUCCESS)<any>();

export const downloadBoxesOverviewAction = createAction(DOWNLOAD_BOXES_OVERVIEW)<Customer,string>();
export const downloadBoxesOverviewSuccessAction = createAction(DOWNLOAD_BOXES_OVERVIEW_SUCCESS)<any>();

export const resetMetaAction = createAction(RESET_META)<any>();
export const resetMetaSuccessAction = createAction(RESET_META_SUCCESS)();

export const updateMetaAction = createAction(UPDATE_META)<any>();
export const updateMetaSuccessAction = createAction(UPDATE_META_SUCCESS)();

export const loadMarkedForDestroyedBoxesAction = createAction(LOAD_MARKED_FOR_DESTROYED_BOXES)();
export const loadMarkedForDestroyedBoxesSuccessAction = createAction(LOAD_MARKED_FOR_DESTROYED_BOXES_SUCCESS)<Box[]>();

export const destroyAllMarkedForDestroyedBoxesAction = createAction(DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES)();
export const destroyAllMarkedForDestroyedBoxesSuccessAction = createAction(DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES_SUCCESS)<Box[]>();

export type ServicesAction = ActionType<
    | typeof loadBoxesAction
    | typeof loadBoxesSuccessAction
    | typeof addLabelAction
    | typeof removeLabelAction
    | typeof add24LabelAction
    | typeof resetLabelAction
    | typeof resetLabelPrintAction
    | typeof loadCustomersAction
    | typeof loadCustomersSuccessAction
    | typeof downloadContentImages
    | typeof downloadContentSuccessImages
    | typeof downloadContentImagesProgressAction
    | typeof downloadArchiveListAction
    | typeof downloadArchiveListSuccessAction
    | typeof downloadArchiveListProgressAction
    | typeof downloadCollectListAction
    | typeof downloadCollectListSuccessAction
    | typeof downloadDestroyListAction
    | typeof downloadDestroyListSuccessAction
    | typeof downloadDestroyRequestAction
    | typeof downloadDestroyRequestSuccessAction
    | typeof resetMetaAction
    | typeof resetMetaSuccessAction
    | typeof updateMetaAction
    | typeof updateMetaSuccessAction
    | typeof loadMarkedForDestroyedBoxesAction
    | typeof loadMarkedForDestroyedBoxesSuccessAction
    | typeof destroyAllMarkedForDestroyedBoxesAction
    | typeof destroyAllMarkedForDestroyedBoxesSuccessAction
    | typeof downloadBoxesOverviewAction
    | typeof downloadBoxesOverviewSuccessAction
>