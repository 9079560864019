/**
* Created on Sat Feb 20 2021 22:47:52
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

export enum GenderText {
    M = 'Herr',
    F = 'Frau',
    D = 'divers'
}

export default class Gender {

    constructor(
        public readonly key: keyof typeof GenderText,
        public readonly text: string,
    ) { }

    static fromAPI(key: string): Gender {
        return new Gender(
            key as keyof typeof GenderText,
            GenderText[key as keyof typeof GenderText]
        )
    }


    /**
     * return all possible genders
     */
    static genders(): Gender[] {
        return Object.entries(GenderText).map(
            ([key, text], i) => (
                new Gender(
                    key as keyof typeof GenderText,
                    GenderText[key as keyof typeof GenderText]
                )
            )
        )

    }

    static fromKey(key: keyof typeof GenderText): Gender {
        return new Gender(
            key as keyof typeof GenderText,
            GenderText[key as keyof typeof GenderText],
        )
    }

}