/**
*  Created on Di. 24.08.2021 10:22:00
*
*  @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/
import {AppState} from "../../reducers/rootReducer";
import Box from "../../shared/models/Box";

export function getMarkedForDestroyedBoxes(state: AppState): Box[] {
    return state.services.destruction.data;
}

export function loadingMarkedForDestroyedBoxes(state: AppState): boolean {
    return state.services.destruction.load.loading;
}