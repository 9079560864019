/**
* Created on Sun Apr 18 2021 22:29:02
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import axios from "axios";
import { combineEpics, Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";
import api from "../../api";
import { notifySuccessAction } from "../../shared/actions/notify.actions";
import Box from "../../shared/models/Box";
import BoxContent from "../../shared/models/BoxContent";
import BoxImage from "../../shared/models/BoxImage";
import Notification from "../../shared/models/Notification";
import { token } from "../../shared/utils";
import { handleRequestError } from "../../shared/utils/errors";
import {
    addContentSuccessAction,
    ADD_CONTENT,
    ADD_CONTENT_SUCCESS,
    deleteBoxImageSuccessAction,
    deleteContentSuccessAction,
    DELETE_BOX_IMAGE,
    DELETE_CONTENT,
    DELETE_CONTENT_SUCCESS,
    loadBoxSuccessAction,
    LOAD_BOX,
    updateContentSuccessAction,
    UPDATE_CONTENT,
    UPDATE_CONTENT_SUCCESS,
    viewedBoxImageSuccessAction,
    VIEWED_BOX_IMAGE,
    UPDATE_BOX,
    updateBoxSuccessAction,
    UPDATE_BOX_SUCCESS, DELETE_BOX, deleteBoxSuccessAction, DELETE_BOX_SUCCESS
} from "../actions/box.actions";

const loadBox: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_BOX),
        switchMap(({ payload, meta }) => {
            const url = `${api.BASE_URL_V1}/boxes/${payload}/${meta.params()}`
            return from(axios.get<Box>(url, token(state$))).pipe(
                map(({ data }) => loadBoxSuccessAction(Box.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const updateBox: Epic = (action$, state$) =>
    action$.pipe(
        ofType(UPDATE_BOX),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/boxes/${payload.id}/`
            return from(axios.patch<Box>(url, Box.toAPI(payload), token(state$))).pipe(
                map(({ data }) => updateBoxSuccessAction(Box.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const deleteBox: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DELETE_BOX),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/boxes/${payload.id}/`
            return from(axios.delete(url, token(state$))).pipe(
                map(() => deleteBoxSuccessAction()),
                catchError(handleRequestError)
            )
        })
    )

const viewedBoxImage: Epic = (action$, state$) =>
    action$.pipe(
        ofType(VIEWED_BOX_IMAGE),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/boximages/${payload.id}/`
            return from(axios.patch<BoxImage>(
                url,
                {
                    viewed: !payload.viewed
                },
                token(state$)
            )).pipe(
                map(({ data }) => viewedBoxImageSuccessAction(BoxImage.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const deleteBoxImage: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DELETE_BOX_IMAGE),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/boximages/${payload.id}/`
            return from(axios.delete(
                url,
                token(state$)
            )).pipe(
                map(() => deleteBoxImageSuccessAction(payload.id)),
                catchError(handleRequestError)
            )
        })
    )

const addBoxContent: Epic = (action$, state$) =>
    action$.pipe(
        ofType(ADD_CONTENT),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/boxcontents/`
            return from(axios.post(url, BoxContent.toAPI(payload), token(state$))).pipe(
                map(({ data }) => addContentSuccessAction(BoxContent.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const deleteBoxContent: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DELETE_CONTENT),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/boxcontents/${payload.id}/`
            return from(axios.delete(url, token(state$))).pipe(
                map(() => deleteContentSuccessAction(payload.id)),
                catchError(handleRequestError)
            )
        })
    )


const updateBoxContent: Epic = (action$, state$) =>
    action$.pipe(
        ofType(UPDATE_CONTENT),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/boxcontents/${payload.id}/`
            return from(axios.patch(url, BoxContent.toAPI(payload), token(state$))).pipe(
                map(({ data }) => updateContentSuccessAction(BoxContent.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const successUpdateBox: Epic = action$ =>
    action$.pipe(
        ofType(UPDATE_BOX_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Box aktualisiert.`)))
    )

const successDeleteBox: Epic = action$ =>
    action$.pipe(
        ofType(DELETE_BOX_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Box gelöscht`)))
    )

const successAddContent: Epic = action$ =>
    action$.pipe(
        ofType(ADD_CONTENT_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Inhalt hinzugefügt.`)))
    )

const successUpdateContent: Epic = action$ =>
    action$.pipe(
        ofType(UPDATE_CONTENT_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Inhalt aktualisiert.`)))
    )


const successDeleteContent: Epic = action$ =>
    action$.pipe(
        ofType(DELETE_CONTENT_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Inhalt gelöscht.`)))
    )

export default combineEpics(
    loadBox,
    viewedBoxImage,
    deleteBoxImage,
    addBoxContent,
    updateBoxContent,
    successUpdateContent,
    deleteBoxContent,
    successDeleteContent,
    successAddContent,
    updateBox,
    successUpdateBox,
    deleteBox,
    successDeleteBox
)