/**
* Created on Mon Feb 01 2021 11:08:04
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import Row from "./Row";


export default class Area {

    public id?: number;
    public rowsCount?: number;
    public boxesCount?: number;
    public rows?: Row[];
    public created?: string
    constructor(
        public name: string
    ){};

    static fromAPI(model?: any): Area {
        const area = new Area(
            model.name,
            )
        area.created = model.created
        area.id = model.id;
        area.rowsCount = model.rows_count;
        area.boxesCount = model.boxes_count;
        if (model.rows) {
            area.rows = model.rows.map((r: any) => Row.fromAPI(r)).sort((a:Row, b:Row) => {return a.number - b.number})
        }
        return area
    }

    static toApi(a: Area): Object {
        let data:any = {
            name: a.name
        }
        return data
    }

}