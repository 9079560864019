/**
* Created on Wed Feb 17 2021 12:17:01
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Box from "../../shared/models/Box";

export function getBox(state: AppState): Box | undefined {
    return state.stock.box.data!
}

export function loadedBox(state: AppState): boolean {
    return state.stock.box.load.loaded
}