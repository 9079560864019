/**
* Created on Sun Apr 18 2021 12:52:56
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Customer from "../../shared/models/Customer";

export function getCustomers(state: AppState): Customer[] {
    return state.services.archiveList.customers
}

export function getDownloadImages(state: AppState): boolean {
    return state.services.archiveList.downloadImages
}

export function getDownloadImagesProgress(state: AppState): number {
    return state.services.archiveList.downloadImagesProgress
}

export function getDownloadArchiveList(state: AppState): boolean {
    return state.services.archiveList.downloadArchiveList
}

export function getDownloadArchiveListProgress(state: AppState): number {
    return state.services.archiveList.downloadArchiveListProgress
}