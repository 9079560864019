/**
* Created on Wed Mar 10 2021 11:25:57
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import {ActionType} from "typesafe-actions";
import Box from "../../shared/models/Box";
import BoxContent from "../../shared/models/BoxContent";
import Customer from '../../shared/models/Customer';
import DeliveryOption from '../../shared/models/DeliveryOption';
import {
    ADD_BOX_CONTENT_TO_DELIVERY,
    ADD_BOX_TO_DELIVERY,
    ADD_DELIVERY_SUCCESS,
    addBoxContentToDeliveryAction,
    addBoxToDeliveryAction,
    addDeliverySuccessAction,
    REMOVE_BOX_CONTENT_FROM_DELIVERY,
    REMOVE_BOX_FROM_DELIVERY,
    removeBoxContentFromDeliveryAction,
    removeBoxFromDeliveryAction,
    SET_CUSTOMER,
    SET_DELIVERYOPTION,
    setCustomerAction,
    setDeliveryOptionAction
} from "../actions/delieveries.actions";

export interface State{
    boxes: Box[],
    boxcontents: BoxContent[],
    customer?: Customer,
    deliveryoption?: DeliveryOption,
}


export default function(
    state: State = {
        boxes: [],
        boxcontents: [],
        customer: undefined,
        deliveryoption: undefined
    },
    action: ActionType<
        | typeof addBoxToDeliveryAction
        | typeof addBoxContentToDeliveryAction
        | typeof removeBoxFromDeliveryAction
        | typeof removeBoxContentFromDeliveryAction
        | typeof setCustomerAction
        | typeof setDeliveryOptionAction
        | typeof addDeliverySuccessAction
    >
): State {
    switch (action.type) {
        case ADD_BOX_TO_DELIVERY:
            return update(state, {
                boxes: { $push: [action.payload] }
            })    
            
        case ADD_BOX_CONTENT_TO_DELIVERY:
            return update(state, {
                boxcontents: { $push: [action.payload] }
            })
            
        case REMOVE_BOX_FROM_DELIVERY:
            var index = state.boxes.findIndex(b => b.id === action.payload.id)
            return update(state, {
                boxes: { $splice: [[index!, 1]] }
            })
        
        case REMOVE_BOX_CONTENT_FROM_DELIVERY:
            var index = state.boxcontents.findIndex(c => c === action.payload)
            return update(state, {
                boxcontents: { $splice: [[index!, 1]] }
            })
        
        case SET_DELIVERYOPTION:
            return update(state, {
                deliveryoption: { $set: action.payload}
            })
            
        case SET_CUSTOMER:
            return update(state, {
                customer: { $set: action.payload },
                deliveryoption: { $set: undefined},
                boxes: { $set: [] } ,
                boxcontents: { $set: [] }
            })
        case ADD_DELIVERY_SUCCESS:
            return update(state, {
                customer: { $set: undefined  },
                deliveryoption: { $set: undefined},
                boxes: { $set: [] } ,
                boxcontents: { $set: [] }
            })
        default:
            return state
    }
}