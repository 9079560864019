/**
* Created on Sat Jan 23 2021 16:24:39
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { NavLink } from 'react-router-dom';
import Staff from '../Staff';
import UserMenu from './UserMenu';

interface Link {
    to: string,
    name: string,
    staff?: boolean
}

interface Props {
    title: string,
    links: Link[]
}

export default function TopBar({
    title,
    links,
}: Props) {

    return (
        <div className="sticky top-0 z-40 flex items-center w-full py-4 bg-adl-1">
            <div className="mx-6 text-lg font-bold text-white whitespace-nowrap">
                {title}
            </div>
            <div className="flex flex-wrap w-full ml-8">
                {links.map((l, i) =>
                    l.staff ?
                        <Staff key={`topbar-item-${i}`}>
                            <NavLink className="mr-4 text-sm text-white whitespace-nowrap" activeClassName="border-b-2" to={l.to} >
                                {l.name}
                            </NavLink>
                        </Staff>
                        :
                        <div key={`topbar-item-${i}`}>
                            <NavLink className="mr-4 text-sm text-white whitespace-nowrap" activeClassName="border-b-2" to={l.to} >
                                {l.name}
                            </NavLink>
                        </div>

                )}
            </div>
            <div className="flex justify-end">
                <div className="mr-1">
                    <UserMenu />
                </div>
            </div>
        </div>
    )
}