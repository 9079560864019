/**
* Created on Wed Mar 17 2021 13:25:40
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import moment from 'moment';

const calcDateNextDelivery = (days: number[], from: string, to: string, p: number, w: number) => {

    var searchDate = moment().local()
  
    var toHour = parseInt(to.split(":")[0])
    var toMinute = parseInt(to.split(":")[1])
  
    var fromHour = from.split(":")[0]
    var fromMinute = parseInt(from.split(":")[1])
  
    var lastTime = moment({
      h: toHour,
      m: toMinute
    }).subtract(p, 'hours')
  
    var currentTime = moment({
      h: moment().local().hour(),
      m: moment().local().minute()
    })
  
    if (!currentTime.isBefore(lastTime)) {
      searchDate.add(1, 'day')
      toHour = parseInt(fromHour) + w
      toMinute = fromMinute
    } else {
      toHour = currentTime.hour() + w
      toMinute = currentTime.minute()
    }
  
    if (days.length !== 0) {
  
      var searchWeekday = searchDate.isoWeekday()
      var weekDayToFind = days.filter(d => d >= searchWeekday)
      var weekDay = -1

      if (weekDayToFind.length === 0) {
        weekDay = days[0]
      } else {
        weekDay = weekDayToFind[0]
      }
  
      while (searchDate.isoWeekday() !== weekDay) {
  
        searchDate.add(1, 'day')
        toHour = parseInt(fromHour) + w
        toMinute = fromMinute
      }
    }
  
    var deliveryTimeUntil = searchDate.hour(toHour).minute(toMinute)
    var time = moment(`${searchDate.format("YYYY-MM-DD")}T${deliveryTimeUntil.format("HH:mm:ss")}`).utc()
  
    return `${time.toISOString()}`
  
  }

export default class DeliveryOption {
    
    public id?: number;
    constructor(
        public description: string,
        public customer: number,
        public days: number[],
        public from: string,
        public to: string,
        public prior: number,
        public window: number,
        public price: number,
    ) {}

    static fromAPI(model?: any): DeliveryOption {
        const option = new DeliveryOption(
            model.description,
            model.customer,
            model.days.split(",")!.map((d:string) => parseInt(d))!,
            model.fr,
            model.to,
            model.prior,
            model.window,
            model.price,
        )
        option.id = model.id
        return option
    }

    static nextDeliveryDate(d: DeliveryOption): string {
        return calcDateNextDelivery(
            d.days!,
            d.from!,
            d.to!,
            d.prior!,
            d.window!
        )
    }

    static toAPI(o: DeliveryOption): Object {
      const data = {
        customer: o.customer,
        days: o.days.join(","),
        description: o.description,
        fr: o.from,
        to: o.to,
        prior: o.prior,
        price: o.price,
        window: o.window
      }
      return data;
    }

}