import { combineEpics, ofType, Epic } from 'redux-observable';
import { map } from 'rxjs/operators';
import { FORBIDDEN } from '../actions/error.actions';
import { push } from 'connected-react-router';


const redirectToLogin: Epic = (action$, state$) =>
    action$.pipe(
        ofType(FORBIDDEN),
        map(() => { return push("/login")})
    )

export default combineEpics(
    redirectToLogin
)