/**
* Created on Sat Feb 20 2021 15:42:38
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Pagination from "../../shared/models/Pagination";
import RequestParams from "../../shared/models/RequestParams";
import User from "../../shared/models/User";

export const ERROR_USERS = '@@users/ERROR_USERS';
export type ERROR_USERS = typeof ERROR_USERS;

export const LOAD_USERS = '@@users/LOAD_USERS';
export type LOAD_USERS = typeof LOAD_USERS;

export const LOAD_USERS_SUCCESS = '@@users/LOAD_USERS_SUCCESS';
export type LOAD_USERS_SUCCESS = typeof LOAD_USERS_SUCCESS;

export const ADD_USER = '@@users/ADD_USER';
export type ADD_USER = typeof ADD_USER;

export const ADD_USER_SUCCESS = '@@users/ADD_USER_SUCCESS';
export type ADD_USER_SUCCESS = typeof ADD_USER_SUCCESS;

export const CHANGE_USER_PASSWORD = '@@users/CHANGE_USER_PASSWORD';
export type CHANGE_USER_PASSWORD = typeof CHANGE_USER_PASSWORD;

export const CHANGE_USER_PASSWORD_SUCCESS = '@@users/CHANGE_USER_PASSWORD_SUCCESS';
export type CHANGE_USER_PASSWORD_SUCCESS = typeof CHANGE_USER_PASSWORD_SUCCESS;

export const errorUsersAction = createAction(ERROR_USERS)<string>();

export const loadUsersAction = createAction(LOAD_USERS)<RequestParams>();
export const loadUsersSuccessAction = createAction(LOAD_USERS_SUCCESS)<Pagination<User>>();

export const addUserAction = createAction(ADD_USER)<User>();
export const addUserSuccessAction = createAction(ADD_USER_SUCCESS)<User>();

export const changeUserPasswordAction = createAction(CHANGE_USER_PASSWORD)<Object>();
export const changeUserPasswordSuccessAction = createAction(CHANGE_USER_PASSWORD_SUCCESS)<void>();

export type UsersAction = ActionType<
    | typeof loadUsersAction
    | typeof loadUsersSuccessAction
    | typeof errorUsersAction
    | typeof addUserAction
    | typeof addUserSuccessAction
    | typeof changeUserPasswordAction
    | typeof changeUserPasswordSuccessAction
>;