/**
 * Created on Sun Apr 18 2021 12:02:08
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import React, { useEffect, useState } from 'react';
import { FaFileArchive, FaFilePdf } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { Card, SubTitle } from '../../../shared/components/card';
import Input from '../../../shared/components/inputs/Input';
import Select from '../../../shared/components/inputs/Select';
import { PageContent } from '../../../shared/components/page';
import Customer from '../../../shared/models/Customer';
import { useDebounce } from '../../../shared/utils/debounce';
import {
    downloadArchiveListAction,
    downloadContentImages,
    loadCustomersAction
} from '../../actions/services.actions';
import {
    getCustomers,
    getDownloadArchiveList, getDownloadArchiveListProgress,
    getDownloadImages,
    getDownloadImagesProgress
} from '../../selector/archivelist.selector';

export default function () {

    const [customer, setCustomer] = useState<Customer>()
    const [from, setFrom] = useState("")
    const debouncedFrom = useDebounce(from, 750)
    const [to, setTo] = useState("")
    const debouncedTo = useDebounce(to, 750)
    const dispatch = useDispatch();

    useEffect(() => {
        const params = new URLSearchParams()
        if (from) {
            params.set('boxes_from', from)
        }
        if (to) {
            params.set('boxes_to', to)
        }
        const meta = params.toString().length > 0 ? `&${params.toString()}` : ""
        dispatch(loadCustomersAction(meta))
    }, [dispatch, debouncedFrom, debouncedTo, customer])

    const customers = useSelector(getCustomers)
    const downloadImages = useSelector(getDownloadImages)
    const downloadImagesProgress = useSelector(getDownloadImagesProgress)
    const downloadArchiveList = useSelector(getDownloadArchiveList)
    const downloadArchiveLisProgress = useSelector(getDownloadArchiveListProgress)


    const disableDownloadImages = () => {
        if (customer ? customer.countBoximages! <= 0 : true)
            return true
        return downloadImages;

    }

    const downloadImagesText = () => {
        if (downloadImages && downloadImagesProgress === -1) {
            return (
                <div className="animate-pulse">
                    Zip Datei wird erstellt ...
                </div>
            )
        }
        if (downloadImages && downloadImagesProgress >= 0) {
            return `Download (${downloadImagesProgress} %)`
        }
        return "Inhaltsbilder (zip)"
    }

    const disableDownloadArchiveList = () => {
        if (customer ? customer.countBoxes! <= 0 : true)
            return true
        return downloadArchiveList;

    }

    const downloadArchiveListText = () => {
        if (downloadArchiveList && downloadArchiveLisProgress === -1) {
            return (
                <div className="animate-pulse">
                    PDF wird erstellt ...
                </div>
            )
        }
        if (downloadArchiveList && downloadArchiveLisProgress >= 0) {
            return `Download (${downloadArchiveLisProgress} %)`
        }
        return "PDF"
    }

    const handleOnClickDownloadPDF = () => {
        const params = new URLSearchParams()
        if (from) {
            params.set('from', from)
        }
        if (to) {
            params.set('to', to)
        }
        const meta = params.toString().length > 0 ? `?${params.toString()}` : ""
        dispatch(downloadArchiveListAction(customer!, meta))
    }

    const handleOnClickDownloadImages = () => {
        const params = new URLSearchParams()
        if (from) {
            params.set('from', from)
        }
        if (to) {
            params.set('to', to)
        }
        const meta = params.toString().length > 0 ? `?${params.toString()}` : ""
        dispatch(downloadContentImages(customer!, meta))
    }

    const currentCustomer = customers.find((c) => c.id === customer?.id)
    
    return (
        <PageContent>
            <Card title={<div className="flex items-center justify-between">
                <div>
                    Archivliste / Inhaltsbilder
                </div>
                <div className="flex gap-x-2">
                    <Button
                        icon={<FaFilePdf className="text-red-600" />}
                        type="button"
                        disabled={disableDownloadArchiveList()}
                        onClick={() => handleOnClickDownloadPDF()}
                    >
                        {downloadArchiveListText()}
                    </Button>
                    <Button
                        icon={<FaFileArchive className="text-yellow-400" />}
                        type="button"
                        disabled={disableDownloadImages()}
                        onClick={() => handleOnClickDownloadImages()}
                    >
                        {downloadImagesText()}
                    </Button>
                </div>
            </div>}>
                <div>
                    <div className="p-2">
                        <Select
                            title="Kunde"
                            data={customers}
                            renderOption={(c: Customer) => `( ${c.code} ) ${c.name}`}
                            onChangeObjectClass={c => setCustomer(c)}
                        />
                        <div className="grid grid-cols-2 pt-2 gap-x-2">
                            <Input
                                title="von"
                                type="number"
                                step={1}
                                value={from}
                                onChange={(e) => setFrom(e.target.value)}
                            />
                            <Input
                                title="bis"
                                type="number"
                                step={1}
                                value={to}
                                onChange={(e) => setTo(e.target.value)}
                            />
                        </div>
                    </div>
                    {currentCustomer &&
                        <div>
                            <SubTitle title="Information" />
                            <div className="grid grid-cols-3 p-2 gap-x-2">
                                <div className="font-semibold text-gray-700">Anzahl Boxen:</div>
                                <div className="col-span-2">
                                    {currentCustomer!.countBoxes!}
                                </div>
                                <div className="font-semibold text-gray-700">Laufende Meter (eingelagert):</div>
                                <div className="col-span-2">
                                    {currentCustomer!.countMeters!} m
                                </div>
                                <div className="font-semibold text-gray-700">Anzahl Inhaltsbilder:</div>
                                <div className="col-span-2">
                                    {currentCustomer!.countBoximages!}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Card>
        </PageContent>
    )
}