/**
* Created on Fri Feb 26 2021 09:36:07
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Customer from "../../shared/models/Customer";
import Pagination from "../../shared/models/Pagination";


export function getCustomers(state: AppState): Pagination<Customer> {
    return state.customers.data
}
