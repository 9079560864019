/**
* Created on Sat Jan 23 2021 19:40:23
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Area from "../../shared/models/Area";

export function getAreas(state: AppState): Area[] {
    return state.stock.areas.data;
}

export function loadedAreas(state: AppState): boolean {
    return state.stock.areas.load.loaded;
}

export function loadingAreas(state: AppState): boolean {
    return state.stock.areas.load.loading;
}