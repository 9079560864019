/**
* Created on Fri Mar 05 2021 11:15:31
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useState } from 'react';
import Box from '../../../shared/models/Box';
import { formatDate } from '../../../shared/utils';
import { Collapse } from 'react-collapse';
import BoxImage from '../../../shared/models/BoxImage';
import Image from '../../../shared/components/Image';
import { FaCheck, FaEye, FaEyeSlash, FaTrashAlt } from 'react-icons/fa';
import Button from '../../../shared/components/buttons/Button';
import { useDispatch } from 'react-redux';
import { deleteBoxImageAction, viewedBoxImageAction } from '../../actions/box.actions';
import YesNo from '../../../shared/components/overlays/YesNo';
import Staff from '../../../shared/components/Staff';


interface ICProps {
    image: BoxImage
}

function ImageComponent({
    image
}: ICProps) {

    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [showYesNoDelete, setShowYesNoDelete] = useState(false)

    return (
        <div className="mb-2">
            <YesNo
                title="Löschen"
                text={`Inhaltsbild vom ${formatDate(image.created.toString())} wirklich löschen?`}
                type="danger"
                open={showYesNoDelete}
                onClose={() => setShowYesNoDelete(false)}
                onYes={() => dispatch(deleteBoxImageAction(image))}
                onNo={() => setShowYesNoDelete(false)}
            />
            <div className={`p-1 px-2 text-white cursor-pointer bg-adl-1 ${image.viewed && "opacity-75"}`} onClick={() => setOpen(!open)}>
                <div className="flex items-center justify-between">
                    <div>
                        {formatDate(image.created.toString())}
                    </div>
                    {image.viewed && <FaCheck />}
                </div>
            </div>
            <Collapse isOpened={open}>
                <div className="border-2 border-adl-1">
                    <div className="relative">
                        <Staff>
                            <div className="absolute top-0 right-0">
                                <div className="flex gap-2 p-2">
                                    {image.viewed ?
                                        <Button icon={<FaEyeSlash />} onClick={() => dispatch(viewedBoxImageAction(image))}>
                                            Zu Bearbeiten
                                    </Button>
                                        :
                                        <Button icon={<FaEye />} onClick={() => dispatch(viewedBoxImageAction(image))}>
                                            Bearbeitet
                                    </Button>
                                    }
                                    <Button color="danger" icon={<FaTrashAlt />} onClick={() => setShowYesNoDelete(true)}>
                                        Löschen
                                </Button>
                                </div>
                            </div>
                        </Staff>
                        <Image url={image.image} />
                    </div>
                </div>
            </Collapse>
        </div>
    )
}


interface Props {
    box: Box
}

export default function BoxImages({
    box
}: Props) {

    return (
        <div>
            {box.images!.map(i =>
                <ImageComponent image={i} key={`content-image-${box.code}-${i.created}`} />
            )}
        </div>
    )
}