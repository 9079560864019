/**
* Created on Tue Mar 09 2021 08:18:56
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { FaTimes } from 'react-icons/fa';
import Button from '../buttons/Button';

interface Props {
    title: string | React.ReactNode,
    text: string | React.ReactNode,
    open: boolean,
    type?: "danger" | "warning",
    onClose: () => void,
    onYes: () => void,
    onNo: () => void,
}

export default function YesNo({
    title,
    text,
    open = false,
    type,
    onClose,
    onYes,
    onNo,
}: Props) {

    return (
        <div className={`${!open && "hidden"}`}>
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full mx-auto backdrop-filter">
                <div className="max-h-screen overflow-y-scroll no-scrollbar">
                    <div className="px-4 py-2 font-semibold text-white bg-adl-1">
                        <div className="flex items-center justify-between">
                            <div>
                                {title}
                            </div>
                            <div className="cursor-pointer" onClick={onClose}>
                                <FaTimes />
                            </div>
                        </div>
                    </div>
                    <div className="p-4 bg-white border-b-4 border-l-4 border-r-4 border-adl-1">
                        <div>
                            {text}
                        </div>
                        <div className="flex gap-4 mt-4">
                            <div className="w-1/2">
                                <Button color={type} onClick={onYes}>
                                    Ja
                                </Button>
                            </div>
                            <div className="w-1/2">
                                <Button onClick={onNo}>
                                    Nein
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}