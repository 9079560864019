import { ActionType, createAction } from "typesafe-actions";


export const FORBIDDEN = '@@error/FORBIDDEN';
export type FORBIDDEN = typeof FORBIDDEN;

export const forbiddenAction = createAction(FORBIDDEN)<void>();

export type ErrorActions = ActionType<
    | typeof forbiddenAction
>