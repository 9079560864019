/**
*  Created on Mo. 7.06.2021 12:45:20
*
*  @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, {useEffect, useState} from 'react';
import {FaSearch} from "react-icons/fa";
import {Formik} from 'formik';
import validationSchema from "../../../../stock/components/boxes/filter/validationSchema";
import DeliveryFilter from "../../../../shared/models/DeliveryFilter";
import Input from "../../../../shared/components/inputs/Input";
import {history} from "../../../../store/history";
import {useLocation} from "react-router-dom";

export default function ExtendedFilter() {

    const location = useLocation();
    const [filter, setFilter] = useState(new DeliveryFilter().fromParams(location.search))
    const [timer, setTimer] = useState<NodeJS.Timeout>()

    useEffect(() => {
        setFilter(new DeliveryFilter().fromParams(location.search))
    }, [location])

    const setFilterParams = (e: React.FormEvent<HTMLInputElement>) => {
        const newFilter = DeliveryFilter.fromEvent(filter, e)
        validationSchema.validate(newFilter).then(
            _ => {
                if (timer) {
                    clearTimeout(timer)
                    setTimer(setTimeout(() => {
                        history.push(location.pathname + filter.toParams())
                        setTimer(undefined)
                    }, 1000))
                } else {
                    setTimer(setTimeout(() => {
                        history.push(location.pathname + filter.toParams())
                        setTimer(undefined)
                    }, 1000))
                }
            }
        ).catch(
            _ => console.log("invalid filter")
        )
        setFilter(newFilter)
    }

    return (
        <div className="flex items-center py-2 border-b-4 border-adl-1">
            <div className="p-4">
                <FaSearch/>
            </div>
            <Formik
                validationSchema={validationSchema}
                initialValues={new DeliveryFilter().fromParams(location.search)}
                onSubmit={(values) => {
                    console.log(values);
                }}
            >
                {({
                      values,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      touched,
                      errors
                  }) => (
                    <form noValidate className="flex flex-wrap items-center w-full" onSubmit={handleSubmit}>
                        <div className="pr-2 w-1/3">
                            <Input
                                title="Box"
                                name="box"
                                controlId={"delivery-filter-box"}
                                value={values.box}
                                onBlur={handleBlur}
                                onChange={e => {
                                    handleChange(e);
                                    setFilterParams(e);
                                }}
                                isInvalid={touched.box && !!errors.box}
                                isValid={touched.box && !errors.box}
                                error={errors.box}
                            />
                        </div>
                        <div className="pr-2 w-2/3">
                            <Input
                                title="Inhalt"
                                name="content"
                                controlId={"delivery-filter-content"}
                                value={values.content}
                                onBlur={handleBlur}
                                onChange={e => {
                                    handleChange(e);
                                    setFilterParams(e);
                                }}
                                isInvalid={touched.content && !!errors.content}
                                isValid={touched.content && !errors.content}
                                error={errors.content}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}