/**
* Created on Sun Jan 24 2021 15:24:22
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { authAction, noTokenAction } from '../actions/auth.actions';
import { isAuth, isLoaded, getToken } from '../selectors/auth.selector';

function Auth({
    children,
    location
}: PropsWithChildren<RouteComponentProps>) {


    const dispatch = useDispatch()
    const [fromLocation, setFromLocation] = useState("/")
    const token = useSelector(getToken)
    useEffect(() => {
        setFromLocation(location.pathname + location.search)
        if (token?.userId) {
            dispatch(authAction(token.userId))
        } else {
            dispatch(noTokenAction())
        }
    }, [dispatch, token])
    const auth = useSelector(isAuth)
    const loaded = useSelector(isLoaded)

    if (loaded) {
        return (
            <div>
                {!auth && <Redirect to={{
                    pathname: "/login",
                    state: fromLocation
                }}/>}
                {auth && children}
                {!auth && location.pathname === "/login" && children }
            </div>
        )
    } else {
        return (
            <div>
                loading
            </div>
        )
    }
}

export default withRouter(Auth);