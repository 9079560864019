/**
* Created on Sat Apr 17 2021 20:43:50
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { combineReducers } from 'redux'
import labelPrintReducer from './printlabels.reducer'
import archiveListReducer from './archivelist.reducer'
import collectListReducer from './collectlist.reducer'
import destroyListReducer from './destroylist.reducer'
import destroyRequestReducer from './destroyrequest.reducer'
import metaUpdateReducer from './metaupdate.reducer'
import destructionReducer from './destruction.reducer'
import boxesOverviewReducer from './boxesoverview.reducer'

export default combineReducers({
    labelPrint: labelPrintReducer,
    archiveList:  archiveListReducer,
    collectList: collectListReducer,
    destroyList: destroyListReducer,
    destroyRequest: destroyRequestReducer,
    metaUpdate: metaUpdateReducer,
    destruction: destructionReducer,
    boxesOverview: boxesOverviewReducer,
})