/**
* Created on Fri Mar 12 2021 12:14:07
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import { PageContent } from '../../../shared/components/page';
import Steps from '../../../shared/components/steps';
import { history } from '../../../store/history';
import { getBoxContents, getBoxes, getCustomer, getDeliveryoption } from '../../selectors/create.selector';
import CheckAndSubmitForm from './CheckAndSubmitForm';

import CreateForm from './CreateForm';
import CustomerSelect from './CustomerSelect';
//import OptionCheckboxes from './OptionCheckboxes';
import OptionSelect from './OptionSelect';

export default function () {

    const [step, setStep] = useState(0)
    const location = useLocation()

    useEffect(() => {        
        const search = new URLSearchParams(location.search)
        const step = search.get('step')
        if(step) {
            setStep(parseInt(step))
        } else {
            setStep(0)
        }
    }, [location])

    const nextStep = () => {
        const search = new URLSearchParams(location.search)
        search.set('step', (step + 1).toString())
        history.push(location.pathname + "?" + search.toString())
    }

    const boxes = useSelector(getBoxes)
    const boxcontents = useSelector(getBoxContents)
    const customer = useSelector(getCustomer)
    const deliveryoption = useSelector(getDeliveryoption)

    return (
        <PageContent>
            <Card title={
                <div className="flex items-center justify-between">
                    <div>
                        Neue Lieferung {customer && <span> für  <span className="font-bold"> {customer.name} </span> </span>}
                    </div>
                    {step === 2 &&
                        <div className="flex text-black gap-x-4">
                            <div className="px-4 py-1 bg-gray-200 rounded-full">
                                <span className="mr-1 font-bold">
                                    {boxes.length}
                                </span>
                                <span className="font-light">
                                    Box(en)
                            </span>
                            </div>
                            <div className="px-4 py-1 bg-gray-200 rounded-full">
                                <span className="mr-1 font-bold">
                                    {boxcontents.length}
                                </span>
                                <span className="font-light">
                                    Boxinhalt(e)
                            </span>
                            </div>
                            <div>
                                <Button onClick={() => nextStep()} disabled={boxcontents.length === 0 && boxes.length === 0}>
                                    <div className="flex items-center">
                                        <div>
                                            Prüfen und Abschicken
                                        </div>
                                        <div className="ml-2">
                                            <FaArrowRight/>
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    }
                </div>}>
                <div>
                    <Steps
                        step={step}
                        steps={[
                            <CustomerSelect nextStep={() => nextStep()} customer={customer} />,
                            <OptionSelect
                                nextStep={() => nextStep()} 
                                customer={customer}  
                                deliveryoption={deliveryoption}
                            />,
                            <CreateForm customer={customer}/>,
                            <CheckAndSubmitForm />
                        ]}
                    />
                </div>

            </Card>


        </PageContent >
    )
}