

export default class DeliveryFilter {

    public content?: string
    public box?: string
    public currentParams?: string = ""


    static fromEvent(filter: DeliveryFilter, event: React.FormEvent<HTMLInputElement>): DeliveryFilter {
        const newFilter = Object.assign(filter)
        newFilter[event.currentTarget.name] = event.currentTarget.value
        return newFilter
    }

    public fromParams(parms: string): DeliveryFilter {
        const p = new URLSearchParams(parms)
        this.box = p.get('box') && p.get('box') !== "" ? p.get('box')! : undefined
        this.content = p.get('content') && p.get('content') !== "" ? p.get('content')! : undefined
        this.currentParams = p.toString()
        return this
    }

    public toParams(other?: string): string{
        const params = new URLSearchParams(this.currentParams);
        if(this.box){
            params.set('box', this.box!.replaceAll('.', ''))
        } else{
            params.delete('box')
        }
        if(this.content){
            params.set('content', this.content!)
        } else{
            params.delete('content')
        }
        params.set('page', "1")
        this.currentParams = params.toString()
        return other? other+"&"+params.toString() : "?"+params.toString();
    }

    public params(other?: string): string {
        const params = new URLSearchParams();
        if(this.box){
            params.set('box', this.box.replaceAll('.', ''))
        }
        if(this.content){
            params.set('content', this.content)
        }
        return other? other+"&"+params.toString() : "?"+params.toString();
    }

}