/**
* Created on Tue Mar 09 2021 09:17:44
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import Box from '../../../../shared/models/Box';
import { StatesNum } from '../../../../shared/models/BoxContentState';

interface Props {
    box: Box
}

export default function ContentsCustomers({
    box
}: Props) {

    return (
        <div>
            {box.content.filter(c => c.state.num === StatesNum.INSIDE).length > 0 &&
                <>
                    <div className="mt-2 font-semibold text-gray-700">Inhalte in der Box:</div>
                    <ul className="flex flex-col list-inside">
                        {box.content.filter(c => c.state.num === StatesNum.INSIDE).map(
                            c => <li className="list-disc" key={c.id}>{c.text}</li>
                        )}
                    </ul>
                </>
            }
            {box.content.filter(c => c.state.num === StatesNum.ORDERED).length > 0 &&
                <>
                    <div className="mt-2 font-semibold text-gray-700">Angeforderte Inhalte:</div>
                    <ul className="flex flex-col list-inside">
                        {box.content.filter(c => c.state.num === StatesNum.ORDERED).map(
                            c => <li className="list-disc" key={c.id}>{c.text}</li>
                        )}
                    </ul>
                </>
            }
            {box.content.filter(c => c.state.num === StatesNum.OUTSIDE).length > 0 &&
                <>
                    <div className="mt-2 font-semibold text-gray-700">Inhalte außerhalb der Box:</div>
                    <ul className="flex flex-col list-inside">
                        {box.content.filter(c => c.state.num === StatesNum.OUTSIDE).map(
                            c => <li className="list-disc" key={c.id}>{c.text}</li>
                        )}
                    </ul>
                </>
            }
        </div>
    )
}