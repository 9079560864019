/**
* Created on Thu Feb 25 2021 10:58:11
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Box from "../../shared/models/Box";
import BoxContent from "../../shared/models/BoxContent";
import Customer from "../../shared/models/Customer";
import Delivery from "../../shared/models/Delivery";
import DeliveryOption from "../../shared/models/DeliveryOption";
import Pagination from "../../shared/models/Pagination";
import RequestParams from "../../shared/models/RequestParams";

export const LOAD_DELIVERIES = '@@deliveries/LOAD_DELIVERIES';
export type LOAD_DELIVERIES = typeof LOAD_DELIVERIES;

export const LOAD_DELIVERIES_SUCCESS = '@@deliveries/LOAD_DELIVERIES_SUCCESS';
export type LOAD_DELIVERIES_SUCCESS = typeof LOAD_DELIVERIES_SUCCESS;

export const LOAD_BOXES = '@@deliveries/LOAD_BOXES';
export type LOAD_BOXES = typeof LOAD_BOXES;

export const LOAD_BOXES_SUCCESS = '@@deliveries/LOAD_BOXES_SUCCESS';
export type LOAD_BOXES_SUCCESS = typeof LOAD_BOXES_SUCCESS;

export const LOAD_CUSTOMERS = '@@deliveries/LOAD_CUSTOMERS';
export type LOAD_CUSTOMERS = typeof LOAD_CUSTOMERS;

export const LOAD_CUSTOMERS_SUCCESS = '@@deliveries/LOAD_CUSTOMERS_SUCCESS';
export type LOAD_CUSTOMERS_SUCCESS = typeof LOAD_CUSTOMERS_SUCCESS;

export const SET_CUSTOMER = '@@deliveries/SET_CUSTOMER';
export type SET_CUSTOMER = typeof SET_CUSTOMER;

export const SET_DELIVERYOPTION = '@@deliveries/SET_DELIVERYOPTION';
export type SET_DELIVERYOPTION = typeof SET_DELIVERYOPTION;

export const ADD_BOX_TO_DELIVERY = '@@deliveries/ADD_BOX_TO_DELIVERY';
export type ADD_BOX_TO_DELIVERY = typeof ADD_BOX_TO_DELIVERY;

export const ADD_BOX_CONTENT_TO_DELIVERY = '@@deliveries/ADD_BOX_CONTENT_TO_DELIVERY';
export type ADD_BOX_CONTENT_TO_DELIVERY = typeof ADD_BOX_CONTENT_TO_DELIVERY;

export const REMOVE_BOX_FROM_DELIVERY = '@@deliveries/REMOVE_BOX_FROM_DELIVERY';
export type REMOVE_BOX_FROM_DELIVERY = typeof REMOVE_BOX_FROM_DELIVERY;

export const REMOVE_BOX_CONTENT_FROM_DELIVERY = '@@deliveries/REMOVE_BOX_CONTENT_FROM_DELIVERY';
export type REMOVE_BOX_CONTENT_FROM_DELIVERY = typeof REMOVE_BOX_CONTENT_FROM_DELIVERY;

export const ADD_DELIVERY = '@@deliveries/ADD_DELIVERY';
export type ADD_DELIVERY = typeof ADD_DELIVERY;

export const ADD_DELIVERY_SUCCESS = '@@deliveries/ADD_DELIVERY_SUCCESS';
export type ADD_DELIVERY_SUCCESS = typeof ADD_DELIVERY_SUCCESS;

export const loadDeliveriesAction = createAction(LOAD_DELIVERIES)<RequestParams>();
export const loadDeliveriesSuccessAction = createAction(LOAD_DELIVERIES_SUCCESS)<Pagination<Delivery>>();

export const loadBoxesAction = createAction(LOAD_BOXES)<RequestParams>();
export const loadBoxesSuccessAction = createAction(LOAD_BOXES_SUCCESS)<Pagination<Box>>();

export const addBoxToDeliveryAction = createAction(ADD_BOX_TO_DELIVERY)<Box>();
export const addBoxContentToDeliveryAction = createAction(ADD_BOX_CONTENT_TO_DELIVERY)<BoxContent>();

export const removeBoxFromDeliveryAction = createAction(REMOVE_BOX_FROM_DELIVERY)<Box>();
export const removeBoxContentFromDeliveryAction = createAction(REMOVE_BOX_CONTENT_FROM_DELIVERY)<BoxContent>();

export const loadCustomersAction = createAction(LOAD_CUSTOMERS)<RequestParams>();
export const loadCustomersSuccessAction = createAction(LOAD_CUSTOMERS_SUCCESS)<Customer[]>();

export const setCustomerAction = createAction(SET_CUSTOMER)<Customer>();

export const setDeliveryOptionAction = createAction(SET_DELIVERYOPTION)<DeliveryOption>();

export const addDeliveryAction = createAction(ADD_DELIVERY)<Delivery>();
export const addDeliverySuccessAction = createAction(ADD_DELIVERY_SUCCESS)<Delivery>();

export type DeliveriesAction = ActionType<
    | typeof loadDeliveriesAction
    | typeof loadDeliveriesSuccessAction
    | typeof loadBoxesAction
    | typeof loadBoxesSuccessAction
    | typeof addBoxToDeliveryAction
    | typeof addBoxContentToDeliveryAction
    | typeof removeBoxFromDeliveryAction
    | typeof removeBoxContentFromDeliveryAction
    | typeof addDeliveryAction
    | typeof setDeliveryOptionAction
    | typeof setCustomerAction
>