/**
* Created on Sat Jan 23 2021 16:30:27
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Page } from '../../shared/components/page';
import TopBar from '../../shared/components/topbar';
import Overview from './overview';
import Areas from './areas';
import Area from './area';
import Boxes from './boxes';
import Box from './box';
import BoxesNew from './box-form';
import Rows from './rows';
import Row from './row';
import AreaNew from './area-form';
import RowNew from './row-form';


export default function () {

    return(
        <Page>
            <TopBar
                title="Lager"
                links={[
                    { name: "Überblick", to: "/stock/overview"},
                    { name: "Boxen", to: "/stock/boxes"},
                    { name: "Bereiche", to: "/stock/areas", staff: true },
                    { name: "Reihen", to: "/stock/rows", staff: true }
                ]}
            />
            <Switch>
                <Route exact path="/stock" 
                    render={ () => { return <Redirect to="/stock/overview" /> }}
                />
                <Route path="/stock/overview" component={Overview} />
                <Route path="/stock/boxes/new" exact component={BoxesNew} />
                <Route path="/stock/boxes/:id" component={Box} />
                <Route path="/stock/boxes" component={Boxes}/>
                <Route path="/stock/areas/new" exact component={AreaNew} />
                <Route path="/stock/areas/:id" component={Area} />
                <Route path="/stock/areas" component={Areas}/>
                <Route path="/stock/rows/new" exact component={RowNew} />
                <Route path="/stock/rows/:id" component={Row} />
                <Route path="/stock/rows" component={Rows}/>
            </Switch>
        </Page>
    )
}