/**
* Created on Tue Jan 26 2021 10:20:12
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/


class CreateState {


    constructor(
        public creating: boolean = false,
        public created: boolean = false,
        public failure: boolean = false,
    ){}

    static init(): CreateState {
        return new CreateState()
    }

    static creating(): CreateState {
        let state = new CreateState()
        state.created = false
        state.creating = true
        state.failure = false
        return state
    }

    static created(): CreateState {
        let state = new CreateState()
        state.created = true
        state.creating = false
        state.failure = false
        return state
    }

    static failure(): CreateState {
        let state = new CreateState()
        state.created = false
        state.creating = false
        state.failure = true
        return state
    }
}

class LoadState {
    constructor(
        public loading: boolean = false,
        public loaded: boolean = false,
        public failure: boolean = false,
    ){}
    
    static init(): LoadState {
        return new LoadState()
    }

    static loading(): LoadState {
        let state = new LoadState()
        state.loaded = false
        state.loading = true
        state.failure = false
        return state
    }

    static loaded(): LoadState {
        let state = new LoadState()
        state.loaded = true
        state.loading = false
        state.failure = false
        return state
    }

    static failure(): LoadState {
        let state = new LoadState()
        state.loaded = false
        state.loading = false
        state.failure = true
        return state
    }
}

class UpdateState {
    constructor(
        public updating: boolean = false,
        public updated: boolean = false,
        public failure: boolean = false,
    ){}
}

class DeleteState {
    constructor(
        public deleting: boolean = false,
        public deleted: boolean = false,
        public failure: boolean = false,
    ){}
}

export {
    CreateState,
    LoadState,
    UpdateState,
    DeleteState
}