/**
* Created on Mon Apr 12 2021 13:58:10
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { urlHttps } from "../utils"

export default class RowQRCode{

    constructor(
        public id: number,
        public image: string,
        public created: Date,
    ) {}

    static fromAPI(model?: any): RowQRCode {
        const qrCode = new RowQRCode(
            model.id,
            urlHttps(model.image),
            model.created
        )
        return qrCode
    }

}