/**
* Created on Sat Feb 20 2021 17:06:03
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import Gender from "./Gender";

export default class User {

    public id?: number;
    public phone1?: string;
    public phone2?: string;
    public lastLogin?: string;
    public initPassword?: boolean;
    public gender?: Gender = undefined;
    constructor(
        public firstName: string,
        public lastName: string,
        public email: string,
        public isStaff: boolean = false,
    ) { };

    static fromAPI(model?: any): User {
        const user = new User(
            model.first_name,
            model.last_name,
            model.email,
            model.is_staff
        )
        user.gender = Gender.fromAPI(model.gender);
        user.initPassword = model.init_password;
        user.id = model.id;
        user.phone1 = model.phone1
        user.phone2 = model.phone2
        user.lastLogin = model.last_login
        return user
    }

    static toAPI(u: User): Object {
        let data: any = {
            last_name: u.lastName,
            email: u.email, 
        }
        
        if(u.gender){
            data.gender = u.gender.key
        }
        if(u.firstName){
            data.first_name = u.firstName
        }
        if(u.isStaff) {
            data.is_staff = u.isStaff
        }
        if(u.phone1) {
            data.phone1 = u.phone1
        }
        if(u.phone2) {
            data.phone2 = u.phone2
        }

        return data
    }

}