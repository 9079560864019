/**
* Created on Sat Mar 27 2021 12:12:41
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaSave, FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import { Card, SubTitle } from '../../../shared/components/card';
import Input from '../../../shared/components/inputs/Input';
import MultiSelect from '../../../shared/components/inputs/Multiselect';
import TextArea from '../../../shared/components/inputs/TextArea';
import YesNo from '../../../shared/components/overlays/YesNo';
import { PageContent } from '../../../shared/components/page';
import { deleteCustomerAction, loadCustomerAction, loadUsersAction, updateCustomerAction } from '../../actions/customers.actions';
import { getCustomer, getPossibleUsers, loadedCustomer } from '../../selector/customer.selector';
import DeliveryOptions from './DeliveryOptions';
import validationSchema from './validationSchema';

interface RouterProps {
    id: string;
}

interface CustomerProps extends RouteComponentProps<RouterProps> { }

export default function Customer({
    match
}: CustomerProps) {

    const id = parseInt(match.params.id);
    const customer = useSelector(getCustomer);
    const loaded = useSelector(loadedCustomer);
    const users = useSelector(getPossibleUsers);
    const [showYesNoDelete, setShowYesNoDelete] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadCustomerAction(id));
        dispatch(loadUsersAction());
    }, [dispatch, id])



    return (
        <div>
            <YesNo
                title="Löschen"
                text={`${customer?.name} wirklich löschen?`}
                type="danger"
                open={showYesNoDelete}
                onClose={() => setShowYesNoDelete(false)}
                onYes={() => dispatch(deleteCustomerAction(customer!))}
                onNo={() => setShowYesNoDelete(false)}
            />
            <PageContent loading={!loaded}>
                <Formik
                    enableReinitialize={true}
                    initialValues={customer!}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log(values);
                        dispatch(updateCustomerAction(values))
                        setSubmitting(false)
                    }}
                >
                    {({
                        isSubmitting,
                        isValid,
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Card title={
                                <div className="flex items-center justify-between">
                                    <div>
                                        ( {customer?.code} ) {customer?.name}
                                    </div>
                                    <div className="flex gap-x-2">
                                        {customer?.countBoxes! === 0 &&
                                            <Button
                                                color="danger"
                                                icon={<FaTrashAlt />}
                                                type="button"
                                                onClick={() => setShowYesNoDelete(true)}
                                            >
                                                Kunde löschen
                                            </Button>
                                        }
                                        {values !== customer &&
                                            <Button
                                                type="submit"
                                                icon={<FaSave />}
                                                disabled={!isValid || isSubmitting}
                                            >
                                                Speichern
                                        </Button>
                                        }
                                    </div>
                                </div>
                            }>
                                <div className="p-1">
                                    <div className="p-1">
                                        <Input
                                            title="Name"
                                            type="text"
                                            name="name"
                                            controlId="form-customer-name"
                                            value={values.name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={touched.name && !!errors.name}
                                            isValid={touched.name && !errors.name}
                                            error={errors.name}
                                            required={true}
                                        />
                                    </div>
                                    <div className="p-1">
                                        <TextArea
                                            title="Notizen"
                                            name="notes"
                                            controlId="form-customer-notes"
                                            value={values.notes}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={touched.notes && !!errors.notes}
                                            isValid={touched.notes && !errors.notes}
                                            error={errors.notes}
                                        />
                                    </div>
                                </div>
                                <SubTitle title="Anschrift" />
                                <div className="p-1">
                                    <div className="p-1">
                                        <Input
                                            title="Name"
                                            type="text"
                                            name="addressName"
                                            controlId="form-customer-addressName"
                                            value={values.addressName}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={touched.addressName && !!errors.addressName}
                                            isValid={touched.addressName && !errors.addressName}
                                            error={errors.addressName}
                                        />
                                    </div>
                                    <div className="flex w-full p-1 gap-x-2">
                                        <div className="w-1/2">
                                            <Input
                                                title="PLZ"
                                                type="text"
                                                name="addressPostCode"
                                                controlId="form-customer-addressPostCode"
                                                value={values.addressPostCode}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={touched.addressPostCode && !!errors.addressPostCode}
                                                isValid={touched.addressPostCode && !errors.addressPostCode}
                                                error={errors.addressPostCode}
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <Input
                                                title="Stadt"
                                                type="text"
                                                name="addressCity"
                                                controlId="form-customer-addressCity"
                                                value={values.addressCity}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={touched.addressCity && !!errors.addressCity}
                                                isValid={touched.addressCity && !errors.addressCity}
                                                error={errors.addressCity}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-1">
                                        <Input
                                            title="Straße / Nr."
                                            type="text"
                                            name="addressStreet"
                                            controlId="form-customer-addressStreet"
                                            value={values.addressStreet}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={touched.addressStreet && !!errors.addressStreet}
                                            isValid={touched.addressStreet && !errors.addressStreet}
                                            error={errors.addressStreet}
                                        />
                                    </div>
                                </div>
                                <SubTitle title="Meta-Daten" />
                                <div className="p-1">
                                    <div className="p-1">
                                        <Input
                                            title="Meterpreis"
                                            type="number"
                                            name="meterPrice"
                                            controlId="form-customer-meterPrice"
                                            value={values.meterPrice}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={touched.meterPrice && !!errors.meterPrice}
                                            isValid={touched.meterPrice && !errors.meterPrice}
                                            error={errors.meterPrice}
                                            min={0}
                                            step={0.01}
                                        />
                                    </div>
                                    <div className="p-1">
                                        <MultiSelect
                                            name="users"
                                            title="Nutzer Berechtigungen"
                                            data={users}
                                            values={values.users?.filter(u => u.isStaff === false)}
                                            renderOption={i => `${i.firstName} ${i.lastName} ( ${i.email} )`}
                                            onChange={setFieldValue}
                                            emptyPlaceHolder="Kein User ausgewählt  (Nur Zugriff für Admins)"
                                        />
                                    </div>
                                </div>
                            </Card>
                        </form>
                    )}
                </Formik>
                <div className="mt-2">
                    <Card>
                        <SubTitle title="Lieferoptionen" />
                        <DeliveryOptions customer={customer!} />
                    </Card>
                </div>
            </PageContent>
        </div>
    )
}