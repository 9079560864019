import { combineEpics, ofType, Epic } from 'redux-observable';
import {  delay, map } from 'rxjs/operators';
import { NOTIFY_ERROR, NOTIFY_INFO, NOTIFY_SUCCESS, NOTIFY_WARNING, removeNotifyAction } from '../actions/notify.actions';

const timeoutNotify: Epic = action$ => 
    action$.pipe(
        ofType(
            NOTIFY_SUCCESS,
            NOTIFY_ERROR,
            NOTIFY_INFO,
            NOTIFY_WARNING
        ),
        delay(5000),
        map(({payload}) => removeNotifyAction(payload))
    )

export default combineEpics(
   timeoutNotify
)