/**
 * Created on Tue Mar 09 2021 11:51:45
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import React, {useEffect, useState} from 'react';
import {FaMinus, FaPlus, FaSearch} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import PaginationButton from '../../../shared/components/table/PaginationButton';
import Box from '../../../shared/models/Box';
import BoxContent from '../../../shared/models/BoxContent';
import Customer from '../../../shared/models/Customer';
import PageParams from '../../../shared/models/PageParams';
import RequestParams from '../../../shared/models/RequestParams';
import {history} from '../../../store/history';
import {
    addBoxContentToDeliveryAction,
    addBoxToDeliveryAction,
    loadBoxesAction,
    removeBoxContentFromDeliveryAction,
    removeBoxFromDeliveryAction
} from '../../actions/delieveries.actions';
import {getBoxes, getBoxContents} from '../../selectors/create.selector';
import {getPossibleBoxes} from '../../selectors/deliveries.selector';
import NewBoxContentInput from './NewBoxContentInput';

interface Props {
    customer: Customer;
}

export default function CreateForm({
                                       customer
                                   }: Props) {

    const dispatch = useDispatch();
    const location = useLocation();

    const [filterCode, setFilterCode] = useState("");
    const [filterContent, setFilterContent] = useState("");
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const [init, setInit] = useState(false)
    const timerTime = 500

    useEffect(() => {
        clearTimeout(timer!)
        if (customer) {
            const search = new URLSearchParams(location.search)
            const page = search.get('page')

            let buildParams = `owner_id=${customer.id!}&state=1&query={id,created,state,code,content}`
            if (filterCode) {
                buildParams = buildParams + `&contain_code=${filterCode}`
            }
            if (filterContent) {
                buildParams = buildParams + `&contain_content=${filterContent}`
            }

            const params = new RequestParams(buildParams)

            if (page) {
                params.page = new PageParams(parseInt(page))
            } else {
                params.page = new PageParams()
            }
            if (!init) {
                dispatch(loadBoxesAction(params))
                setInit(true)
            } else {
                if (timer) {
                    clearTimeout(timer)
                    setTimer(setTimeout(() => {
                        dispatch(loadBoxesAction(params))
                        setTimer(undefined)
                    }, timerTime))
                } else {
                    setTimer(setTimeout(() => {
                        dispatch(loadBoxesAction(params))
                        setTimer(undefined)
                    }, timerTime))
                }
            }
        } else {
            history.push(location.pathname)
        }

        return () => {
            clearTimeout(timer!)
            setTimer(undefined)
        }

    }, [dispatch, location, filterCode, filterContent])

    const possibleBoxes = useSelector(getPossibleBoxes)
    const boxes = useSelector(getBoxes)
    const boxcontents = useSelector(getBoxContents)

    const boxInDelivery = (box: Box): boolean => {
        let index = boxes.findIndex(b => b.id === box.id)
        if (index !== -1) {
            return true
        }
        return false
    }

    const boxContentInDelivery = (content: BoxContent): boolean => {
        let index = boxcontents.findIndex(c => c === content)
        if (index !== -1) {
            return true
        }
        return false
    }

    const unknownBoxContentsInDelivery = (box: Box): BoxContent[] => {
        const contents = boxcontents.filter(c => c.box === box.id)
        return contents.filter(c => c.id === undefined)
    }

    const addBox = (b: Box) => {
        dispatch(addBoxToDeliveryAction(b))
        boxcontents.filter(c => c.box === b.id).forEach(
            c => dispatch(removeBoxContentFromDeliveryAction(c))
        )
    }

    const addBoxContent = (c: BoxContent, b: Box) => {
        c.boxCode = b.code
        dispatch(addBoxContentToDeliveryAction(c))
    }

    return (
        <div>
            <table className="w-full">
                <thead>
                <tr>
                    <th className="w-1/3 px-2 text-left text-gray-700 border">
                        <div>
                            Code
                        </div>
                        <div className="flex items-center mb-1">
                            <FaSearch className="mx-2"/>
                            <input
                                className="w-full p-1 leading-tight bg-gray-200 border-2 border-gray-200 rounded outline-none focus:outline-none focus:bg-white focus:border-gray-200"
                                onChange={(e) => setFilterCode(e.target.value)}
                            >
                            </input>
                        </div>
                    </th>
                    <th className="w-2/3 px-2 text-left text-gray-700 border">
                        <div>
                            Boxinhalte
                        </div>
                        <div className="flex items-center mb-1">
                            <FaSearch className="mx-2"/>
                            <input
                                className="w-full p-1 leading-tight bg-gray-200 border-2 border-gray-200 rounded outline-none focus:outline-none focus:bg-white focus:border-gray-200"
                                onChange={(e) => setFilterContent(e.target.value)}
                            >
                            </input>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {possibleBoxes.data.map((b, i) =>
                    <tr key={`table-boxes-${b.id}-${i}`}
                        className={`${boxInDelivery(b) && "bg-adl-2"} border-t first:border-0 last:border-b hover:bg-gray-100`}>
                        <td className="flex items-center h-full px-2 my-1">
                            {boxInDelivery(b) ?
                                <button
                                    className="p-1 mr-2 text-white bg-red-500 rounded shadow focus:outline-none hover:bg-red-700"
                                    onClick={() => dispatch(removeBoxFromDeliveryAction(b))}>
                                    <FaMinus/>
                                </button>
                                :
                                <button
                                    className="p-1 mr-2 text-white rounded shadow focus:outline-none hover:bg-adl-1 bg-adl-2"
                                    onClick={() => addBox(b)}>
                                    <FaPlus/>
                                </button>
                            }
                            <div>
                                {b.formatCode()}
                            </div>
                        </td>
                        <td className="border-l">
                            {boxInDelivery(b) ?
                                <ul className="text-sm list-inside">
                                    {b.content.map((c, i) =>
                                        <li className="px-2 py-1 list-disc"
                                            key={`table-boxes-${b.id}-content-${c.id}-${i}`}>
                                            {c.text}
                                        </li>
                                    )}
                                </ul>
                                :
                                <ul className="text-sm">
                                    {b.content.map((c, i) => {
                                            if (c.state.key === "INSIDE") {
                                                return <li className={`px-2 py-1 ${boxContentInDelivery(c) && "bg-adl-2"}`}
                                                           key={`table-boxes-${b.id}-content-${c.id}-${i}`}>
                                                    <div className="flex items-center">
                                                        {boxContentInDelivery(c) ?
                                                            <button
                                                                className="p-1 mr-2 text-white bg-red-500 rounded shadow focus:outline-none hover:bg-red-700"
                                                                onClick={() => dispatch(removeBoxContentFromDeliveryAction(c))}>
                                                                <FaMinus/>
                                                            </button>
                                                            :
                                                            <button
                                                                className="p-1 mr-2 text-white rounded shadow focus:outline-none hover:bg-adl-1 bg-adl-2"
                                                                onClick={() => addBoxContent(c, b)}>
                                                                <FaPlus/>
                                                            </button>
                                                        }
                                                        <div>
                                                            {c.text}
                                                        </div>
                                                    </div>
                                                </li>
                                            }
                                            if (c.state.key === "NON_EXISTENT") {
                                                return <li className={`px-2 py-1 flex justify-between`}
                                                           key={`table-boxes-${b.id}-content-${c.id}-${i}`}>
                                                    <div className="font-bold text-red-500 line-through">
                                                        {c.text}
                                                    </div>
                                                    <div className="italic text-gray-500">
                                                        Inhalt existiert nicht
                                                    </div>
                                                </li>
                                            }
                                            if (c.state.key === "ORDERED") {
                                                return <li className={`px-2 py-1 flex justify-between`}
                                                           key={`table-boxes-${b.id}-content-${c.id}-${i}`}>
                                                    <div >
                                                        {c.text}
                                                    </div>
                                                    <div className="italic text-gray-500">
                                                        Inhalt wurde bereits angefordert
                                                    </div>
                                                </li>
                                            }
                                            if (c.state.key === "OUTSIDE") {
                                                return <li className={`px-2 py-1 flex justify-between`}
                                                           key={`table-boxes-${b.id}-content-${c.id}-${i}`}>
                                                    <div>
                                                        {c.text}
                                                    </div>
                                                    <div className="italic text-gray-500">
                                                        Inhalt wurde bereits ausgeliefert
                                                    </div>
                                                </li>
                                            }
                                            return <></>
                                        }
                                    )}
                                    {
                                        unknownBoxContentsInDelivery(b).map(
                                            (c, i) =>
                                                <li className="px-2 py-1 bg-adl-2"
                                                    key={`table-boxes-${b.id}-content-unknown-${i}`}>
                                                    <div className="flex items-center">
                                                        <button
                                                            className="p-1 mr-2 text-white bg-red-500 rounded shadow focus:outline-none hover:bg-red-700"
                                                            onClick={() => dispatch(removeBoxContentFromDeliveryAction(c))}>
                                                            <FaMinus/>
                                                        </button>
                                                        <div>
                                                            {c.text}
                                                        </div>
                                                    </div>
                                                </li>
                                        )
                                    }
                                    <NewBoxContentInput box={b}/>
                                </ul>
                            }
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            <div className="p-2">
                <PaginationButton pagination={possibleBoxes}/>
            </div>
        </div>
    )
}