import { createStore, applyMiddleware } from 'redux';
import rootReducer, { AppState } from '../reducers/rootReducer';
import { history } from './history';
import { routerMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import { AllActions } from '../actions/actions';
import rootEpics from '../epics/rootEpics';


export function configureStore() {

    const epicMiddleware = createEpicMiddleware<
        AllActions,
        AllActions,
        AppState
    >({
        dependencies: {
            get store() {
                return store
            }
        }
    });

    const store = createStore(
        rootReducer(history),
        applyMiddleware(
            routerMiddleware(history),
            epicMiddleware
        )
    )
    
    epicMiddleware.run(rootEpics)
    return store
}