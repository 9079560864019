/**
* Created on Sun Apr 18 2021 12:48:02
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import download from 'downloadjs';
import { ActionType } from 'typesafe-actions';
import {  downloadCollectListSuccessAction, DOWNLOAD_COLLECT_LIST_SUCCESS } from '../actions/services.actions';

export interface State { }

export default function (
    state: State = {},
    action: ActionType<
        | typeof downloadCollectListSuccessAction
    >
): State {
    switch (action.type) {
        case DOWNLOAD_COLLECT_LIST_SUCCESS:
            download(action.payload.data, `ADL-Sammelliste.pdf`)
            return state
        default:
            return state
    }
}