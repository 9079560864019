/**
* Created on Fri Apr 09 2021 08:26:53
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import { Card, SubTitle } from '../../../shared/components/card';
import YesNo from '../../../shared/components/overlays/YesNo';
import { PageContent } from '../../../shared/components/page';
import Table from '../../../shared/components/table';
import BooleanCell from '../../../shared/components/table/BooleanCell';
import Row from '../../../shared/models/Row';
import { history } from '../../../store/history';
import { deleteAreaAction, loadAreaAction } from '../../actions/area.actions';
import { getArea, loadedArea } from '../../selectors/area.selectors';

interface RouterProps {
    id: string;
}

interface AreaProps extends RouteComponentProps<RouterProps> { }

export default function ({
    match
}: AreaProps) {

    const id = parseInt(match.params.id)
    const dispatch = useDispatch();
    const [showYesNoDelete, setShowYesNoDelete] = useState(false);

    useEffect(() => {
        dispatch(loadAreaAction(id))
    }, [dispatch, id])

    const loaded = useSelector(loadedArea)
    const area = useSelector(getArea)
    const columns_row = [
        { name: "Nummer", key: "number" },
        { name: "Rückwärts", key: "reverse", render: (r: Row) => <BooleanCell value={r.reverse} /> },
        { name: "#Boxen", key: "boxesCount" }
    ]

    return (
        <div>
            <YesNo
                title="Löschen"
                text={`Bereich ${area?.name} wirklich löschen?`}
                type="danger"
                open={showYesNoDelete}
                onClose={() => setShowYesNoDelete(false)}
                onYes={() => dispatch(deleteAreaAction(area!))}
                onNo={() => setShowYesNoDelete(false)}
            />
            <PageContent loading={!loaded}>
                <Card
                    title={<div className="flex items-center justify-between">
                        <div>
                            Bereich {area?.name}
                        </div>
                        {area?.rowsCount === 0 &&
                            <div>
                                <Button
                                    icon={<FaTrashAlt />}
                                    color="danger"
                                    type="button"
                                    onClick={() => setShowYesNoDelete(true)}
                                >
                                    Bereich löschen
                                </Button>
                            </div>
                        }
                    </div>
                    }
                >
                    <div className="m-2">
                        <div className="w-full lg:w-1/2">
                            <div className="grid grid-cols-2 gap-x-2">
                                <div className="font-semibold text-gray-700">Name:</div>
                                <div>{area?.name}</div>
                                <div className="font-semibold text-gray-700">Anzahl Reihen:</div>
                                <div>{area?.rowsCount}</div>
                                <div className="font-semibold text-gray-700">Anzahl Boxen:</div>
                                <div>{area?.boxesCount}</div>
                            </div>
                        </div>
                    </div>
                    <SubTitle title={`Reihen ( ${area?.rowsCount} )`} />
                    <Table
                        data={area?.rows!}
                        columns={columns_row}
                        onClickRow={(r: Row) => history.push(`/stock/rows/${r.id}`)}
                    />
                </Card>
            </PageContent>
        </div>
    )
}