/**
* Created on Wed Feb 17 2021 12:04:45
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Box from "../../shared/models/Box";
import { LoadState } from "../../shared/models/RequestState";
import { addContentSuccessAction, ADD_CONTENT_SUCCESS, deleteBoxImageSuccessAction, deleteContentSuccessAction, DELETE_BOX_IMAGE_SUCCESS, DELETE_CONTENT_SUCCESS, loadBoxAction, loadBoxSuccessAction, LOAD_BOX, LOAD_BOX_SUCCESS, updateBoxSuccessAction, UPDATE_BOX_SUCCESS, viewedBoxImageSuccessAction, VIEWED_BOX_IMAGE_SUCCESS } from "../actions/box.actions";

export interface State {
    data: Box | undefined,
    load: LoadState,
}

export default function (
    state: State = {
        data: undefined,
        load: LoadState.init()
    },
    action: ActionType<
        | typeof loadBoxAction
        | typeof loadBoxSuccessAction
        | typeof viewedBoxImageSuccessAction
        | typeof deleteBoxImageSuccessAction
        | typeof addContentSuccessAction
        | typeof deleteContentSuccessAction
        | typeof updateBoxSuccessAction
    >
): State {

    switch (action.type) {
        case LOAD_BOX:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_BOX_SUCCESS:
            return update(state, {
                data: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        case UPDATE_BOX_SUCCESS:
            return update(state, {
                data: { $set: action.payload }
            })
        case VIEWED_BOX_IMAGE_SUCCESS:
            var index = state.data?.images!.findIndex(image =>  image.id === action.payload.id)
            return update(state, {
                data: {
                    images: { [index!]: { $set: action.payload  }}}
                }
            )
        case DELETE_BOX_IMAGE_SUCCESS:
            var index = state.data?.images!.findIndex(image =>  image.id === action.payload)
            return update(state, {
                data: {
                    images: { $splice: [[index!, 1]]}
                }}
            )
        case DELETE_CONTENT_SUCCESS:
            var index = state.data?.content!.findIndex(content => content.id === action.payload)
            return update(state, {
                data: {
                    content: { $splice: [[index!, 1]]}
                }
            })
        case ADD_CONTENT_SUCCESS:
            return update(state, {
                data: {
                    content: { $push: [action.payload] }
                }
            })
        default:
            return state
    }

}