/**
* Created on Tue Mar 30 2021 20:43:11
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from '../../reducers/rootReducer';
import Notification from '../models/Notification';

export function getNotifications(state: AppState): Notification[] {
    return state.notify.data
}