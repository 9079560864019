/**
 * Created on Sat Jan 23 2021 13:30:44
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import React from 'react';
import {Page, PageContent} from '../../shared/components/page';
import TopBar from '../../shared/components/topbar';
// @ts-ignore
import app_v1 from '../../shared/apps/app-v1.0.apk';
// @ts-ignore
import app_v11 from '../../shared/apps/app-v1.1.apk';

// @ts-ignore
import app_v111 from '../../shared/apps/app-v1.1.1.apk';

import Table from "../../shared/components/table";
import {Card} from "../../shared/components/card";
import {MdAndroid} from "react-icons/md";


export default function () {

    const columns = [
        {name: "Version", key: "version", render: (d: any) => <div className="flex justify-between"> <span>{d.version}</span> <a className="text-blue-500 underline" href={d.download}>download</a></div>},
        {name: "Datum", key: "date"},
        {name: "Beschreibung / Änderungen", key: "description"}
    ]

    const data = [
        {
            version: "1.1.1",
            download: app_v111,
            description: <ul className="list-inside list-disc">
                <li><span className="font-semibold">[Bug fix]</span> Lieferungen können nicht angeklickt werden</li>
            </ul>,
            date: "16.06.2021",
        },
        {
            version: "1.1",
            download: app_v11,
            description: <ul className="list-inside list-disc">
                <li><span className="font-semibold">[Bug fix]</span> leere Reihen anzeigen in Reihen Ansicht</li>
                <li><span className="font-semibold">[Änderung]</span> "Box bereits eingelagert" Meldung</li>
                <li><span className="font-semibold">[Design]</span> Anpassungen</li>
            </ul>,
            date: "15.06.2021",
        },
        {
            version: "1.0",
            download: app_v1,
            description: "",
            date: "04.06.2021",
        },
    ]

    return (
        <Page>
            <TopBar
                title="App & Scripts"
                links={[]}
            />
            <PageContent>
                <Card title={
                    <div className="flex items-center">
                        <div className="mr-4">
                            <MdAndroid className="text-android"/>
                        </div>
                        <div>
                            Android Management System App
                        </div>
                    </div>
                }>
                    <Table
                        columns={columns}
                        data={data}
                    />
                </Card>
            </PageContent>
        </Page>
    )
}