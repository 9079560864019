import React from 'react';
import {BeatLoader} from "react-spinners";

export default function LoadingBeat() {

    return (
        <BeatLoader
            color="#fff"
            size={10}
        />
    )
}