/**
* Created on Thu Feb 25 2021 11:50:30
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Box from "../../shared/models/Box";
import Customer from "../../shared/models/Customer";
import Delivery from "../../shared/models/Delivery";
import Pagination from "../../shared/models/Pagination";

export function getDeliveries(state: AppState): Pagination<Delivery> {
    return state.deliveries.deliveries.data
}

export function loadingDeliveries(state: AppState): boolean {
    return state.deliveries.deliveries.load.loading
}

export function getPossibleBoxes(state: AppState): Pagination<Box> {
    return state.deliveries.possibleBoxes.data
}

export function getPossibleCustomers(state: AppState): Customer[] {
    return state.deliveries.possibleCustomers.data
}