/**
* Created on Wed Feb 24 2021 12:25:05
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Auth from "../models/Auth";
import User from "../models/User";


export const AUTH = '@@auth/AUTH';
export type AUTH = typeof AUTH;

export const AUTH_SUCCESS = '@@auth/AUTH_SUCCESS';
export type AUTH_SUCCESS = typeof AUTH_SUCCESS;

export const NO_TOKEN = '@@auth/NO_TOKEN';
export type NO_TOKEN = typeof NO_TOKEN;

export const LOGOUT = '@@auth/LOGOUT';
export type LOGOUT = typeof LOGOUT;

export const LOGOUT_SUCCESS = '@@auth/LOGOUT_SUCCESS';
export type LOGOUT_SUCCESS = typeof LOGOUT_SUCCESS;

export const authAction = createAction(AUTH)<number>();
export const authSuccessAction = createAction(AUTH_SUCCESS)<User>();

export const noTokenAction = createAction(NO_TOKEN)<void>();

export const logoutAction = createAction(LOGOUT)<void>();
export const logoutSuccessAction = createAction(LOGOUT_SUCCESS)<Auth>();

export type AuthActions = ActionType<
    | typeof authAction
    | typeof authSuccessAction
    | typeof logoutAction
    | typeof logoutSuccessAction
    | typeof noTokenAction
>