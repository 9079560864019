/**
* Created on Wed Apr 14 2021 12:14:15
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import YesNo from '../../../../shared/components/overlays/YesNo';
import BoxContent from '../../../../shared/models/BoxContent';
import BoxContentState from '../../../../shared/models/BoxContentState';
import { deleteContentAction, updateContentAction } from '../../../actions/box.actions';

interface Props {
    content: BoxContent
}

export default function ({
    content
}: Props) {

    const [text, setText] = useState(content.text)
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const [showYesNoDelete, setShowYesNoDelete] = useState(false);
    const dispatch = useDispatch()
    const handleOnChange = (e: any) => {
        setText(e.target.value)
        if (timer) {
            clearTimeout(timer)
            setTimer(setTimeout(() => {
                const c = new BoxContent(
                    e.target.value,
                    new BoxContentState("INSIDE")
                )
                c.id = content.id
                dispatch(updateContentAction(c))
                setTimer(undefined)
            }, 1000))
        } else {
            setTimer(setTimeout(() => {
                const c = new BoxContent(
                    e.target.value,
                    new BoxContentState("INSIDE")
                )
                c.id = content.id
                dispatch(updateContentAction(c))
                setTimer(undefined)
            }, 1000))
        }
    }

    return (
        <div>
            <YesNo
                title="Löschen"
                text={` Inhalt "${content?.text}" wirklich löschen?`}
                type="danger"
                open={showYesNoDelete}
                onClose={() => setShowYesNoDelete(false)}
                onYes={() => dispatch(deleteContentAction(content))}
                onNo={() => setShowYesNoDelete(false)}
            />
            <div className="flex items-center ">
                <input
                    className={`w-full px-2 py-2 bg-gray-200 rounded outline-none leading-tight focus:outline-none focus:bg-white focus:border-adl-1 border-gray-200 border-2`}
                    value={text}
                    onChange={handleOnChange}
                />
                <div className="ml-2">
                    <FaTrashAlt
                        className="cursor-pointer"
                        onClick={ () => setShowYesNoDelete(true) }
                    />
                </div>
            </div>
        </div>
    )
}