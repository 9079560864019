/**
* Created on Sat Mar 27 2021 13:05:35
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Customer from "../../shared/models/Customer";
import DeliveryOption from '../../shared/models/DeliveryOption';
import { LoadState } from "../../shared/models/RequestState";
import User from '../../shared/models/User';
import { 
    addDeliveryOptionSuccessAction,
    ADD_DELIVERY_OPTION_SUCCESS,
    deleteCustomerSuccessAction,
    deleteDeliveryOptionSuccessAction,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_DELIVERY_OPTION_SUCCESS,
    loadCustomerAction,
    loadCustomerSuccessAction,
    loadUsersSuccessAction,
    LOAD_CUSTOMER,
    LOAD_CUSTOMER_SUCCESS,
    LOAD_USERS_SUCCESS, 
    selectDeliveryOptionAction, 
    SELECT_DELIVERY_OPTION, 
    updateCustomerSuccessAction, 
    updateDeliveryOptionSuccessAction, 
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_DELIVERY_OPTION_SUCCESS
} from "../actions/customers.actions";

export interface State {
    data: Customer | undefined,
    load: LoadState,
    possibleUsers: User[],
    selectedDeliveryOption: DeliveryOption | undefined
}

export default function(
    state: State = {
        data: undefined,
        load: LoadState.init(),
        possibleUsers: [],
        selectedDeliveryOption: undefined
    },
    action: ActionType<
        | typeof loadCustomerAction
        | typeof loadCustomerSuccessAction
        | typeof loadUsersSuccessAction
        | typeof updateCustomerSuccessAction
        | typeof deleteCustomerSuccessAction
        | typeof addDeliveryOptionSuccessAction
        | typeof selectDeliveryOptionAction
        | typeof updateDeliveryOptionSuccessAction
        | typeof deleteDeliveryOptionSuccessAction
    >
): State {
    switch (action.type) {
        case LOAD_CUSTOMER:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_CUSTOMER_SUCCESS:
            return update(state, {
                data: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        case LOAD_USERS_SUCCESS:
            return update(state, {
                possibleUsers: { $set: action.payload }
            })
        case UPDATE_CUSTOMER_SUCCESS:
            return update(state, {
                data: { $set: action.payload }
            })
        case DELETE_CUSTOMER_SUCCESS:
            return update(state, {
                data: { $set: undefined },
                load: { $set: LoadState.init() },
                possibleUsers: { $set: [] }
            })
        case ADD_DELIVERY_OPTION_SUCCESS:
            return update(state, {
                data: { 
                    deliveryoptions: {
                        $push: [action.payload]
                    }
                },
                selectedDeliveryOption: { $set : action.payload}
            })
        case SELECT_DELIVERY_OPTION:
            return update(state, {
                selectedDeliveryOption: { $set : action.payload}
            })
        case UPDATE_DELIVERY_OPTION_SUCCESS:
            var index = state.data?.deliveryoptions?.findIndex(o => o.id === action.payload.id)
            return update(state, {
                data: {
                    deliveryoptions: {
                        [index!]: {$set : action.payload}
                    }
                }
            })
        case DELETE_DELIVERY_OPTION_SUCCESS:
            var index = state.data?.deliveryoptions?.findIndex(o => o.id === action.payload)
            return update(state, {
                data: { 
                    deliveryoptions: {
                        $splice: [[index!, 1]]
                    }
                },
                selectedDeliveryOption: { $set : undefined}
            })
        default:
            return state
            
    }

}