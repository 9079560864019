/**
* Created on Mon Feb 01 2021 11:55:02
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { history } from '../../../store/history';
import Pagination from '../../models/Pagination';
import { addParamString, createRandomId } from '../../utils';


interface Props {
    pagination: Pagination<any>
    loading?: boolean
}

export default function PaginationButton({
    pagination,
    loading
}: Props) {

    const location = useLocation();
    const [current, setCurrent] = useState(1);
    const PAGE_SHOW_LIMIT = 2

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const page = params.get('page')
        if (page) {
            setCurrent(parseInt(page))
        } else {
            setCurrent(1)
        }

    }, [location])

    const setPage = (p: number) => {
        if (p === 0) {
            history.push(location.pathname + addParamString(location.search, "page", 1))
            return
        }
        if (p >= pagination.totalPages) {
            history.push(location.pathname + addParamString(location.search, "page", pagination.totalPages))
            return
        }
        history.push(location.pathname + addParamString(location.search, "page", p))
    }

    const [buttonID] = useState(createRandomId())

    return (
        <div className={`flex overflow-hidden text-white rounded w-min`}>
            {current !== 1 &&
                <button className={`px-2 py-1 focus:outline-none font-bold bg-adl-2 ${loading && "cursor-not-allowed"}`} onClick={() => setPage(current - 1)}>
                    <FaArrowLeft />
                </button>
            }
            {Array.from({ length: pagination.totalPages }, (_, i) => i + 1).map(i => {

                if (i <= PAGE_SHOW_LIMIT) {
                    return (
                        <button key={`button-pagination-${i}-${buttonID}`} className={`px-3 py-1 focus:outline-none font-bold ${i === current ? "bg-adl-1" : "bg-adl-2"} ${loading && "cursor-not-allowed"}`} onClick={() => setPage(i)}>
                            {i}
                        </button>
                    )
                }

                if (current - PAGE_SHOW_LIMIT === i || current + PAGE_SHOW_LIMIT === i) {
                    return (<div key={`placeholder-pagination-${i}-${buttonID}`} className="px-3 py-1 font-bold select-none focus:outline-none bg-adl-2">...</div>)
                }

                if (i === current || (current - PAGE_SHOW_LIMIT < i && i < current + PAGE_SHOW_LIMIT)) {
                    return (
                        <button key={`button-pagination-${i}-${buttonID}`} className={`px-3 py-1 focus:outline-none font-bold ${i === current ? "bg-adl-1" : "bg-adl-2"} ${loading && "cursor-not-allowed"}`} onClick={() => setPage(i)}>
                            {i}
                        </button>
                    )
                }

                if (i > pagination.totalPages - PAGE_SHOW_LIMIT) {
                    return (
                        <button key={`button-pagination-${i}-${buttonID}`} className={`px-3 py-1 focus:outline-none font-bold ${i === current ? "bg-adl-1" : "bg-adl-2"} ${loading && "cursor-not-allowed"}`} onClick={() => setPage(i)}>
                            {i}
                        </button>
                    )
                }

                return ""
            }

            )}
            { current !== pagination.totalPages &&
                < button className={`px-2 py-1 focus:outline-none font-bold bg-adl-2 ${loading && "cursor-not-allowed"}`} onClick={() => setPage(current + 1)}>
                    <FaArrowRight />
                </button>
            }
        </div >
    )
}