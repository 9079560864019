/**
* Created on Fri Mar 05 2021 10:33:53
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';

interface Props {
    title: string;
}

export default function SubTitle({
    title,

}: Props) {

    return(
        <div className="px-2 py-1 text-sm font-semibold text-white bg-adl-1">
            {title}
        </div>
    )
}