import React, {useState} from 'react';
import Delivery from "../../../shared/models/Delivery";
import {useDispatch} from "react-redux";
import {updateDeliveryAction} from "../../actions/delivery.actions";
import {formatDate, parseDate } from "../../../shared/utils";
import Staff from "../../../shared/components/Staff";
import NotStaff from "../../../shared/components/NotStaff";


interface Props {
    delivery: Delivery
}

export default function DateChange(
    {
        delivery
    }: Props) {

    const [time, setTime] = useState(delivery.time)
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const dispatch = useDispatch()

    const handleOnChange = (e: any) => {
        setTime(e.target.value)
        if (timer) {
            clearTimeout(timer)
            setTimer(setTimeout(() => {
                const d: Delivery = Object.assign(delivery)
                d.time = e.target.value
                if (d.time) {
                    dispatch(updateDeliveryAction(d))
                }
                setTimer(undefined)
            }, 1000))
        } else {
            setTimer(setTimeout(() => {
                const d: Delivery = Object.assign(delivery)
                d.time = e.target.value
                if (d.time) {
                    dispatch(updateDeliveryAction(d))
                }
                setTimer(undefined)
            }, 1000))
        }
    }

    return (
        <div className="flex items-center">
            <Staff>
                <input
                    className={`w-full px-2 py-2 bg-gray-200 rounded outline-none leading-tight focus:outline-none focus:bg-white focus:border-adl-1 border-gray-200 border-2 mb-2`}
                    value={parseDate(time!)}
                    type="datetime-local"
                    onChange={handleOnChange}
                />
            </Staff>
            <NotStaff>
                {formatDate(delivery!.time!)}
            </NotStaff>
        </div>
    )
}