/**
* Created on Sun Apr 18 2021 12:48:02
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from 'typesafe-actions';
import Box from '../../shared/models/Box';
import Customer from '../../shared/models/Customer';
import Pagination from '../../shared/models/Pagination';
import { LoadState } from '../../shared/models/RequestState';
import { 
    loadBoxesAction,
    loadBoxesSuccessAction,
    loadCustomersAction,
    loadCustomersSuccessAction,
    LOAD_BOXES,
    LOAD_BOXES_SUCCESS,
    LOAD_CUSTOMERS,
    LOAD_CUSTOMERS_SUCCESS 
} from '../actions/services.actions';

export interface State {
    customers: Customer[],
    boxes: Pagination<Box>,
    load: LoadState,
    loadBoxes: LoadState,
}

export default function (
    state: State = {
        customers: [],
        boxes: new Pagination<Box>(),
        load: LoadState.init(),
        loadBoxes: LoadState.init(),
    },
    action: ActionType<
        | typeof loadCustomersAction
        | typeof loadCustomersSuccessAction
        | typeof loadBoxesAction
        | typeof loadBoxesSuccessAction
    >
): State {
    switch (action.type) {
        case LOAD_CUSTOMERS:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_BOXES:
            return update(state, {
                loadBoxes: { $set: LoadState.loading() }
            })
        case LOAD_BOXES_SUCCESS:
            return update(state, {
                loadBoxes: { $set: LoadState.loaded() },
                boxes: { $set: action.payload }
            })
        case LOAD_CUSTOMERS_SUCCESS:
            return update(state, {
                load: { $set: LoadState.loaded() },
                customers: { $set: action.payload }
            })
        default:
            return state
    }
}