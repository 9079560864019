/**
* Created on Sat Feb 20 2021 15:54:09
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Pagination from '../../shared/models/Pagination';
import { LoadState } from '../../shared/models/RequestState';
import User from '../../shared/models/User';
import { loadUsersAction, loadUsersSuccessAction, LOAD_USERS, LOAD_USERS_SUCCESS } from '../actions/users.actions';

export interface State {
    data: Pagination<User>,
    load: LoadState,
}

export default function (
    state: State = {
        data: new Pagination<User>(),
        load: LoadState.init()
    },
    action: ActionType<
        | typeof loadUsersAction
        | typeof loadUsersSuccessAction
    >
): State {
    switch (action.type) {
        case LOAD_USERS:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_USERS_SUCCESS:
            return update(state, {
                data: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        default:
            return state
    }
}