/**
* Created on Sat Jan 30 2021 18:00:01
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { MdArrowDownward, MdArrowUpward, MdRemove } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { history } from '../../../store/history';
import { FilterObject } from '../../models/Filter';
import Pagination from '../../models/Pagination';
import { addParamString, createRandomId, paramsToObject } from '../../utils';
import LoadingCircle from '../LoadingCircle';
import PaginationButton from './PaginationButton';

interface DataObject {
    [index: string]: any
}

interface Column {
    key: string;
    render?: (d: any) => any;
    name: string | JSX.Element;
    orderBy?: boolean;
    hidden?: boolean;
}

interface Props {
    columns: Column[];
    data: DataObject[];
    pagination?: Pagination<any>;
    loading?: boolean;
    onClickRow?: (d: any) => void;
    onFilter?: (f: FilterObject) => void;
    filter?: string[];
    rowHighlight?: (r: any) => string;
}

export default function Table({
    columns,
    data,
    pagination,
    loading,
    onClickRow,
    onFilter,
    filter,
    rowHighlight
}: Props) {

    const location = useLocation();
    const [tableID] = useState(createRandomId())
    const [orderBy, setOrderBy] = useState("")
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({} as FilterObject)

    const setFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        let f = {
            ...filters,
            [e.currentTarget.name]: e.target.value
        }
        if (onFilter) {
            onFilter(f)
        }
        setFilters(f)
    }

    const orderByValue = (key: string) => {
        if (orderBy === key || "-" + key === orderBy) {
            if (orderBy[0] === "-") {
                return key
            } else {
                return "-" + key
            }
        }
        return key
    }

    const toFilter = (key: string) => {
        if (filter !== undefined && filter?.indexOf(key) !== -1) {
            return true
        }
        return false
    }

    const resetFilter = () => {
        let f: FilterObject = {}
        if (onFilter) {
            onFilter(f)
        }
        setFilters(f)
    }

    const initFilter = () => {
        const params = new URLSearchParams(location.search)
        Array.from(params).forEach(item => {
            if (toFilter(item[0])) {
                setShowFilter(true)
            }
        })
        const filters = paramsToObject(params)
        setFilters(filters)
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const orderBy = params.get('orderBy')
        initFilter()
        setOrderBy(orderBy!)
    }, [location])

    return (
        <div className="relative">
            {loading &&
                <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                    <LoadingCircle />
                </div>
            }
            <table className="w-full border-b">
                <thead className="border-b">
                    <tr>
                        {columns.map((c, i) => ((c.hidden === undefined || c.hidden === false) &&
                            <th className={`px-2 text-left text-gray-700 border-r last:border-0`} key={`table-head-${i}-${tableID}`}>
                                <div className="flex items-center justify-between whitespace-nowrap">
                                    <div
                                        className={`flex items-center ${c.orderBy ? "cursor-pointer" : ""}`}
                                        onClick={
                                            c.orderBy ?
                                                () => {
                                                    const p = orderByValue(c.key!)
                                                    setOrderBy(p)
                                                    history.push(location.pathname + addParamString(location.search, "orderBy", p))
                                                } :
                                                () => { }
                                        }
                                    >
                                        {(c.key === orderBy || "-" + c.key === orderBy) &&
                                            <div className="mr-1">
                                                {orderBy[0] === "-" ? <MdArrowDownward /> : <MdArrowUpward />}
                                            </div>
                                        }
                                        {(c.orderBy !== undefined) && (c.key !== orderBy && "-" + c.key !== orderBy) &&
                                            <div className="mr-1">
                                                <MdRemove />
                                            </div>
                                        }
                                        <div>
                                            {c.name}
                                        </div>
                                    </div>
                                    <div
                                        className="ml-1 text-xs cursor-pointer"
                                        onClick={() => { setShowFilter(!showFilter); showFilter && resetFilter(); }}
                                    >
                                        {toFilter(c.key) &&
                                            <FaFilter />
                                        }
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                    <tr className={`${showFilter ? "" : "hidden"}`}>
                        {columns.map((c, i) => ((c.hidden === undefined || c.hidden === false) &&
                            <th className="px-1 border-r last:border-0" key={`table-head-filter-${i}-${tableID}`}>
                                {toFilter(c.key) &&
                                    <input
                                        className="w-full p-1 leading-tight bg-gray-200 border-2 border-gray-200 rounded outline-none focus:outline-none focus:bg-white focus:border-gray-200"
                                        onChange={(e) => setFilter(e)}
                                        name={c.key}
                                        value={filters[c.key] || ''}
                                    >
                                    </input>
                                }
                            </th>
                        )
                        )}
                    </tr>
                </thead>
                <tbody className={`${loading && "blur animate-pulse"}`}>
                    {data.length === 0 && !loading && <tr> <td className="p-2 italic text-gray-500"> Keine Daten vorhanden </td></tr>}
                    {data.map((d, i) =>
                        <tr
                            key={`table-row-${i}-${tableID}`} className={`${!loading && "hover:bg-gray-200 hover:shadow"} ${onClickRow && "cursor-pointer"} ${rowHighlight ? `${rowHighlight(d)} hover:${rowHighlight(d)}` : ""}`}
                            onClick={onClickRow ? () => onClickRow(d) : () => { }}
                        >
                            {columns.map((c, j) => ((c.hidden === undefined || c.hidden === false) &&
                                <td className={`px-2 border-r last:border-0 ${loading && "select-none"}`} key={`table-data-${i}-${j}-${tableID}`}>
                                    {c.render ? c.render(d) : d[c.key!]}
                                </td>
                            ))}
                        </tr>
                    )}
                </tbody>
            </table>
            {pagination && pagination.totalPages > 1 &&
                <div className="p-2">
                    <PaginationButton pagination={pagination} loading={loading} />
                </div>
            }
        </div>
    )
}