/**
* Created on Sun Apr 18 2021 15:54:32
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { Card, SubTitle } from '../../../shared/components/card';
import Input from '../../../shared/components/inputs/Input';
import Select from '../../../shared/components/inputs/Select';
import { PageContent } from '../../../shared/components/page';
import BoxFilter from '../../../shared/models/BoxFilter';
import Customer from '../../../shared/models/Customer';
import PageParams from '../../../shared/models/PageParams';
import RequestParams from '../../../shared/models/RequestParams';
import { loadBoxesAction, loadCustomersAction, updateMetaAction } from '../../actions/services.actions';
import { getBoxes, getCustomers } from '../../selector/metaupdate.selector';

export default function () {

    const [customer, setCustomer] = useState<Customer>()
    const [fromBox, setFromBox] = useState<number>()
    const [toBox, setToBox] = useState<number>()
    const [metaDestroyYear, setMetaDestroyYear] = useState<number>()
    const [metaFirstDeliveryCustomer, setMetaFirstDeliveryCustomer] = useState<number>()
    const [metaYear, setMetaYear] = useState<number>()
    const [size, setSize] = useState<number>()


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCustomersAction(""))
    }, [dispatch])

    const customers = useSelector(getCustomers)
    const boxes = useSelector(getBoxes)

    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const setFilterParams = (c: Customer, fB: number, tB: number) => {
        setCustomer(c)
        setFromBox(fB)
        setToBox(tB)
        if (c) {
            if (timer) {
                clearTimeout(timer)
                setTimer(setTimeout(() => {
                    const params = new RequestParams("")
                    params.page = new PageParams()
                    const boxFilter = new BoxFilter()
                    boxFilter.owner = c.code
                    boxFilter.from = fB
                    boxFilter.to = tB
                    params.otherParams = boxFilter.params()
                    dispatch(loadBoxesAction(params))
                    setTimer(undefined)
                }, 1000))
            } else {
                setTimer(setTimeout(() => {
                    const params = new RequestParams("")
                    params.page = new PageParams()
                    const boxFilter = new BoxFilter()
                    boxFilter.owner = c.code
                    boxFilter.from = fB
                    boxFilter.to = tB
                    params.otherParams = boxFilter.params()
                    dispatch(loadBoxesAction(params))
                    setTimer(undefined)
                }, 1000))
            }
        }
    }


    const updateMeta = () => {
        const data: any = {
            customer: { id: customer?.id },
        }
        if (fromBox) {
            data.from = fromBox
        }
        if (toBox) {
            data.to = toBox
        }
        if (metaYear) {
            data.meta_year = metaYear
        }
        if (metaDestroyYear) {
            data.meta_destroy_year = metaDestroyYear
        }
        if (metaFirstDeliveryCustomer) {
            data.meta_first_delivery_customer = metaFirstDeliveryCustomer
        }
        if (size) {
            data.size = size
        }
        dispatch(updateMetaAction(data))
    }

    const resetMeta = (action: string) => {
        const data: any = {
            customer: { id: customer?.id },
            action: action
        }
        if (fromBox) {
            data.from = fromBox
        }
        if (toBox) {
            data.to = toBox
        }
        dispatch(updateMetaAction(data))
    }

    return (
        <PageContent>
            <Card title="Metadaten Update">
                <div className="p-2">
                    <Select
                        title="Kunde"
                        data={customers}
                        renderOption={(c: Customer) => `( ${c.code} ) ${c.name}`}
                        onChangeObjectClass={c => setFilterParams(c, fromBox!, toBox!)}
                    />
                    <div className="grid grid-cols-2 pt-2 gap-x-2">
                        <Input
                            title="von"
                            type="number"
                            value={fromBox}
                            onChange={(e) => setFilterParams(customer!, e.target.value, toBox!)}
                            disabled={customer ? false : true}
                        />
                        <Input
                            title="bis"
                            type="number"
                            value={toBox}
                            onChange={(e) => setFilterParams(customer!, fromBox!, e.target.value)}
                            disabled={customer ? false : true}
                        />
                    </div>
                </div>
                <SubTitle title="Metadaten" />
                <div className="p-2">
                    <div className="flex items-end pb-2">
                        <div className="w-full">
                            <Input
                                title="Jahrgang"
                                type="number"
                                onChange={(e) => setMetaYear(e.target.value)}
                                value={metaYear}
                                disabled={customer ? false : true}
                            />
                        </div>
                        <div className="pb-2 pl-2 w-min">
                            <Button
                                onClick={() => resetMeta("reset_year")}
                                disabled={customer ? false : true}
                            >
                                reset
                            </Button>
                        </div>
                    </div>
                    <div className="flex items-end pb-2">
                        <div className="w-full">
                            <Input
                                title="Vernichtung Jahrgang"
                                type="number"
                                onChange={(e) => setMetaDestroyYear(e.target.value)}
                                value={metaDestroyYear}
                                disabled={customer ? false : true}
                            />
                        </div>
                        <div className="pb-2 pl-2 w-min">
                            <Button
                                onClick={() => resetMeta("reset_destroy_year")}
                                disabled={customer ? false : true}
                            >
                                reset
                            </Button>
                        </div>
                    </div>
                    <div className="flex items-end pb-2">
                        <div className="w-full">
                            <Input
                                title="Anlieferung"
                                type="date"
                                onChange={(e) => setMetaFirstDeliveryCustomer(e.target.value)}
                                value={metaFirstDeliveryCustomer}
                                disabled={customer ? false : true}
                            />
                        </div>
                        <div className="pb-2 pl-2 w-min">
                            <Button
                                onClick={() => resetMeta("reset_first_delivery_customer")}
                                disabled={customer ? false : true}
                            >
                                reset
                            </Button>
                        </div>
                    </div>
                    <div className="flex items-end pb-2">
                        <div className="w-full">
                            <Input
                                title="Größe (mm)"
                                type="number"
                                onChange={(e) => setSize(e.target.value)}
                                value={size}
                                disabled={customer ? false : true}
                            />
                        </div>
                    </div>
                    <div className="py-2">
                        <Button
                            onClick={() => updateMeta()}
                            disabled={customer ? false : true}
                        >
                            {customer ? boxes.totalItems : 0} Boxen updaten
                        </Button>
                    </div>
                </div>
            </Card>
        </PageContent>
    )
}