/**
* Created on Mon Apr 12 2021 12:00:38
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import Input from '../../../shared/components/inputs/Input';
import { PageContent } from '../../../shared/components/page';
import Area from '../../../shared/models/Area';
import { addAreaAction } from '../../actions/areas.actions';

export default function () {

    const initValues = {
        name: ""
    }

    const dispatch = useDispatch()

    return(
        <PageContent>
            <Card title="Bereich hinzufügen">
                <div className="p-1">
                    <Formik
                        initialValues={initValues}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false)
                            const a = new Area(
                                values.name
                            )
                            dispatch(addAreaAction(a))
                        }}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            errors,
                            touched,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <div className="p-1">
                                    <Input
                                        title="Name"
                                        type="text"
                                        name="name"
                                        controlId="create-area-name"
                                        value={values.name}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.name && !!errors.name}
                                        isValid={touched.name && !errors.name}
                                        error={errors.name}
                                        required={true}
                                    />
                                </div>
                                <div className="px-1 py-2">
                                    <Button
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Hinzufügen
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Card>
        </PageContent>
    )
}