/**
 *  Created on Mo. 18.10.2021 10:25:16
 *
 *  @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */
import {PageContent} from "../../../shared/components/page";
import {Card} from "../../../shared/components/card";
import Button from "../../../shared/components/buttons/Button";
import {FaFilePdf} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import Select from "../../../shared/components/inputs/Select";
import Customer from "../../../shared/models/Customer";
import {
    downloadBoxesOverviewAction,
    loadCustomersAction
} from "../../actions/services.actions";
import {useDispatch, useSelector} from "react-redux";
import {getCustomers} from "../../selector/archivelist.selector";
import Input from "../../../shared/components/inputs/Input";

export default function () {

    const [customer, setCustomer] = useState<Customer>()
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCustomersAction(""))
    }, [dispatch])

    const customers = useSelector(getCustomers)

    const disableDownload = () => {
        return customer ? customer.countBoxes! <= 0 : true;
    }

    const handleOnClickDownload = () => {
        const params = new URLSearchParams();
        if (from) {
            params.set('from', from)
        }
        if (to) {
            params.set('to', to)
        }
        const meta = params.toString().length > 0 ? `?${params.toString()}` : ""
        dispatch(downloadBoxesOverviewAction(customer!, meta))
    }    

    return (
        <PageContent>
            <Card title={<div className="flex items-center justify-between">
                <div>Boxen Lager Übersicht</div>
                <div>
                    <Button
                        icon={<FaFilePdf className="text-red-600"/>}
                        type="button"
                        disabled={disableDownload()}
                        onClick={() => handleOnClickDownload()}
                    >
                        download
                    </Button>
                </div>
            </div>}>
                <div className="p-2">
                    <Select
                        title="Kunde"
                        data={customers}
                        renderOption={(c: Customer) => `( ${c.code} ) ${c.name}`}
                        onChangeObjectClass={c => setCustomer(c)}
                    />
                    <div className="grid grid-cols-2 pt-2 gap-x-2">
                        <Input
                            title="von"
                            type="number"
                            step={1}
                            value={from}
                            onChange={(e) => setFrom(e.target.value)}
                        />
                        <Input
                            title="bis"
                            type="number"
                            step={1}
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                        />
                    </div>
                </div>
            </Card>
        </PageContent>
    )
}