/**
* Created on Fri Mar 05 2021 12:08:23
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import NotStaff from '../../../../shared/components/NotStaff';
import Staff from '../../../../shared/components/Staff';
import Box from '../../../../shared/models/Box';
import ContentsCustomers from './ContentsCustomers';
import ContentsStaff from './ContentsStaff';

interface Props {
    box: Box
}

export default function BoxContents({
    box
}: Props) {

    return(
        <div>
            <Staff>
                <ContentsStaff box={box}/>
            </Staff>
            <NotStaff>
                <ContentsCustomers box={box}/>
            </NotStaff>
        </div>
    )
}
//<ContentsCustomers box={box}/>