/**
* Created on Sat Jan 23 2021 18:05:37
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { PropsWithChildren } from 'react';
import LoadingCircle from '../LoadingCircle';

interface Props {
    loading?: boolean
}

export default function PageContent({
    children,
    loading
}: PropsWithChildren<Props>) {

    return (
        <div className="m-6">
            { loading !== undefined && loading ?
                <div className="relative w-full h-20">
                    <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                        <LoadingCircle />
                    </div>
                </div>
                :
                children
            }
        </div>
    )
}
