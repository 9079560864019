/**
* Created on Mon Apr 12 2021 13:22:10
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import Image from '../../../shared/components/Image';
import Checkbox from '../../../shared/components/inputs/Checkbox';
import YesNo from '../../../shared/components/overlays/YesNo';
import { PageContent } from '../../../shared/components/page';
import Row from '../../../shared/models/Row';
import { deleteRowAction, loadRowAction, updateRowAction } from '../../actions/row.actions';
import { getRow, loadedRow } from '../../selectors/row.selectors';

interface RouterProps {
    id: string;
}

interface RowProps extends RouteComponentProps<RouterProps> { }

export default function ({
    match
}: RowProps) {

    const id = parseInt(match.params.id)
    const dispatch = useDispatch();
    const [showYesNoDelete, setShowYesNoDelete] = useState(false);
    useEffect(() => {
        dispatch(loadRowAction(id))
    }, [dispatch, id])

    const loaded = useSelector(loadedRow)
    const row = useSelector(getRow)

    const updateRow = (reverse: boolean) => {
        const r =  new Row(
            row.number,
            reverse
        )
        r.id = row.id
        dispatch(updateRowAction(r))
    }

    return (
        <div>
            <YesNo
                title="Löschen"
                text={`Reiche ${row?.number} aus Bereich ${row?.area?.name} wirklich löschen?`}
                type="danger"
                open={showYesNoDelete}
                onClose={() => setShowYesNoDelete(false)}
                onYes={() => dispatch(deleteRowAction(row!))}
                onNo={() => setShowYesNoDelete(false)}
            />
            <PageContent loading={!loaded}>
                <Card title={
                    <div className="flex items-center justify-between">
                        <div>
                        Bereich {row?.area?.name} Reihe {row?.number}
                        </div>
                        {row?.boxesCount === 0 &&
                            <div>
                                <Button
                                    icon={<FaTrashAlt />}
                                    color="danger"
                                    type="button"
                                    onClick={() => setShowYesNoDelete(true)}
                                >
                                    Reihe löschen
                            </Button>
                            </div>
                        }
                    </div>
                }>
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-full m-2 lg:w-1/2">
                            <div className="grid grid-cols-2 gap-x-2">
                                <div className="font-semibold text-gray-700">Bereich:</div>
                                <div>{row?.area?.name}</div>
                                <div className="font-semibold text-gray-700">Nummer:</div>
                                <div>{row?.number}</div>
                                <div className="font-semibold text-gray-700">Anzahl Boxen:</div>
                                <div>{row?.boxesCount}</div>
                                <div className="font-semibold text-gray-700">Rückwärts:</div>
                                <Checkbox 
                                    value={row?.reverse}
                                    onChange={(reverse: boolean) => updateRow(reverse)}
                                />
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2">
                            <div className="max-w-xs">
                                <Image url={row?.qrCode?.image!} />
                            </div>
                        </div>
                    </div>
                </Card>
            </PageContent >
        </div >
    )
}