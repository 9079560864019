/**
 * Created on Sat Apr 17 2021 11:05:02
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import React, {useEffect} from 'react';
import {FaFileDownload, FaTimes} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import {Card, SubTitle} from '../../../shared/components/card';
import {PageContent} from '../../../shared/components/page';
import Box from '../../../shared/models/Box';
import BoxContent from '../../../shared/models/BoxContent';
import {formatBoxCode } from '../../../shared/utils';
import {downloadDeliveryNoteAction, loadDeliveryAction, cancelDeliveryAction} from '../../actions/delivery.actions';
import {getDelivery, loadedDelivery} from '../../selectors/delivery.selectors';
import {history} from "../../../store/history";
import DeliveryState from "../../../shared/models/DeliveryState";
import Staff from "../../../shared/components/Staff";
import PriceChange from "./PriceChange";
import DateChange from "./DateChange";

interface RouterProps {
    id: string;
}

interface DeliveryProps extends RouteComponentProps<RouterProps> {
}

export default function ({
                             match
                         }: DeliveryProps) {

    const id = parseInt(match.params.id)
    const delivery = useSelector(getDelivery)
    const loaded = useSelector(loadedDelivery)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadDeliveryAction(id))
    }, [dispatch, id])

    return (
        <div>
            <PageContent loading={!loaded}>
                <Card title={
                    <div className="flex items-center justify-between">
                        <div>
                            Lieferung {delivery?.code} ( {delivery?.kind?.text} )
                        </div>
                        {delivery?.kind?.key !== 'RETURN' &&
                        <div className="flex gap-x-2">
                            {delivery?.state?.key === 'OPEN' &&
                            <Button
                                color="danger"
                                icon={<FaTimes/>}
                                onClick={() => {
                                    const d = delivery
                                    d!.state = DeliveryState.fromKey("CANCELED")
                                    dispatch(cancelDeliveryAction(d!))
                                }}
                            >
                                Storniern
                            </Button>
                            }
                            <Staff>
                                <Button
                                    icon={<FaFileDownload/>}
                                    onClick={() => dispatch(downloadDeliveryNoteAction(delivery!))}
                                >
                                    Download
                                </Button>
                            </Staff>
                        </div>
                        }
                    </div>
                }>
                    <div className="p-2">
                        <div className="w-full">
                            <div className="grid grid-cols-3 gap-x-2">
                                <div className="font-semibold text-gray-700">Status:</div>
                                <div className="col-span-2">
                                    <strong>
                                        {delivery?.state?.text}
                                    </strong>
                                </div>
                                <div className="font-semibold text-gray-700">Kunde:</div>
                                <div className="col-span-2">
                                    ( {delivery?.customer.code} ) {delivery?.customer.name}
                                </div>
                                <div className="font-semibold text-gray-700">Datum:</div>
                                <div className="col-span-2">
                                    <DateChange delivery={delivery!}/>
                                </div>
                                <div className="font-semibold text-gray-700">Preis:</div>
                                <div className="col-span-2">
                                    <PriceChange delivery={delivery!}/>
                                </div>
                                {delivery?.comment &&
                                <>
                                    <div className="font-semibold text-gray-700 mt-2">Kommentar:</div>
                                    <div className="col-span-2 whitespace-pre mt-2">
                                        {delivery?.comment}
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    {delivery?.boxes.length !== 0 && <>
                        <SubTitle title={`Boxen ( ${delivery?.boxes.length} )`}/>
                        <div className="p-2">
                            <ul>
                                {delivery?.boxes.map((b: Box) => (
                                    <li key={`delivery-box-${b.id}`}>
                                        <span className="hover:underline cursor-pointer"
                                              onClick={() => history.push(`/stock/boxes/${b.id}`)}> {b.formatCode()} </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                    }
                    {delivery?.boxcontents.length !== 0 && <>
                        <SubTitle title={`Boxeninhalte ( ${delivery?.boxcontents.length} )`}/>
                        <div className="p-2">
                            <ul>
                                {delivery?.boxcontents.map((c: BoxContent) => (
                                    <li key={`delivery-boxcontent-${c.id}`}>
                                        ( <span onClick={() => history.push(`/stock/boxes/${c.box}`)}
                                                className="hover:underline cursor-pointer">
                                        {formatBoxCode(c.boxCode!)}</span> ) {c.text}
                                        {c.state.key === 'NON_EXISTENT' &&
                                        <span className="ml-2 italic text-gray-500">Inhalt nicht vorhanden</span>}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                    }
                </Card>
            </PageContent>
        </div>
    )
}