/**
* Created on Wed Mar 17 2021 13:18:50
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import Select from '../../../shared/components/inputs/Select';
import Customer from '../../../shared/models/Customer';
import DeliveryOption from '../../../shared/models/DeliveryOption';
import { formatDate } from '../../../shared/utils';
import { setDeliveryOptionAction } from '../../actions/delieveries.actions';

interface Props {
    nextStep: () => void;
    customer?: Customer;
    deliveryoption?: DeliveryOption;
}

export default function OptionSelect({
    nextStep,
    customer,
    deliveryoption
}: Props) {

    const dispatch = useDispatch()
    const location = useLocation()

    if (!customer) {
        return (
            <Redirect to={location.pathname} />
        )
    }

    if (customer!.deliveryoptions!.length === 0) {
        return (
            <div className="w-full px-2 py-4 text-lg font-semibold text-red-500">
                Es kann keine Lieferung für <span className="font-bold">{customer.name}</span> erstellt werden, da keine Zustellungsoptionen zur Verfügung stehen!
            </div>
        )
    }

    return (
        <div className="px-2 py-4">
            <Select
                title="Zustellungsoption"
                data={customer!.deliveryoptions!}
                value={deliveryoption}
                renderOption={(o) => `${o.description} - ( ${o.price} € ) `}
                showKey={"description"}
                onChangeObjectClass={(d) => dispatch(setDeliveryOptionAction(d))}
            />
            <div className="py-2">
                <div className="font-semibold text-gray-700">
                    Zustellung bis:
                </div>
                <div className="font-bold">
                    {deliveryoption &&
                        formatDate(DeliveryOption.nextDeliveryDate(deliveryoption))
                    }
                </div>
            </div>
            <div className="pt-2">
                <Button onClick={nextStep} disabled={deliveryoption === undefined}>
                    <div className="flex items-center">
                        <div>
                            Boxen und Inhalte auswählen
                        </div>
                        <div className="ml-2">
                            <FaArrowRight />
                        </div>
                    </div>
                </Button>
            </div>
        </div>
    )
}