/**
* Created on Sun Apr 18 2021 16:24:25
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Box from "../../shared/models/Box";
import Customer from "../../shared/models/Customer";
import Pagination from "../../shared/models/Pagination";

export function getCustomers(state: AppState): Customer[] {
    return state.services.metaUpdate.customers
}

export function getBoxes(state: AppState): Pagination<Box> {
    return state.services.metaUpdate.boxes
}