/**
* Created on Fri Apr 09 2021 12:23:00
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Area from "../../shared/models/Area";
import Pagination from "../../shared/models/Pagination";
import Row from "../../shared/models/Row";

export function getRows(state: AppState): Pagination<Row> {
    return state.stock.rows.data;
}

export function loadedRows(state: AppState): boolean {
    return state.stock.rows.load.loaded;
}

export function loadingRows(state: AppState): boolean {
    return state.stock.rows.load.loading;
}

export function possibleAreas(state: AppState): Area[] {
    return state.stock.rows.possibleAreas;
}