

export default class StockOverview {

    constructor(
        public boxContentsCount: number = 0,
        public boxesCreatedCount: number = 0,
        public boxesDestroyedCount: number = 0,
        public boxesInStockCount: number = 0,
        public boxesInconsistentCount: number = 0,
        public boxesNotInStockCount: number = 0,
        public boxesOrderedCount: number = 0,
        public boxesRetourCount: number = 0,
    ) { }

    static fromAPI(model?: any): StockOverview {
        const overview = new StockOverview(
            model.boxcontents_count,
            model.boxes_created_count,
            model.boxes_destroyed_count,
            model.boxes_in_stock_count,
            model.boxes_inconsistent_count,
            model.boxes_not_in_stock_count,
            model.boxes_ordered_count,
            model.boxes_retour_count,
        )
        return overview
    }

}