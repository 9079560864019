/**
* Created on Wed Feb 24 2021 09:52:23
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import User from "./User"

export default class Token {

    public user?: User
    constructor(
        public token: string,
        public userId: number
    ){}

    static fromAPI(model?: any): Token {
        const token = new Token(
            model.token,
            model.user.id
        )
        token.user = User.fromAPI(model.user)
        return token
    }
    
    static fromStorage(): Token | undefined {
        const token = localStorage.getItem("token")
        const userId = localStorage.getItem("id")
        if (token && userId){
            return new Token(
                token,
                parseInt(userId)
            )
        }
        return undefined
    }

    static clear() {
        localStorage.removeItem("token")
        localStorage.removeItem("id")
    }

    public toStorage() {
        localStorage.setItem("token", this.token)
        localStorage.setItem("id", this.userId.toString())
    }

}