/**
* Created on Wed Feb 10 2021 14:13:43
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import BoxState from "./BoxState"

export default class BoxFilter {

    public owner?: string
    public from?: number
    public to?: number
    public state?: BoxState
    public currentParams?: string = ""

    static fromEvent(filter: BoxFilter, event: React.FormEvent<HTMLInputElement>): BoxFilter {
        const newFilter = Object.assign(filter)
        if (event.currentTarget.value.length > 0){
            newFilter[event.currentTarget.name] = JSON.parse(event.currentTarget.value)
        } else {
            newFilter[event.currentTarget.name] = event.currentTarget.value
        }
        return newFilter
    }

    public fromParams(params: string): BoxFilter {
        const p = new URLSearchParams(params);
        this.owner = p.get('owner') && p.get('owner') !== "" ?  p.get('owner')! : undefined
        this.from = p.get('from') ?  parseInt(p.get('from')!) : undefined
        this.to = p.get('to') ? parseInt(p.get('to')!) : undefined
        this.state = p.get('state') ? BoxState.fromNumber(parseInt(p.get('state')!)) : undefined
        this.currentParams = p.toString()
        return this
    }

    public toParams(other?: string): string{
        const params = new URLSearchParams(this.currentParams);
        if(this.owner){
            params.set('owner', this.owner!)
        } else{
            params.delete('owner')
        }
        if(this.from){
            params.set('from', this.from.toString())
        } else {
            params.delete('from')
        }
        if(this.to){
            params.set('to', this.to.toString())
        } else {
            params.delete("to")
        }
        if(this.state){
            params.set('state', this.state.num.toString())
        } else {
            params.delete('state')
        }
        params.set('page', "1")
        this.currentParams = params.toString()
        return other? other+"&"+params.toString() : "?"+params.toString();
    }

    public params(other?: string): string {
        const params = new URLSearchParams();
        if(this.owner){
            params.set('owner', this.owner)
        }
        if(this.from){
            params.set('min_number', this.from.toString())
        }
        if(this.to){
            params.set('max_number', this.to.toString())
        }
        if(this.state){
            params.set('state', this.state.num.toString())
        }

        if (params.toString() === ""){
            return other? other : ""
        }
        return other? other+"&"+params.toString() : "?"+params.toString();
    }

}