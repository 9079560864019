/**
* Created on Fri Mar 19 2021 14:53:18
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../shared/components/buttons/Button';
import Input from '../../../../shared/components/inputs/Input';
import Box from '../../../../shared/models/Box';
import BoxContent from '../../../../shared/models/BoxContent';
import BoxContentState from '../../../../shared/models/BoxContentState';
import { addContentAction } from '../../../actions/box.actions';

interface Props {
    box: Box
}

export default function ContentForm({
    box
}: Props) {

    const [content, setContent] = useState("")
    const dispatch = useDispatch()
    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const c = new BoxContent(
            content,
            new BoxContentState("INSIDE")
        )
        c.box = box.id
        dispatch(addContentAction(c))
        setContent("")
    }
    return (
        <form onSubmit={handleOnSubmit}>
            <Input 
                title="Inhalt hinzufügen"
                onChange={e => setContent(e.target.value)}
                value={content}
            />
            <div className="mt-2">
                <Button
                    disabled={content === ""}
                    type="submit"
                >
                    Hinzufügen
                </Button>
            </div>
        </form>
    )
}