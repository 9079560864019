/**
* Created on Wed Feb 24 2021 19:43:13
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

export interface FilterObject {
    [key: string]: string
}

export default class Filter{

    constructor(
        public filter: FilterObject,
        public keys: string[],
    ) {}
    
    public params(other?: string): string {
        let params = new URLSearchParams(other);
        params.delete('page')
        this.keys.forEach(key => {
            if (this.filter[key] !== undefined && this.filter[key].length > 0){
                params.delete(key)
                params.set(key, this.filter[key])
            } else {
                params.delete(key)
            }
        })

        return params.toString();
    }

}