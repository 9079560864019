/**
* Created on Fri Feb 26 2021 09:29:10
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { combineEpics, Epic, ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import api from "../../api";
import { from } from "rxjs";
import axios from "axios";
import { token } from "../../shared/utils";
import Pagination from "../../shared/models/Pagination";
import { handleRequestError } from "../../shared/utils/errors";
import Customer from "../../shared/models/Customer";
import {
    addCustomerSuccessAction,
    addDeliveryOptionSuccessAction,
    ADD_CUSTOMER,
    ADD_CUSTOMER_SUCCESS,
    ADD_DELIVERY_OPTION,
    ADD_DELIVERY_OPTION_SUCCESS,
    deleteCustomerSuccessAction,
    deleteDeliveryOptionSuccessAction,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_DELIVERY_OPTION,
    DELETE_DELIVERY_OPTION_SUCCESS,
    loadCustomersSuccessAction,
    loadCustomerSuccessAction,
    loadUsersSuccessAction,
    LOAD_CUSTOMER,
    LOAD_CUSTOMERS,
    LOAD_USERS,
    updateCustomerSuccessAction,
    updateDeliveryOptionSuccessAction,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_DELIVERY_OPTION,
    UPDATE_DELIVERY_OPTION_SUCCESS,
} from "../actions/customers.actions";
import { push } from "connected-react-router";
import User from "../../shared/models/User";
import { notifySuccessAction } from "../../shared/actions/notify.actions";
import Notification from "../../shared/models/Notification";
import DeliveryOption from "../../shared/models/DeliveryOption";


const loadCustomers: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_CUSTOMERS),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/customers/${payload.params()}`
            return from(axios.get<Customer[]>(url, token(state$))).pipe(
                map(({ data }) => loadCustomersSuccessAction(Pagination.fromAPI(data, Customer.fromAPI))),
                catchError(handleRequestError)
            )
        })
    )

const addCustomer: Epic = (action$, state$) =>
    action$.pipe(
        ofType(ADD_CUSTOMER),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/customers/`
            return from(axios.post<Customer>(url, Customer.toApi(payload), token(state$))).pipe(
                map(({ data }) => addCustomerSuccessAction(Customer.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const updateCustomer: Epic = (action$, state$) =>
    action$.pipe(
        ofType(UPDATE_CUSTOMER),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/customers/${payload.id}/`
            return from(axios.patch<Customer>(url, Customer.toApi(payload), token(state$))).pipe(
                map(({ data }) => updateCustomerSuccessAction(Customer.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const deleteCustomer: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DELETE_CUSTOMER),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/customers/${payload.id}/`
            return from(axios.delete<Customer>(url, token(state$))).pipe(
                map(() => deleteCustomerSuccessAction()),
                catchError(handleRequestError)
            )
        })
    )

const redirectToCustomers: Epic = action$ =>
    action$.pipe(
        ofType(ADD_CUSTOMER_SUCCESS, DELETE_CUSTOMER_SUCCESS),
        map(() => {
            return push('/customers')
        })
    )

const successAddCustomer: Epic = action$ =>
    action$.pipe(
        ofType(ADD_CUSTOMER_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Kunde hinzugefügt.`)))
    )


const successUpdateCustomer: Epic = action$ =>
    action$.pipe(
        ofType(UPDATE_CUSTOMER_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Kunde aktualisiert.`)))
    )

const successDeleteCustomer: Epic = action$ =>
    action$.pipe(
        ofType(DELETE_CUSTOMER_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Kunde gelöscht.`)))
    )

const loadCustomer: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_CUSTOMER),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/customers/${payload}/`
            return from(axios.get<Customer>(url, token(state$))).pipe(
                map(({ data }) => loadCustomerSuccessAction(Customer.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const loadUsers: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_USERS),
        switchMap(() => {
            const url = `${api.BASE_URL_V1}/users/?is_staff=False`
            return from(axios.get<User[]>(url, token(state$))).pipe(
                map(({ data }) => loadUsersSuccessAction(data.map((u: User) => User.fromAPI(u)))),
                catchError(handleRequestError)
            )
        })
    )

const addDeliveryOption: Epic = (action$, state$) =>
    action$.pipe(
        ofType(ADD_DELIVERY_OPTION),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/deliveries/options/`
            return from(axios.post<DeliveryOption>(url, DeliveryOption.toAPI(payload), token(state$))).pipe(
                map(({ data }) => addDeliveryOptionSuccessAction(DeliveryOption.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const successAddDeliveryOption: Epic = action$ =>
    action$.pipe(
        ofType(ADD_DELIVERY_OPTION_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Lieferoption hinzugefügt.`)))
    )

const updateDeliveryOption: Epic = (action$, state$) =>
    action$.pipe(
        ofType(UPDATE_DELIVERY_OPTION),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/deliveries/options/${payload.id}/`
            return from(axios.patch<DeliveryOption>(url, DeliveryOption.toAPI(payload), token(state$))).pipe(
                map(({ data }) => updateDeliveryOptionSuccessAction(DeliveryOption.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

const successUpdateDeliveryOption: Epic = action$ =>
    action$.pipe(
        ofType(UPDATE_DELIVERY_OPTION_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Lieferoption aktualisiert.`)))
    )

const deleteDeliveryOption: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DELETE_DELIVERY_OPTION),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/deliveries/options/${payload.id}/`
            return from(axios.delete<DeliveryOption>(url, token(state$))).pipe(
                map(({ data }) => deleteDeliveryOptionSuccessAction(payload.id)),
                catchError(handleRequestError)
            )
        })
    )

const successDeleteDeliveryOption: Epic = action$ =>
    action$.pipe(
        ofType(DELETE_DELIVERY_OPTION_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Lieferoption gelöscht.`)))
    )

export default combineEpics(
    loadCustomers,
    addCustomer,
    redirectToCustomers,
    loadCustomer,
    loadUsers,
    updateCustomer,
    successUpdateCustomer,
    deleteCustomer,
    successDeleteCustomer,
    successAddCustomer,
    addDeliveryOption,
    successAddDeliveryOption,
    updateDeliveryOption,
    successUpdateDeliveryOption,
    deleteDeliveryOption,
    successDeleteDeliveryOption
)