/**
* Created on Thu Mar 11 2021 09:21:22
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer"
import Box from "../../shared/models/Box"
import BoxContent from "../../shared/models/BoxContent"
import Customer from "../../shared/models/Customer"
import DeliveryOption from "../../shared/models/DeliveryOption"


export function getBoxes(state: AppState): Box[] {
    return state.deliveries.create.boxes
}

export function getBoxContents(state: AppState): BoxContent[] {
    return state.deliveries.create.boxcontents
}

export function getCustomer(state: AppState): Customer {
    return state.deliveries.create.customer!
}

export function getDeliveryoption(state: AppState): DeliveryOption {
    return state.deliveries.create.deliveryoption!
}