import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    gender: Yup.object().required("Bitte auswählen"),
    firstName: Yup.string().required("Bitte ausfüllen"),
    lastName: Yup.string().required("Bitte ausfüllen"),
    email: Yup.string().email("Ungültige E-Mail Adresse").required("Bitte ausfüllen"),
    phone1: Yup.string().required("Bitte ausfüllen"),
})

export default validationSchema;