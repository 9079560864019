import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    description: Yup.string().required("Bitte ausfüllen"),
    days: Yup.array().min(1, "Bitte einen Tag auswählen").required("Bitte auswählen"),
    from: Yup.string().required("Bitte ausfüllen"),
    to: Yup.string().required("Bitte ausfüllen"),
    prior: Yup.number().min(0).required("Bitte ausfüllen"),
    window: Yup.number().min(0).required("Bitte ausfüllen"),
    price: Yup.number().min(0).required("Bitte ausfüllen")
})

export default validationSchema;