/**
* Created on Fri Apr 16 2021 18:37:26
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import Box from '../../../shared/models/Box';
import { formatDate } from '../../../shared/utils';

interface Props {
    box: Box
}

export default function MetaData({
    box
}: Props) {

    return (
        <div className="grid grid-cols-2 gap-x-2">
            { box.metaYear && <>
                <div className="font-semibold text-gray-700">Jahrgang:</div>
                <div>
                    {box.metaYear!}
                </div>
            </>
            }
            { box.metaDestroyYear && <>
                <div className="font-semibold text-gray-700">Vernichtung Jahrgang:</div>
                <div>
                    {box.metaDestroyYear!}
                </div>
            </>
            }
            { box.metaFirstDeliveryCustomer && <>
                <div className="font-semibold text-gray-700">Anlieferung:</div>
                <div>
                    {formatDate(box.metaFirstDeliveryCustomer!, "DD.MM.YYYY")}
                </div>
            </>
            }
        </div>
    )
}