import { combineEpics, Epic, ofType } from "redux-observable";
import { switchMap, map, catchError } from 'rxjs/operators';
import { from, Observable, of } from "rxjs";
import axios, { AxiosError } from "axios";
import api from "../../api";
import { ActionType } from "typesafe-actions";
import {
    LOGIN,
    LOGIN_SUCCESS,
    errorAction,
    loginFailureAction,
    loginSuccessAction,
} from "../actions/login.actions";
import { push } from "connected-react-router";
import Token from "../../shared/models/Token";
import { AUTH_SUCCESS } from "../../shared/actions/auth.actions";
import { notifyErrorAction } from "../../shared/actions/notify.actions";
import Notification from "../../shared/models/Notification";


function handleLoginError(
    err: AxiosError
): Observable<
    ActionType<
        | typeof loginFailureAction
        | typeof notifyErrorAction
        | typeof errorAction
    >
> {

    return of(notifyErrorAction(new Notification("Login fehlgeschlagen.")))
    //return of(errorAction("Login fehlgeschlagen"))
}

const login: Epic = action$ =>
    action$.pipe(
        ofType(LOGIN),
        switchMap(({ payload }) =>
            from(axios.post(`${api.BASE_URL}/api-token-auth/`, payload)).pipe(
                map(({ data: user }) => loginSuccessAction(Token.fromAPI(user))),
                catchError(handleLoginError)
            )
        )
)

const redirectToFrom: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOGIN_SUCCESS, AUTH_SUCCESS),
        map(() => {
            if (state$.value.router.location.state && state$.value.router.location.state !== "/login") {
                return push(state$.value.router.location.state)
            }
            if (state$.value.router.location && state$.value.router.location.pathname !== "/login") {
                return push(state$.value.router.location.pathname + state$.value.router.location.search)
            }
            return push("/")
        })
    )


export default combineEpics(
    login,
    redirectToFrom,
);