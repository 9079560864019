/**
* Created on Fri Apr 09 2021 11:52:12
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Area from '../../shared/models/Area';
import Pagination from "../../shared/models/Pagination";
import { LoadState } from "../../shared/models/RequestState";
import Row from "../../shared/models/Row";
import { loadRowsAction, loadRowsSuccessAction, LOAD_ROWS_SUCCESS, LOAD_ROWS, loadAreasSuccessAction, LOAD_AREAS_SUCCESS } from "../actions/rows.actions";

export interface State {
    data: Pagination<Row>,
    load: LoadState,
    possibleAreas: Area[],
}

export default function(
    state: State = {
        data: new Pagination<Row>(),
        possibleAreas: [],
        load: LoadState.init()
    },
    action: ActionType<
        | typeof loadRowsAction
        | typeof loadRowsSuccessAction
        | typeof loadAreasSuccessAction
    >
): State {
    switch (action.type) {
        case LOAD_ROWS:
            return update(state, {
                load: { $set: LoadState.loading() }
            })

        case LOAD_ROWS_SUCCESS:
            return update(state, {
                data: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        case LOAD_AREAS_SUCCESS:
            return update(state, {
                possibleAreas: { $set: action.payload }
            })
        default:
            return state
    }
}