/**
* Created on Fri Apr 09 2021 08:50:36
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/


import { ActionType, createAction } from "typesafe-actions";
import Area from "../../shared/models/Area";
import Pagination from "../../shared/models/Pagination";
import RequestParams from "../../shared/models/RequestParams";
import Row from "../../shared/models/Row";

export const LOAD_ROWS = '@@rows/LOAD_ROWS';
export type LOAD_ROWS = typeof LOAD_ROWS;

export const LOAD_ROWS_SUCCESS = '@@rows/LOAD_ROWS_SUCCESS';
export type LOAD_ROWS_SUCCESS = typeof LOAD_ROWS_SUCCESS;

export const LOAD_AREAS = '@@rows/LOAD_AREAS';
export type LOAD_AREAS = typeof LOAD_AREAS;

export const LOAD_AREAS_SUCCESS = '@@rows/LOAD_AREAS_SUCCESS';
export type LOAD_AREAS_SUCCESS = typeof LOAD_AREAS_SUCCESS;

export const ADD_ROW = '@@rows/ADD_ROW';
export type ADD_ROW = typeof ADD_ROW;

export const ADD_ROW_SUCCESS = '@@rows/ADD_ROW_SUCCESS';
export type ADD_ROW_SUCCESS = typeof ADD_ROW_SUCCESS;

export const loadRowsAction = createAction(LOAD_ROWS)<RequestParams>();
export const loadRowsSuccessAction = createAction(LOAD_ROWS_SUCCESS)<Pagination<Row>>();

export const loadAreasAction = createAction(LOAD_AREAS)<void>();
export const loadAreasSuccessAction = createAction(LOAD_AREAS_SUCCESS)<Area[]>()

export const addRowAction = createAction(ADD_ROW)<Row>();
export const addRowSuccessAction = createAction(ADD_ROW_SUCCESS)<Row>();

export type RowsAction = ActionType<
    | typeof loadRowsAction
    | typeof loadRowsSuccessAction
    | typeof loadAreasAction
    | typeof loadAreasSuccessAction
    | typeof addRowAction
    | typeof addRowSuccessAction
>;