/**
* Created on Sun Apr 18 2021 09:31:40
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

export default class LabelPrint {
    constructor(
        public code: string,
        public amount: number,
    ) {}
}