/**
* Created on Sat Apr 17 2021 20:59:39
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Box from "../../shared/models/Box";
import LabelPrint from "../../shared/models/LabelPrint";
import Pagination from "../../shared/models/Pagination";


export function getBoxes(state: AppState): Pagination<Box> {
    return state.services.labelPrint.boxes
}

export function loadingBoxes(state: AppState): boolean {
    return state.services.labelPrint.load.loading
}

export function getLabelPrints(state: AppState): LabelPrint[] {
    return state.services.labelPrint.create
}