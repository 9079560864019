/**
* Created on Thu Feb 04 2021 09:50:47
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect } from 'react';
import { PageContent } from '../../../shared/components/page';
import { FaArrowRight, FaCubes } from 'react-icons/fa';
import { history } from '../../../store/history';
import { useDispatch, useSelector } from 'react-redux';
import { loadOverviewAction } from '../../actions/overview.actions';
import { getOverview } from '../../selectors/overview.selector';
import Staff from '../../../shared/components/Staff';

export default function () {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadOverviewAction())
    }, [dispatch])

    const overview = useSelector(getOverview)

    return (
        <PageContent>
            <div className="flex flex-col items-center py-16">
                <FaCubes className="w-16 h-16 text-yellow-600" />
                <div className="text-2xl font-bold text-gray-600">
                    Lager Überblick
            </div>
            </div>
            <div>
                <ul className="mx-20">
                    <li className="grid items-center grid-cols-4 pb-5 hover:underline">
                        <div className="col-span-2 pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/stock/boxes?state=1")}>
                            Eingelagerte Boxen
                        </div>
                        <div className="text-lg font-semibold cursor-pointer" onClick={() => history.push("/stock/boxes?state=1")}>
                            {overview.boxesInStockCount}
                        </div>
                        <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer" onClick={() => history.push("/stock/boxes?state=1")} />
                    </li>
                    <Staff>
                        <li className="grid items-center grid-cols-4 pb-5">
                            <div className="col-span-2 pr-6 text-xl font-semibold text-gray-600">
                                registrierte Boxinhalte
                            </div>
                            <div className="text-lg font-semibold">
                                {overview.boxContentsCount}
                            </div>
                        </li>
                    </Staff>
                    <li className="grid items-center grid-cols-4 pb-5 hover:underline">
                        <div className="col-span-2 pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/stock/boxes?state=2")}>
                            Nicht eingelagerte - / außer Haus Boxen
                        </div>
                        <div className="text-lg font-semibold cursor-pointer" onClick={() => history.push("/stock/boxes?state=2")}>
                            {overview.boxesNotInStockCount}
                        </div>
                        <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer " onClick={() => history.push("/stock/boxes?state=2")} />
                    </li>
                    <li className="grid items-center grid-cols-4 pb-5 hover:underline">
                        <div className="col-span-2 pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/stock/boxes?state=4")}>
                            Angeforderte Boxen
                        </div>
                        <div className="text-lg font-semibold cursor-pointer" onClick={() => history.push("/stock/boxes?state=4")}>
                            {overview.boxesOrderedCount}
                        </div>
                        <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer " onClick={() => history.push("/stock/boxes?state=4")} />
                    </li>
                    <li className="grid items-center grid-cols-4 pb-5 hover:underline">
                        <div className="col-span-2 pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/stock/boxes?state=5")}>
                            Zurückgekommene Boxen
                        </div>
                        <div className="text-lg font-semibold cursor-pointer" onClick={() => history.push("/stock/boxes?state=5")}>
                            {overview.boxesRetourCount}
                        </div>
                        <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer " onClick={() => history.push("/stock/boxes?state=5")} />
                    </li>
                    <Staff>
                        <li className="grid items-center grid-cols-4 pb-5 hover:underline">
                            <div className="col-span-2 pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/stock/boxes?state=0")}>
                                Erstellte Boxen
                        </div>
                            <div className="text-lg font-semibold cursor-pointer" onClick={() => history.push("/stock/boxes?state=0")}>
                                {overview.boxesCreatedCount}
                            </div>
                            <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer " onClick={() => history.push("/stock/boxes?state=0")} />
                        </li>
                    </Staff>
                    <Staff>
                        <li className="grid items-center grid-cols-4 pb-5 hover:underline">
                        <div className="col-span-2 pr-6 text-xl font-semibold text-gray-600 cursor-pointer" onClick={() => history.push("/stock/boxes?state=6")} >
                            Vernichtete Boxen
                        </div>
                        <div className="text-lg font-semibold cursor-pointer" onClick={() => history.push("/stock/boxes?state=6")}>
                            {overview.boxesDestroyedCount}
                        </div>
                        <FaArrowRight className="w-6 h-6 text-gray-600 cursor-pointer " onClick={() => history.push("/stock/boxes?state=6")} />
                    </li>
                    </Staff>

                </ul>
            </div>
        </PageContent >
    )
}