/**
* Created on Tue Mar 30 2021 15:25:11
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { v4 as uuidv4 } from 'uuid'

export default class Notification {
    constructor(
        public msg: string,
        public uuid: string = uuidv4(),
        public type: "INFO" | "ERROR" | "WARING" | "SUCCESS" = "INFO"
    ) {}
}