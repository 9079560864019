import axios from "axios";
import { combineEpics, Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import api from "../../api";
import Area from "../../shared/models/Area";
import { token } from "../../shared/utils";
import { handleRequestError } from "../../shared/utils/errors";
import { loadAreaSuccessAction, LOAD_AREA } from "../actions/area.actions";

const loadArea: Epic = (action$, state$) => 
    action$.pipe(
        ofType(LOAD_AREA),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/areas/${payload}/`
            return from(axios.get<Area>(url, token(state$))).pipe(
                map(({ data }) => loadAreaSuccessAction( Area.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )

export default combineEpics(
    loadArea
);