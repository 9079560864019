/**
* Created on (Thu) 21.04.2022 17:10:16
*
* @author Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import Select from '../../../shared/components/inputs/Select';
import { PageContent } from '../../../shared/components/page';
import Customer from '../../../shared/models/Customer';
import { 
    downloadDestroyRequestAction,
    loadCustomersAction
} from '../../actions/services.actions';
import { getCustomers } from '../../selector/archivelist.selector';

export default function () {

    const [customer, setCustomer] = useState<Customer>()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCustomersAction(""))
    }, [dispatch])

    const customers = useSelector(getCustomers)

    const handleOnClickPdf = () => {
        dispatch(downloadDestroyRequestAction(customer!))
    }

    return (
        <PageContent>
            <Card title={<div className="flex items-center justify-between">
                <div>
                    Vernichtungsanfrage
                </div>
                <div className="flex gap-x-2">
                    <Button
                        icon={<FaFilePdf className="text-red-600" />}
                        type="button"
                    onClick={() => handleOnClickPdf()}
                    disabled={!customer}
                    >
                        PDF
                    </Button>
                </div>
            </div>}>
                <div className="p-2">
                    <Select
                        title="Kunde"
                        data={customers}
                        renderOption={(c: Customer) => `( ${c.code} ) ${c.name}`}
                        onChangeObjectClass={c => setCustomer(c)}
                    />
                </div>
            </Card>
        </PageContent>
    )
}