/**
* Created on Mon Apr 12 2021 13:35:02
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/


import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import { LoadState } from "../../shared/models/RequestState";
import Row from "../../shared/models/Row";
import { loadRowAction, loadRowSuccessAction, LOAD_ROW, LOAD_ROW_SUCCESS, updateRowSuccessAction, UPDATE_ROW_SUCCESS } from "../actions/row.actions";

export interface State {
    data: Row | undefined,
    load: LoadState
}

export default function(
    state: State = {
        data: undefined,
        load: LoadState.init()
    },
    action: ActionType<
        | typeof loadRowSuccessAction
        | typeof updateRowSuccessAction
        | typeof loadRowAction
    >
): State {
    switch (action.type) {
        case LOAD_ROW:
            return update( state, {
                load: { $set: LoadState.loading() }
            } )
        case LOAD_ROW_SUCCESS:
            return update(state, {
                data: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        case UPDATE_ROW_SUCCESS:
            return update( state, {
                data: { $set: action.payload }
            } )
        default:
            return state
    }
}