import React, {useState} from 'react';
import Staff from "../../../shared/components/Staff";
import {useDispatch} from "react-redux";
import NotStaff from "../../../shared/components/NotStaff";
import Delivery from "../../../shared/models/Delivery";
import {updateDeliveryAction} from "../../actions/delivery.actions";


interface Props {
    delivery: Delivery
}

export default function PriceChange(
    {
        delivery
    }: Props) {

    const [p, setP] = useState(delivery.price)
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const dispatch = useDispatch()

    const handleOnChange = (e: any) => {
        setP(e.target.value)
        if (timer) {
            clearTimeout(timer)
            setTimer(setTimeout(() => {
                const d: Delivery = Object.assign(delivery)
                d.price = e.target.value
                if(d.price){
                    dispatch(updateDeliveryAction(d))
                }
                setTimer(undefined)
            }, 1000))
        } else {
            setTimer(setTimeout(() => {
                const d: Delivery = Object.assign(delivery)
                d.price = e.target.value
                if(d.price){
                    dispatch(updateDeliveryAction(d))
                }
                setTimer(undefined)
            }, 1000))
        }
    }

    return (
        <div className="flex items-center">
            <Staff>
                <input
                    className={`w-full px-2 py-2 bg-gray-200 rounded outline-none leading-tight focus:outline-none focus:bg-white focus:border-adl-1 border-gray-200 border-2`}
                    value={p}
                    type="number"
                    step={0.01}
                    onChange={handleOnChange}
                />
            </Staff>
            <NotStaff>
                <strong>{delivery!.price!} €</strong>
            </NotStaff>

        </div>
    )
}