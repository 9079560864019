/**
 *  Created on Di. 24.08.2021 08:51:58
 *
 *  @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import React, { useEffect, useState } from 'react';
import {PageContent} from '../../../shared/components/page';
import {Card} from "../../../shared/components/card";
import Box from "../../../shared/models/Box";
import Table from "../../../shared/components/table";
import {useDispatch, useSelector} from "react-redux";
import {
    destroyAllMarkedForDestroyedBoxesAction,
    loadMarkedForDestroyedBoxesAction
} from "../../actions/services.actions";
import {getMarkedForDestroyedBoxes} from "../../selector/destruction.selector";
import Button from "../../../shared/components/buttons/Button";
import {FaTrashAlt} from "react-icons/fa";
import YesNo from "../../../shared/components/overlays/YesNo";

export default function () {

    const dispatch = useDispatch();
    const [showYesNo, setShowYesNo] = useState(false);

    useEffect(() => {
        dispatch(loadMarkedForDestroyedBoxesAction())
    }, [dispatch])

    const boxes = useSelector(getMarkedForDestroyedBoxes)

    const columns = [
        {name: "Code", key: "code", render: (b: Box) => b.formatCode()},
        {name: "Kunde", key: "owner", render: (b: Box) => b.owner!.name},
        {name: "Vernichtungsjahr", key: "meta_destroy_year", render: (b: Box) => b.metaDestroyYear},
        {name: "Bereich", key: "position__row__area__name", render: (b: Box) => b.position?.row.area!.name},
        {name: "Reihe", key: "position__row__number", render: (b: Box) => b.position?.row.number},
        {name: "Teil", key: "position__part", render: (b: Box) => b.position?.part},
        {name: "Höhe", key: "position_height", render: (b: Box) => b.position?.height},
    ]

    const handleDestroy = () => {
        dispatch(destroyAllMarkedForDestroyedBoxesAction())
        setShowYesNo(false)
    }

    return (
        <div>
            <YesNo
                title="Vernichten"
                text="Alle Boxen wirklich vernichten?"
                type="danger"
                open={showYesNo}
                onClose={() => setShowYesNo(false)}
                onYes={() => handleDestroy()}
                onNo={() => setShowYesNo(false)}
            />
            <PageContent>
                <Card title={<div className="flex items-center justify-between">
                    <div>
                        Sammelvernichtung
                    </div>
                    <div>
                        <Button
                            onClick={() => setShowYesNo(true)}
                            icon={<FaTrashAlt/>}
                            color="danger"
                        >
                            Boxen vernichten
                        </Button>
                    </div>
                </div>}>
                    <Table
                        columns={columns}
                        data={boxes}
                    />
                </Card>
            </PageContent>
        </div>
    )

}