/**
* Created on Sat Jan 23 2021 13:30:44
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { useSelector } from 'react-redux';
import { Page, PageContent } from '../../shared/components/page';
import TopBar from '../../shared/components/topbar';
import { authUser } from '../../shared/selectors/auth.selector';
import logo from './../../shared/images/adl-logo.gif';
import ChangePassword from './ChangePassword';

export default function () {

    const user = useSelector(authUser)

    return (
        <Page>
            <TopBar
                title="Home"
                links={[]}
            />
            <PageContent>
                <div className="flex flex-col items-center justify-center mt-10">
                    <div className="mt-10 lg:text-xl">
                        Willkommen im Management System der <span className="font-bold">Archiv-Daten-Logistik GmbH</span>.
                    </div>
                    <img src={logo} alt="" className="w-40 mt-10" />
                    <div className="mt-20">
                        Sie sind als <span className="font-bold">{user?.firstName} {user?.lastName}</span> angemeldet.
                    </div>
                </div>
                {user?.initPassword &&
                    <div className="mt-10">
                        <ChangePassword />
                    </div>
                }
            </PageContent>
        </Page>
    )
}