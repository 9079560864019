/**
* Created on Sun Apr 18 2021 14:48:06
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/


import { AppState } from "../../reducers/rootReducer";
import Customer from "../../shared/models/Customer";

export function getCustomers(state: AppState): Customer[] {
    return state.services.destroyList.customers
}
