/**
* Created on Sat Jan 23 2021 18:21:58
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { combineReducers } from 'redux';
import areasReducer from './areas.reducer';
import boxesReducer from './boxes.reducer';
import boxReducer from './box.reducer';
import rowsReducer from './rows.reducer';
import areaReducer from './area.reducer';
import rowReducer from './row.reducer';
import overviewReducer from './overview.reducer';

export default combineReducers({
    boxes: boxesReducer,
    areas: areasReducer,
    rows: rowsReducer,
    box: boxReducer,
    area: areaReducer,
    row: rowReducer,
    overview: overviewReducer,
})