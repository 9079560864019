export default class Pagination<T> {

    constructor(
        public totalItems: number = 0,
        public totalPages: number = 0,
        public currentPage: number = 1,
        public data: T[] = []
    ){};
    
    static fromAPI<T>(model?: any, fromAPIFunc?: (t:any) => T): Pagination<T> {
        const data = fromAPIFunc ? model.results.map((t:any) => fromAPIFunc(t)) : model.results
        const pagination = new Pagination<T>(
            model.count,
            model.count_pages,
            model.current_page,
            data
        )

        return pagination;
    }
}