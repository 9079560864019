/**
* Created on Sat Jan 30 2021 23:07:32
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { StateObservable } from "redux-observable";
import moment from 'moment'

export const createRandomId = (length = 6) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

export const addParamString = (params: string, key: string, value: string | number) => {
  const p = new URLSearchParams(params)
  p.set(key, value.toString())
  return "?" + p.toString()
}

export const token = (state: StateObservable<any>) => {

  if (state.value.auth.token){
    return {
      headers: {
        Authorization: 'Token ' + state.value.auth.token.token
      }
    }
  } else {
    return {}
  }
}

class FilterObject {
  [key: string]: string
}

export function paramsToObject(entries: any) {
  const result: FilterObject = {}
  for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export function formatDate(date:string, format="DD.MM.YYYY HH:mm") {
  if (date === ""){
    return date
  }
  return moment(date).local().format(format)
}

export function parseDate(date:string){
  return moment(date).format("YYYY-MM-DDTHH:mm")
}

export function utcDate(date:string) {
  if (date === ""){
    return date
  }
  return moment(date).utc().toISOString()
}

export function urlHttps(url:string) {
  if (url.includes("localhost")) {
    return url
  }
  return url.replace("http", "https")
}

export function formatBoxCode(code: string): string {
    return code.slice(0, 4) + "." + code.slice(4, 7) + "." + code.slice(7)
}
