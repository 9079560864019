/**
*  Created on Mo. 18.10.2021 10:45:04
*
*  @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/
import download from 'downloadjs';
import update from 'immutability-helper';
import Customer from "../../shared/models/Customer";
import {LoadState} from "../../shared/models/RequestState";
import {ActionType} from "typesafe-actions";
import {
    DOWNLOAD_BOXES_OVERVIEW,
    DOWNLOAD_BOXES_OVERVIEW_SUCCESS, downloadBoxesOverviewAction,
    downloadBoxesOverviewSuccessAction,
    LOAD_CUSTOMERS,
    LOAD_CUSTOMERS_SUCCESS,
    loadCustomersAction,
    loadCustomersSuccessAction
} from "../actions/services.actions";


export interface State {
    customers: Customer[],
    load: LoadState,
    downloadBoxesOverviewList: boolean,
}

export default function (
    state: State = {
        customers: [],
        load: LoadState.init(),
        downloadBoxesOverviewList: false,
    },
    action: ActionType<
        | typeof loadCustomersAction
        | typeof loadCustomersSuccessAction
        | typeof downloadBoxesOverviewAction
        | typeof downloadBoxesOverviewSuccessAction
        >
): State {
    switch (action.type) {
        case LOAD_CUSTOMERS:
            return update(state, {
                load: {$set: LoadState.loading()}
            })
        case LOAD_CUSTOMERS_SUCCESS:
            return update(state, {
                load: {$set: LoadState.loaded()},
                customers: {$set: action.payload}
            })
        case DOWNLOAD_BOXES_OVERVIEW:
            return update(state, {
                downloadBoxesOverviewList: {$set: true}
            })
        case DOWNLOAD_BOXES_OVERVIEW_SUCCESS:
            download(action.payload.data, "ADL-Boxenübersicht.pdf")
            return update(state, {
                downloadBoxesOverviewList: {$set: false}
            })
        default:
            return state
    }
}