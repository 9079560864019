/**
* Created on Sun Apr 18 2021 14:11:40
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import Input from '../../../shared/components/inputs/Input';
import Select from '../../../shared/components/inputs/Select';
import { PageContent } from '../../../shared/components/page';
import Customer from '../../../shared/models/Customer';
import { utcDate } from '../../../shared/utils';
import { downloadDestroyListAction, loadCustomersAction } from '../../actions/services.actions';
import { getCustomers } from '../../selector/destroylist.selector';

export default function () {

    const [customer, setCustomer] = useState<Customer>()
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCustomersAction(""))
    }, [dispatch])

    const customers = useSelector(getCustomers)

    const handleOnClickPdf = () => {
        const params = new URLSearchParams();
        if (from) {
            params.set('from', utcDate(from))
        }
        if (to) {
            params.set('to', utcDate(to))
        }
        const meta = params.toString().length > 0 ? `?${params.toString()}` : ""
        dispatch(downloadDestroyListAction(customer!, meta))

    }

    return (
        <PageContent>
            <Card title={<div className="flex items-center justify-between">
                <div>
                    Vernichtungsliste
                </div>
                <div className="flex gap-x-2">
                    <Button
                        icon={<FaFilePdf className="text-red-600" />}
                        type="button"
                        onClick={() => handleOnClickPdf()}
                        disabled={!customer}
                    >
                        PDF
                    </Button>
                </div>
            </div>}>
                <div className="p-2">
                    <Select
                        title="Kunde"
                        data={customers}
                        renderOption={(c: Customer) => `( ${c.code} ) ${c.name}`}
                        onChangeObjectClass={c => setCustomer(c)}
                    />
                    <div className="grid grid-cols-2 pt-2 gap-x-2">
                        <Input
                            title="von"
                            type="date"
                            value={from}
                            onChange={(e) => setFrom(e.target.value)}
                        />
                        <Input
                            title="bis"
                            type="date"
                            value={to}
                            onChange={(e) => setTo(e.target.value)}
                        />
                    </div>
                </div>
            </Card>
        </PageContent>

    )
}