import { combineEpics } from "redux-observable";
import loginEpics from "../login/epics/login.epics";
import authEpics from "../shared/epics/auth.epics";
import areasEpics from "../stock/epics/areas.epics";
import boxesEpic from "../stock/epics/boxes.epic";
import boxEpic from "../stock/epics/box.epics";
import usersEpic from '../users/epics/users.epics';
import deliveriesEpic from '../deliveries/epics/deliveries.epic';
import customersEpic from '../customers/epics/customers.epics';
import errorEpic from '../shared/epics/error.epics';
import notifyEpics from "../shared/epics/notify.epics";
import areaEpics from "../stock/epics/area.epics";
import rowsEpics from "../stock/epics/rows.epics";
import rowEpics from "../stock/epics/row.epics";
import servicesEpic from "../services/epics/services.epic";
import overviewEpic from '../stock/epics/overview.epics';

export default combineEpics(
    authEpics,
    loginEpics,
    areasEpics,
    areaEpics,
    boxesEpic,
    boxEpic,
    rowsEpics,
    rowEpics,
    usersEpic,
    deliveriesEpic,
    errorEpic,
    customersEpic,
    notifyEpics,
    servicesEpic,
    overviewEpic,
);