/**
* Created on Thu Feb 25 2021 20:30:11
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Page } from '../../shared/components/page';
import TopBar from '../../shared/components/topbar';
import Overview from './overview';
import CustomerForm from './customer-form';
import Customer from './customer';

export default function () {

    return(
        <Page>
            <TopBar
                title="Kunden"
                links={[
                    { name: "Überblick", to: "/customers/overview" },
                ]}
            />
            <Switch>
                <Route exact path="/customers"
                    render={ () => { return <Redirect to="/customers/overview" /> }}
                />
                <Route path="/customers/overview" component={Overview} />
                <Route path="/customers/new" component={CustomerForm}/>
                <Route path="/customers/:id" component={Customer}/>
            </Switch>
        </Page>

    )
}