/**
* Created on Wed Mar 24 2021 21:55:57
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useState } from 'react';

interface Props {
    title?: string,
    value: boolean,
    onChange?: (e: React.ChangeEvent<any> | any) => void,
}

export default function ({
    title,
    value,
    onChange
}: Props) {

    const [ v, setV ] = useState(value)

    const handleOnChange = () => {
        const newV = !v
        setV(newV)
        if (onChange) {
            onChange(newV)
        }
    }

    return(
        <div>
            <label className="inline-flex items-center">
                <input
                    type="checkbox" 
                    className="w-4 h-5 cursor-pointer" 
                    checked={v}
                    onClick={() => handleOnChange()}
                /> 
                <span className="ml-2 font-medium text-gray-600">
                    {title}
                </span>
            </label>
        </div>
    )
}