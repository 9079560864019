/**
* Created on Mon Feb 01 2021 11:04:33
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import Row from "./Row";

export default class Position {

    public id?: number;
    constructor(
        public created: Date,
        public height: number,
        public part: number,
        public row: Row,
    ){}

    static fromAPI(model?: any): Position {

        const position = new Position(
            model.created,
            model.height,
            model.part,
            Row.fromAPI(model.row),
        )
        position.id = model.id;
        return position;

    }
    
}