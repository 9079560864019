/**
* Created on Fri Apr 09 2021 10:02:00
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import StockOverview from "../../shared/models/StockOverview";

export function getOverview(state: AppState): StockOverview {
    return state.stock.overview.data
}