import { AxiosError } from "axios";
import { Observable, of } from "rxjs";
import { ActionType } from "typesafe-actions";
import { forbiddenAction } from "../actions/error.actions";
import { notifyErrorAction } from "../actions/notify.actions";
import Notification from "../models/Notification";

export function handleRequestError(
    err: AxiosError
): Observable<
    ActionType<
        | typeof forbiddenAction
        | typeof notifyErrorAction
    >
> {

    switch (err.response?.status) {
        case 400:
            if (Object.keys(err.response.data).length > 0) {
                const text = err.response.data[Object.keys(err.response.data)[0]]                
                switch (text[0]) {
                    case "customer with this code already exists.":
                        return of(notifyErrorAction(new Notification("Kunde mit dem Code existiert bereits.")))
                    case "The fields areaId, number must make a unique set.":
                        return of(notifyErrorAction(new Notification("Reihe existiert bereits.")))
                    case "area with this name already exists.":
                        return of(notifyErrorAction(new Notification("Bereich existiert bereits.")))
                    default:
                        return of(notifyErrorAction(new Notification(text[0])))
                }
            }
            return of(notifyErrorAction(new Notification("Ups. Da ist etwas schief gelaufen.")))
        case 403:
            return of(forbiddenAction())
        case 404:
            return of(notifyErrorAction(new Notification("Nicht gefunden!")))
        case 405:
            return of(notifyErrorAction(new Notification("Nicht erlaubt!")))
        default:
            return of(notifyErrorAction(new Notification("Ups. Da ist etwas schief gelaufen.")))
    }
}