import axios from "axios";
import { push } from "connected-react-router";
import { combineEpics, Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import api from "../../api";
import { notifySuccessAction } from "../../shared/actions/notify.actions";
import Area from "../../shared/models/Area";
import Notification from "../../shared/models/Notification";
import { token } from "../../shared/utils";
import { handleRequestError } from "../../shared/utils/errors";
import { deleteAreaSuccessAction, DELETE_AREA, DELETE_AREA_SUCCESS } from "../actions/area.actions";
import { addAreaSuccessAction, ADD_AREA, ADD_AREA_SUCCESS, loadAreasSuccessAction, LOAD_AREAS } from "../actions/areas.actions";

const loadAreas: Epic = (action$, state$) => 
    action$.pipe(
        ofType(LOAD_AREAS),
        switchMap(() => {
            const url = `${api.BASE_URL_V1}/areas/?query={id,name,rows_count,boxes_count}`
            return from(axios.get<Area[]>(url, token(state$))).pipe(
                map(({ data }) => loadAreasSuccessAction(data)),
                catchError(handleRequestError)
            )
        })
    )

const addArea: Epic = (action$, state$) =>
    action$.pipe(
        ofType(ADD_AREA),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/areas/`
            return from(axios.post<Area>(url, Area.toApi(payload), token(state$))).pipe(
                map(({ data }) => addAreaSuccessAction(Area.fromAPI(data))),
                catchError(handleRequestError)
            )
        })
    )


const deleteArea: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DELETE_AREA),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/areas/${payload.id}/`
            return from(axios.delete<Area>(url, token(state$))).pipe(
                map(() => deleteAreaSuccessAction()),
                catchError(handleRequestError)
            )
        })
    )

const successDeleteArea: Epic = action$ =>
    action$.pipe(
        ofType(DELETE_AREA_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Bereich gelöscht.`)))
    )

const successAddArea: Epic = action$ =>
    action$.pipe(
        ofType(ADD_AREA_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Bereich hinzugefügt.`)))
    )

const redirectToAreas: Epic = action$ =>
    action$.pipe(
        ofType(ADD_AREA_SUCCESS, DELETE_AREA_SUCCESS),
        map(() => {
            return push('/stock/areas')
        })
    )


export default combineEpics(
    loadAreas,
    deleteArea,
    successDeleteArea,
    redirectToAreas,
    addArea,
    successAddArea,
);