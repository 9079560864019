/**
* Created on Sun Apr 18 2021 22:29:50
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import axios from "axios";
import { combineEpics, Epic, ofType } from "redux-observable";
import { from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import api from "../../api";
import StockOverview from "../../shared/models/StockOverview";

import { token } from "../../shared/utils";
import { handleRequestError } from "../../shared/utils/errors";

import { loadOverviewSuccessAction, LOAD_OVERVIEW } from "../actions/overview.actions";

const loadOverview: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_OVERVIEW),
        switchMap(() => {
            const url = `${api.BASE_URL_V1}/boxes/overview/`
            return from(axios.get(url, token(state$))).pipe(
                map(({ data }) => loadOverviewSuccessAction( StockOverview.fromAPI(data) )),
                catchError(handleRequestError)
            )
        })
    )

export default combineEpics(
    loadOverview,
)