/**
* Created on Fri Feb 26 2021 09:02:32
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import { PageContent } from '../../../shared/components/page';
import Table from '../../../shared/components/table';
import Customer from '../../../shared/models/Customer';
import OrderParams from '../../../shared/models/OrderParams';
import PageParams from '../../../shared/models/PageParams';
import RequestParams from '../../../shared/models/RequestParams';
import { history } from '../../../store/history';
import { loadCustomersAction } from '../../actions/customers.actions';
import { getCustomers } from '../../selector/customers.selector';
import Filter, {FilterObject} from "../../../shared/models/Filter";

export default function () {

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const params = new RequestParams(location.search)
        PageParams.fromSearch(location.search, params)
        OrderParams.fromSearch(location.search, params)
        dispatch(loadCustomersAction(params))
    }, [dispatch, location])


    const filterKeys = ["name", "code"]
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const setFilterParams = (filter: FilterObject) => {
        if (timer) {
            clearTimeout(timer)
            setTimer(setTimeout(() => {
                history.push(location.pathname + "?" + new Filter(filter, filterKeys).params(location.search))
                setTimer(undefined)
            }, 1000))
        } else {
            setTimer(setTimeout(() => {
                history.push(location.pathname + "?" + new Filter(filter, filterKeys).params(location.search))
                setTimer(undefined)
            }, 1000))
        }
    }

    const customers = useSelector(getCustomers)
    const columns = [
        { name: "Code", key: "code", orderBy: true },
        { name: "Name", key: "name", orderBy: true },
        { name: "#Boxen", key: "countBoxes", orderBy: true },
        { name: "#Inhalte", key: "countBoxcontents", orderBy: true },
        { name: "#Bilder", key: "countBoximages", orderBy: true },
        { name: "#L.-Optionen", key: "countDeliveryoptions" }
    ]

    return (
        <PageContent>
            <Card title={
                <div className="flex items-center justify-between">
                    <div>
                        Kunden ( {customers.totalItems} )
                    </div>
                    <div>
                        <Button onClick={() => history.push(`/customers/new`)}>
                            Neuen Kunden hinzufügen
                        </Button>
                    </div>
                </div>
            }
            >
                <Table
                    columns={columns}
                    data={customers.data}
                    pagination={customers}
                    onClickRow={ (c:Customer) => history.push(`/customers/${c.id}`) }
                    onFilter={setFilterParams}
                    filter={filterKeys}
                />
            </Card>
        </PageContent>
    )
}