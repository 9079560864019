/**
* Created on Fri Mar 05 2021 10:44:15
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import Box from '../../../shared/models/Box';

interface Props {
    box: Box
}

export default function Position({
    box
}: Props) {

    return(
        <div className="grid grid-cols-2 gap-x-2">
            <div className="font-semibold text-gray-700">Bereich:</div>
            <div>
                {box.position?.row.area!.name}
            </div>
            <div className="font-semibold text-gray-700">Reihe:</div>
            <div>
                {box.position?.row.number}
            </div>
            <div className="font-semibold text-gray-700">Teil:</div>
            <div>
                {box.position?.part}
            </div>
            <div className="font-semibold text-gray-700">Höhe:</div>
            <div>
                {box.position?.height}
            </div>
        </div>
    )
}