/**
* Created on Sat Mar 27 2021 13:04:51
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Customer from "../../shared/models/Customer";
import DeliveryOption from "../../shared/models/DeliveryOption";
import User from "../../shared/models/User";


export function getCustomer(state: AppState): Customer | undefined {
    return state.customer.data!
}

export function loadedCustomer(state: AppState): boolean {
    return state.customer.load.loaded
}

export function getPossibleUsers(state: AppState): User[] {
    return state.customer.possibleUsers
}

export function getSelectedDeliveryOption(state: AppState): DeliveryOption {
    if (state.customer.data && !state.customer.selectedDeliveryOption){
        return new DeliveryOption("", state.customer.data.id!, [], "", "", 0, 0, 0)
    }
    if (state.customer.selectedDeliveryOption){
        return state.customer.selectedDeliveryOption
    }
    return new DeliveryOption("", -1, [], "", "", 0, 0, 0)
}