/**
* Created on Sun Apr 18 2021 14:32:43
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import download from 'downloadjs';
import { ActionType } from 'typesafe-actions';
import Customer from '../../shared/models/Customer';
import { LoadState } from '../../shared/models/RequestState';
import {
    downloadDestroyRequestSuccessAction,
    DOWNLOAD_DESTROY_REQUEST_SUCCESS,
    loadCustomersAction,
    loadCustomersSuccessAction,
    LOAD_CUSTOMERS,
    LOAD_CUSTOMERS_SUCCESS 
} from '../actions/services.actions';

export interface State {
    customers: Customer[]
    load: LoadState
}

export default function (
    state: State = {
        customers: [],
        load: LoadState.init(),
    },
    action: ActionType<
        | typeof loadCustomersAction
        | typeof loadCustomersSuccessAction
        | typeof downloadDestroyRequestSuccessAction
    >
): State {
    switch (action.type) {
        case LOAD_CUSTOMERS:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_CUSTOMERS_SUCCESS:
            return update(state, {
                load: { $set: LoadState.loaded() },
                customers: { $set: action.payload }
            })
        case DOWNLOAD_DESTROY_REQUEST_SUCCESS:
            download(action.payload.data, `ADL-Vernichtungsanfrage.pdf`)
            return state
        default:
            return state
    }
}