/**
* Created on Sat Jan 23 2021 18:19:21
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Box from '../../shared/models/Box';
import Customer from '../../shared/models/Customer';
import Pagination from '../../shared/models/Pagination';
import {CreateState, LoadState} from '../../shared/models/RequestState';
import {
    loadBoxesAction,
    loadBoxesSuccessAction,
    loadCustomersSuccessAction,
    LOAD_BOXES,
    LOAD_BOXES_SUCCESS,
    LOAD_CUSTOMERS_SUCCESS,
    addBoxesAction, ADD_BOXES, ADD_BOXES_SUCCESS, addBoxesSuccessAction
} from "../actions/boxes.actions";

export interface State {
    data: Pagination<Box>,
    load: LoadState,
    create: CreateState,
    possibleCustomers: Customer[]
}

export default function (
    state: State = {
        data: new Pagination<Box>(),
        load: LoadState.init(),
        create: CreateState.init(),
        possibleCustomers: []
    },
    action: ActionType<
        | typeof loadBoxesAction
        | typeof loadBoxesSuccessAction
        | typeof loadCustomersSuccessAction
        | typeof addBoxesAction
        | typeof addBoxesSuccessAction
    >
): State {
    switch (action.type) {
        case LOAD_BOXES:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_BOXES_SUCCESS:
            return update(state, {
                data: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        case LOAD_CUSTOMERS_SUCCESS:
            return update(state, {
                possibleCustomers: { $set: action.payload }
            })
        case ADD_BOXES:
            return update(state, {
                create: { $set: CreateState.creating() }
            })
        case ADD_BOXES_SUCCESS:
            return update(state, {
                create: { $set: CreateState.created() }
            })
        default:
            return state
    }
}