/**
 * Created on Sat Apr 17 2021 20:39:19
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import { combineEpics, Epic, ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import api from "../../api";
import { from } from "rxjs";
import axios from "axios";
import { token } from "../../shared/utils";
import Pagination from "../../shared/models/Pagination";
import Box from "../../shared/models/Box";
import { handleRequestError } from "../../shared/utils/errors";
import {
    downloadArchiveListSuccessAction,
    downloadCollectListSuccessAction,
    downloadContentSuccessImages,
    downloadDestroyListSuccessAction,
    downloadLabelPrintSuccessAction,
    DOWNLOAD_ARCHIVE_LIST,
    DOWNLOAD_COLLECT_LIST,
    DOWNLOAD_CONTENT_IMAGES,
    DOWNLOAD_DESTROY_LIST,
    DOWNLOAD_LABEL_PRINT,
    loadBoxesSuccessAction,
    loadCustomersSuccessAction,
    LOAD_BOXES,
    LOAD_CUSTOMERS,
    updateMetaSuccessAction,
    UPDATE_META,
    UPDATE_META_SUCCESS,
    downloadContentImagesProgressAction,
    downloadArchiveListProgressAction,
    LOAD_MARKED_FOR_DESTROYED_BOXES,
    loadMarkedForDestroyedBoxesSuccessAction,
    DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES,
    destroyAllMarkedForDestroyedBoxesSuccessAction,
    DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES_SUCCESS,
    DOWNLOAD_BOXES_OVERVIEW,
    downloadBoxesOverviewSuccessAction,
    DOWNLOAD_DESTROY_REQUEST,
    downloadDestroyRequestSuccessAction
} from "../actions/services.actions";
import Customer from "../../shared/models/Customer";
import { notifySuccessAction } from "../../shared/actions/notify.actions";
import Notification from "../../shared/models/Notification";


const loadBoxes: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_BOXES),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/boxes/${payload.params()}`
            return from(axios.get(url, token(state$))).pipe(
                map(({ data }) => loadBoxesSuccessAction(Pagination.fromAPI(data, Box.fromAPI))),
                catchError(handleRequestError)
            )
        })
    )


const downloadLabelPrint: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DOWNLOAD_LABEL_PRINT),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/services/labelprintV2/`
            return from(axios.post(url, { codes: payload }, { ...token(state$), responseType: 'blob' })).pipe(
                map((response) => downloadLabelPrintSuccessAction(response)),
                catchError(handleRequestError)
            )
        })
    )

const loadCustomers: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_CUSTOMERS),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/customers/?query={id, name, code, count_boxes, count_boximages, count_meters}${payload}`
            return from(axios.get(url, token(state$))).pipe(
                map(({ data }) => loadCustomersSuccessAction(data.map((d: any) => Customer.fromAPI(d)))),
                catchError(handleRequestError)
            )
        })
    )


const downloadContentImages: Epic = (action$, state$, { store }) =>
    action$.pipe(
        ofType(DOWNLOAD_CONTENT_IMAGES),
        switchMap(({ payload, meta }) => {
            const url = `${api.BASE_URL_V1}/customers/${payload.id}/contentimages/${meta}`
            return from(axios.get(
                url,
                {
                    ...token(state$), responseType: 'blob',
                    onDownloadProgress: ((progressEvent: ProgressEvent) => {
                        const progress = Math.round(progressEvent.loaded / progressEvent.total)
                        store.dispatch(downloadContentImagesProgressAction(progress))
                    })
                }
            )).pipe(
                map((response) => downloadContentSuccessImages(response)),
                catchError(handleRequestError)
            )
        })
    )

const downloadArchiveList: Epic = (action$, state$, { store }) =>
    action$.pipe(
        ofType(DOWNLOAD_ARCHIVE_LIST),
        switchMap(({ payload, meta }) => {
            const url = `${api.BASE_URL_V1}/customers/${payload.id}/archivelist/${meta}`
            return from(axios.get(
                url,
                {
                    ...token(state$), responseType: 'blob',
                    onDownloadProgress: ((progressEvent => {
                        const progress = Math.round(progressEvent.loaded / progressEvent.total)
                        store.dispatch(downloadArchiveListProgressAction(progress))
                    }))
                }
            )).pipe(
                map((response) => downloadArchiveListSuccessAction(response)),
                catchError(handleRequestError)
            )
        })
    )

const downloadCollectList: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DOWNLOAD_COLLECT_LIST),
        switchMap(() => {
            const url = `${api.BASE_URL_V1}/boxes/collectlist/`
            return from(axios.get(url, { ...token(state$), responseType: 'blob' })).pipe(
                map((response) => downloadCollectListSuccessAction(response)),
                catchError(handleRequestError)
            )
        })
    )

const downloadDestroyList: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DOWNLOAD_DESTROY_LIST),
        switchMap(({ payload, meta }) => {
            const url = `${api.BASE_URL_V1}/customers/${payload.id}/destroylist/${meta}`
            return from(axios.get(url, { ...token(state$), responseType: 'blob' })).pipe(
                map((response) => downloadDestroyListSuccessAction(response)),
                catchError(handleRequestError)
            )
        })
    )

const downloadDestroyRequest: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DOWNLOAD_DESTROY_REQUEST),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/customers/${payload.id}/destroyrequest/`
            return from(axios.get(url, { ...token(state$), responseType: 'blob' })).pipe(
                map((response) => downloadDestroyRequestSuccessAction(response)),
                catchError(handleRequestError)
            )
        })
    )

const downloadBoxesOverview: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DOWNLOAD_BOXES_OVERVIEW),
        switchMap(({ payload, meta }) => {
            const url = `${api.BASE_URL_V1}/customers/${payload.id}/overview/${meta}`
            return from(axios.get(url, { ...token(state$), responseType: 'blob' })).pipe(
                map((response) => downloadBoxesOverviewSuccessAction(response)),
                catchError(handleRequestError)
            )
        })
    )

const updateMeta: Epic = (action$, state$) =>
    action$.pipe(
        ofType(UPDATE_META),
        switchMap(({ payload }) => {
            const url = `${api.BASE_URL_V1}/boxes/meta/`
            return from(axios.post(url, payload, token(state$))).pipe(
                map((response) => updateMetaSuccessAction()),
                catchError(handleRequestError)
            )
        })
    )

const loadMarkedForDestroyedBoxes: Epic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_MARKED_FOR_DESTROYED_BOXES),
        switchMap(() => {
            const url = `${api.BASE_URL_V1}/boxes/?state=7&query={id, code, owner, position, meta_destroy_year}`
            return from(axios.get(url, token(state$))).pipe(
                map(({ data }) => loadMarkedForDestroyedBoxesSuccessAction(data.map((b: any) => Box.fromAPI(b)))),
                catchError(handleRequestError)
            )
        })
    )

const destroyAllMarkedForDestroyBoxes: Epic = (action$, state$) =>
    action$.pipe(
        ofType(DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES),
        switchMap(() => {
            const url = `${api.BASE_URL_V1}/boxes/destruction/`
            return from(axios.get(url, token(state$))).pipe(
                map(({ data }) => destroyAllMarkedForDestroyedBoxesSuccessAction(data.map((b: any) => Box.fromAPI(b)))),
                catchError(handleRequestError)
            )
        })
    )

const successUpdateMeta: Epic = action$ =>
    action$.pipe(
        ofType(UPDATE_META_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Meta Daten aktualisiert.`)))
    )


const successDestroy: Epic = action$ =>
    action$.pipe(
        ofType(DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES_SUCCESS),
        map(() => notifySuccessAction(new Notification(`Boxen vernichtet.`)))
    )

export default combineEpics(
    loadBoxes,
    downloadLabelPrint,
    downloadContentImages,
    loadCustomers,
    downloadCollectList,
    downloadArchiveList,
    downloadDestroyList,
    downloadDestroyRequest,
    downloadBoxesOverview,
    updateMeta,
    successUpdateMeta,
    loadMarkedForDestroyedBoxes,
    destroyAllMarkedForDestroyBoxes,
    successDestroy
)