import { ActionType, createAction } from "typesafe-actions";
import Notification from '../models/Notification';


export const NOTIFY_SUCCESS = '@@notify/NOTIFY_SUCCESS';
export type NOTIFY_SUCCESS =  typeof NOTIFY_SUCCESS;

export const NOTIFY_WARNING = '@@notify/NOTIFY_WARNING';
export type NOTIFY_WARNING =  typeof NOTIFY_WARNING;

export const NOTIFY_ERROR = '@@notify/NOTIFY_ERROR';
export type NOTIFY_ERROR =  typeof NOTIFY_ERROR;

export const NOTIFY_INFO = '@@notify/NOTIFY_INFO';
export type NOTIFY_INFO =  typeof NOTIFY_INFO;

export const REMOVE_NOTIFY = '@@notify/REMOVE_NOTIFY';
export type REMOVE_NOTIFY = typeof REMOVE_NOTIFY;

export const notifySuccessAction = createAction(NOTIFY_SUCCESS)<Notification>();
export const notifyWarningAction = createAction(NOTIFY_WARNING)<Notification>();
export const notifyErrorAction = createAction(NOTIFY_ERROR)<Notification>();
export const notifyInfoAction = createAction(NOTIFY_INFO)<Notification>();
export const removeNotifyAction = createAction(REMOVE_NOTIFY)<Notification>();

export type NotifyActions = ActionType<
    | typeof notifySuccessAction
    | typeof notifyWarningAction
    | typeof notifyErrorAction
    | typeof notifyInfoAction
    | typeof removeNotifyAction
>