/**
* Created on Fri Mar 05 2021 09:35:14
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { urlHttps } from "../utils";

export default class Barcode {

    constructor(
        public id: string,
        public image: string,
    ) {};

    static fromAPI(model?: any): Barcode {
        const barcode = new Barcode(
            model.id,
            urlHttps(model.image)
        )
        return barcode;
    }

}