/**
* Created on Fri Apr 09 2021 10:36:38
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import { PageContent } from '../../../shared/components/page';
import Table from '../../../shared/components/table';
import BooleanCell from '../../../shared/components/table/BooleanCell';
import PageParams from '../../../shared/models/PageParams';
import RequestParams from '../../../shared/models/RequestParams';
import Row from '../../../shared/models/Row';
import { history } from '../../../store/history';
import { loadRowsAction } from '../../actions/rows.actions';
import { getRows, loadingRows } from '../../selectors/rows.selectors';

export default function Rows() {

    const location = useLocation();
    const dispatch = useDispatch()
    useEffect(() => {
        const search = new URLSearchParams(location.search)
        const page = search.get('page')
        const params = new RequestParams(location.search)
        params.otherParams = "query={id, number, area, reverse, positions_count}"
        if (page) {
            params.page = new PageParams(parseInt(page))
        } else {
            params.page = new PageParams()
        }
        dispatch(loadRowsAction(params));
    }, [dispatch, location])

    const rows = useSelector(getRows)
    const loading = useSelector(loadingRows)
    const columns = [
        { name: "Bereich", key: "area", render: (r: Row) => r.area!.name },
        { name: "Nummer", key: "number" },
        { name: "Rückwärts", key: "reverse", render: (r: Row) => <BooleanCell value={r.reverse} /> },
        { name: "#Boxen", key: "boxesCount" },
    ]

    return (
        <PageContent>
            <Card title={
                <div className="flex items-center justify-between">
                    <div>
                        Reihen ( {rows.totalItems} )
                    </div>
                    <div>
                    <Button onClick={() => history.push(`/stock/rows/new`)}>
                        Neuen Reihe hinzufügen
                    </Button>
                </div>
                </div>
            }
            >
                <Table
                    data={rows.data}
                    columns={columns}
                    pagination={rows}
                    loading={loading}
                    onClickRow={(r: Row) => history.push(`/stock/rows/${r.id}`)}
                />
            </Card>
        </PageContent>
    )
}