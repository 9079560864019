import update from 'immutability-helper';
import { NotifyActions, NOTIFY_ERROR, NOTIFY_INFO, NOTIFY_SUCCESS, NOTIFY_WARNING, REMOVE_NOTIFY } from "../actions/notify.actions";
import Notification from '../models/Notification';

export interface State {
    data: Notification[]
}

export default function(
    state: State = {
        data: []
    },
    action: NotifyActions
): State {
    switch(action.type){
        case NOTIFY_SUCCESS:            
            return update(state, {
                data: { $push: [{ ...action.payload, type: "SUCCESS"}] }
            })
        case NOTIFY_INFO:
            return update(state, {
                data: { $push: [{ ...action.payload, type: "INFO"}] }
            })
        case NOTIFY_WARNING:
            return update(state, {
                data: { $push: [{ ...action.payload, type: "WARING"}] }
            })
        case NOTIFY_ERROR:
            return update(state, {
                data: { $push: [{ ...action.payload, type: "ERROR"}] }
            })
        case REMOVE_NOTIFY:
            var index = state.data.findIndex(n => n.uuid === action.payload.uuid)
            return update(state, {
                data: { $splice: [[index!, 1]] }
            })
        default: 
            return state
    }
}