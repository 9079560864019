/**
* Created on Wed Mar 10 2021 14:55:25
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

export enum StatesText {
    INSIDE = "in Box",
    OUTSIDE = "nicht in der Box",
    ORDERED = "angefordert",
    NON_EXISTENT = "existiert nicht"
}

export enum StatesNum {
    INSIDE = 0,
    OUTSIDE = 1,
    ORDERED = 2,
    NON_EXISTENT = 3
}

export default class BoxContentState {
    
    public text?: string;
    public num?: number;
    constructor(
        public readonly key: keyof typeof StatesText,
    ){
        this.text = StatesText[key as keyof typeof StatesText]
        this.num =  StatesNum[key as keyof typeof StatesNum]
    }


    static fromAPI(key: string): BoxContentState {
        return new BoxContentState(
            key as keyof typeof StatesText
        )
    }

    static states(): BoxContentState[]{
        return Object.entries(StatesText).map(
            ([key, state], i) => (
                new BoxContentState(
                    key as keyof typeof StatesText
                )
            )
        )
    }
}