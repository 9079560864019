/**
 * Created on Sun Apr 18 2021 12:48:02
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import download from 'downloadjs';
import update from 'immutability-helper';
import {ActionType} from 'typesafe-actions';
import Customer from '../../shared/models/Customer';
import {LoadState} from '../../shared/models/RequestState';
import {
    DOWNLOAD_ARCHIVE_LIST, DOWNLOAD_ARCHIVE_LIST_PROGRESS,
    DOWNLOAD_ARCHIVE_LIST_SUCCESS,
    DOWNLOAD_CONTENT_IMAGES,
    DOWNLOAD_CONTENT_IMAGES_PROGRESS,
    DOWNLOAD_CONTENT_IMAGES_SUCCESS, downloadArchiveListAction, downloadArchiveListProgressAction,
    downloadArchiveListSuccessAction,
    downloadContentImages,
    downloadContentImagesProgressAction,
    downloadContentSuccessImages,
    LOAD_CUSTOMERS,
    LOAD_CUSTOMERS_SUCCESS,
    loadCustomersAction,
    loadCustomersSuccessAction
} from '../actions/services.actions';

export interface State {
    customers: Customer[]
    load: LoadState
    downloadImages: boolean
    downloadImagesProgress: number
    downloadArchiveList: boolean
    downloadArchiveListProgress: number
}

export default function (
    state: State = {
        customers: [],
        load: LoadState.init(),
        downloadImages: false,
        downloadImagesProgress: -1,
        downloadArchiveList: false,
        downloadArchiveListProgress: -1,
    },
    action: ActionType<| typeof loadCustomersAction
        | typeof loadCustomersSuccessAction
        | typeof downloadContentImages
        | typeof downloadContentSuccessImages
        | typeof downloadContentImagesProgressAction
        | typeof downloadArchiveListAction
        | typeof downloadArchiveListSuccessAction
        | typeof downloadArchiveListProgressAction
        >
): State {
    switch (action.type) {
        case LOAD_CUSTOMERS:
            return update(state, {
                load: {$set: LoadState.loading()}
            })
        case LOAD_CUSTOMERS_SUCCESS:
            return update(state, {
                load: {$set: LoadState.loaded()},
                customers: {$set: action.payload}
            })
        case DOWNLOAD_CONTENT_IMAGES:
            return update(state, {
                downloadImages: {$set: true}
            })
        case DOWNLOAD_CONTENT_IMAGES_SUCCESS:
            download(action.payload.data, `ADL-Inhaltsbilder.zip`)
            return update(state, {
                downloadImagesProgress: {$set: -1},
                downloadImages: {$set: false}
            })
        case DOWNLOAD_CONTENT_IMAGES_PROGRESS:
            return update(state, {
                downloadImagesProgress: {$set: action.payload}
            })
        case DOWNLOAD_ARCHIVE_LIST:
            return update(state, {
                downloadArchiveList: {$set: true}
            })
        case DOWNLOAD_ARCHIVE_LIST_PROGRESS:
            return update(state, {
                downloadArchiveListProgress: { $set: action.payload }
            })
        case DOWNLOAD_ARCHIVE_LIST_SUCCESS:
            download(action.payload.data, `ADL-Archivliste.pdf`)
            return update(state, {
                downloadArchiveListProgress: {$set: -1},
                downloadArchiveList: {$set: false}
            })
        default:
            return state
    }
}