/**
* Created on Tue Apr 13 2021 21:14:16
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Card } from '../../../shared/components/card';
import { PageContent } from '../../../shared/components/page';
import Row from '../../../shared/models/Row';
import Select from '../../../shared/components/inputs/Select';
import Input from '../../../shared/components/inputs/Input';
import { useDispatch, useSelector } from 'react-redux';
import { addRowAction, loadAreasAction } from '../../actions/rows.actions';
import { possibleAreas } from '../../selectors/rows.selectors';
import Area from '../../../shared/models/Area';
import Button from '../../../shared/components/buttons/Button';
import validationSchema from './validationSchema';

export default function () {

    const initValues = {
        number: undefined,
        area: undefined
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAreasAction())
    }, [dispatch])

    const areas = useSelector(possibleAreas)

    return (
        <PageContent>
            <Card title="Reihe hinzufügen">
                <div className="p-1">
                    <Formik
                        initialValues={initValues}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false)
                            const r = new Row(values.number!, false)
                            r.area = JSON.parse(values.area!)
                            dispatch(addRowAction(r))
                        }}
                        validationSchema={validationSchema}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            errors,
                            touched,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <div className="p-1">
                                    <Select
                                        title="Bereich"
                                        name="area"
                                        controlId="create-row-area"
                                        data={areas}
                                        renderOption={(a: Area) => a.name}
                                        value={values.area}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.area && !!errors.area}
                                        error={errors.area}
                                        required={true}
                                    />
                                </div>
                                <div className="p-1">
                                    <Input
                                        title="Nummer"
                                        type="number"
                                        name="number"
                                        controlId="create-area-number"
                                        value={values.number}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.number && !!errors.number}
                                        isValid={touched.number && !errors.number}
                                        error={errors.number}
                                        required={true}
                                    />
                                </div>
                                <div className="px-1 py-2">
                                    <Button
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Hinzufügen
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Card>
        </PageContent>
    )
}