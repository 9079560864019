/**
* Created on Sat Apr 17 2021 13:56:52
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

export enum KindsText {
    CUSTOMER = "Kunden",
    RETURN = "Zurück",
}

export enum KindsNum {
    CUSTOMER = 0,
    RETURN = 1,
}

export default class DeliveryKind {
    
    public text?: string;
    public num?: number;
    constructor(
        public readonly key: keyof typeof KindsText,
    ){
        this.text = KindsText[key as keyof typeof KindsText]
        this.num =  KindsNum[key as keyof typeof KindsNum]
    }


    static fromAPI(key: string): DeliveryKind {
        return new DeliveryKind(
            key as keyof typeof KindsText
        )
    }

    static states(): DeliveryKind[]{
        return Object.entries(KindsText).map(
            ([key, state], i) => (
                new DeliveryKind(
                    key as keyof typeof KindsText
                )
            )
        )
    }
}