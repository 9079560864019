/**
* Created on Fri Mar 12 2021 13:14:29
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';


interface Props {
    step: number,
    steps: React.ReactNode[]
}

export default function Steps({
    step,
    steps,
}: Props) {
    
    return(
        <div>
            {steps[step]}
        </div>
    )
}