/**
* Created on Fri Jan 29 2021 14:56:48
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import BeatLoader from "react-spinners/BeatLoader";

interface Props<T> {
    data: T[]
    value?: any,
    showKey?: keyof T,
    renderOption?: (o: T) => string;
    title?: string,
    name?: string,
    onChange?: (e: React.ChangeEvent<any> | any) => void,
    onChangeObjectClass?: (o: T) => void,
    onBlur?: (e: React.FocusEvent<any> | any) => void,
    controlId?: string,
    loading?: boolean,
    error?: string,
    isInvalid?: boolean,
    required?: boolean,
}

export default function Select<T>({
    data,
    showKey,
    renderOption,
    title,
    onChange,
    onChangeObjectClass,
    onBlur,
    name,
    controlId,
    value,
    loading,
    error,
    isInvalid,
    required
}: Props<T>) {

    const handleOnChange = (e: React.ChangeEvent<any>) => {
        if(onChange){
            onChange(e)
        }
        if(onChangeObjectClass){
            if (e.target.value === "") {
                onChangeObjectClass(null!)
            } else {
                onChangeObjectClass(JSON.parse(e.target.value))
            }
        }
    }

    return (
        <div className="w-full">
            <div className="flex items-baseline justify-between">
                <div className="font-medium text-gray-600">
                    {title} {required && "*"}
                </div>
                <div className="text-sm text-red-500">
                    {isInvalid &&
                        <div >
                            {error}
                        </div>
                    }
                </div>
            </div>
            <div className="relative">
                {loading &&
                    <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        <BeatLoader
                            size={8}
                            color={'#374151'}
                        />
                    </div>
                }
                <select className={`block w-full px-2 py-2 pr-8 leading-tight text-gray-700 bg-gray-200 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-adl-1 ${isInvalid && "border-red-500 border-2 bg-red-100"} `}
                    onChange={handleOnChange}
                    onBlur={onBlur}
                    name={name}
                    id={controlId}
                    value={typeof (value) === "object" ? JSON.stringify(value) : value}
                >
                    {!loading &&
                        <>
                            <option value={""} key={`${controlId}-0`} >Auswählen...</option>
                            {data &&
                                data.map((e, index) =>
                                    <option key={`${controlId}-${index + 1}`} value={JSON.stringify(e)}>
                                        { renderOption ? renderOption(e) : e[showKey as keyof T] }
                                    </option>
                                )
                            }
                        </>
                    }
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                </div>
            </div>
        </div>
    )
}