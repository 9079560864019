/**
* Created on Wed Mar 31 2021 09:02:36
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useState, RefObject, useEffect, createRef } from 'react';
import _ from "lodash";
import { FaTimes } from 'react-icons/fa';

const useOnClickOutside = (ref: RefObject<HTMLDivElement>, handler: any) => {
    useEffect(
        () => {
            const listener = (event: any) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            document.addEventListener('keydown', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
                document.removeEventListener('keydown', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}

interface Props<T> {
    title: string,
    name: string,
    data: T[],
    values?: T[]
    showKey?: keyof T,
    renderOption?: (o: T) => string,
    onChange?: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    emptyPlaceHolder?: string,
}

export default function MultiSelect<T>({
    title,
    data,
    showKey,
    renderOption,
    onChange,
    name,
    emptyPlaceHolder,
    values = [],
}: Props<T>) {

    const [showSelect, setShowSelect] = useState(false)
    const ref = createRef<HTMLDivElement>()
    useOnClickOutside(ref, () => setShowSelect(false))
    
    const addValue = (item: T) => {
        if (onChange) {
            if(!isSelected(item)){
                onChange(name, [...values!, item])
            } else {
                const index = values!.findIndex(i => i === item)
                values?.splice(index, 1)
                onChange(name, values)
            }
        }
    }

    const removeValue = (item: T, e: any) => {
        e.stopPropagation();
        if (onChange) {
            const index = values!.findIndex(i => i === item)
            values?.splice(index, 1)
            onChange(name, values)
        }
    }

    const isSelected = (item: T) : boolean => {
        const index = values.findIndex(i => _.isEqual(i,item))
        if (index !== -1) {
            return true
        }
        return false
    }

    return (
        <div className="">
            <div className="font-medium text-gray-600">
                {title}
            </div>
            <div ref={ref}>
                <div className={`pl-2 py-2 border-2  bg-white ${showSelect ? "border-adl-1 rounded-t" : "rounded border-gray-200"}`}>
                    <div className="relative pr-8 min-h-5" onClick={() => setShowSelect(true)}>
                        <ul className="flex flex-wrap gap-x-2 gap-y-2">
                            {values.map((item, index) =>
                                <li key={`multiselect-value-${index}`} className="flex items-center px-2 bg-gray-200 rounded w-min">
                                    <div className="whitespace-nowrap">
                                        {renderOption ? renderOption(item) : item[showKey as keyof T]}
                                    </div>
                                    <button type="button" className="ml-2 text-sm text-gray-600 focus:outline-none" onClick={(e) => removeValue(item, e)}>
                                        <FaTimes />
                                    </button>
                                </li>
                            )}
                        </ul>
                        {values.length === 0 &&
                            <div className="italic text-gray-500">
                                {emptyPlaceHolder!}
                            </div>
                        }
                        <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                            <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>

                        </div>
                    </div>
                </div>
                <div className="relative">
                    <div className="absolute top-0 w-full z-9999">
                        {showSelect &&
                            <div className="bg-white border-b-2 border-l-2 border-r-2 rounded-b border-adl-1">
                                <ul>
                                    {data.map((item, index) =>
                                        <li 
                                            key={`multiselect-select-${index}`} 
                                            className={`px-2 py-0.5 cursor-pointer ${isSelected(item)? "bg-adl-2": "hover:bg-gray-200"} `}
                                            onClick={(e) => addValue(item)}
                                        >
                                            {renderOption ? renderOption(item) : item[showKey as keyof T]}
                                        </li>)
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}