/**
 *  Created on Di. 24.08.2021 10:12:27
 *
 *  @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */
import {LoadState} from "../../shared/models/RequestState";
import update from 'immutability-helper';
import Box from "../../shared/models/Box";
import {
    DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES,
    DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES_SUCCESS,
    LOAD_MARKED_FOR_DESTROYED_BOXES,
    LOAD_MARKED_FOR_DESTROYED_BOXES_SUCCESS,
    ServicesAction
} from "../actions/services.actions";

export interface State {
    load: LoadState
    data: Box[]
}

export default function (
    state: State = {
        load: LoadState.init(),
        data: []
    },
    action: ServicesAction
): State {
    switch (action.type) {
        case LOAD_MARKED_FOR_DESTROYED_BOXES:
            return update(state, {
                load: {$set: LoadState.loading()}
            })
        case LOAD_MARKED_FOR_DESTROYED_BOXES_SUCCESS:
            return update(state, {
                data: {$set: action.payload},
                load: {$set: LoadState.loaded()}
            })
        case DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES:
            return update(state, {
                load: {$set: LoadState.loading()}
            })
        case DESTROY_ALL_MARKED_FOR_DESTROYED_BOXES_SUCCESS:
            return update(state, {
                load: {$set: LoadState.loaded()},
                data: {$set: []}
            })
        default:
            return state
    }
}