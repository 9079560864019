/**
* Created on Mon Feb 01 2021 10:59:57
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import Area from "./Area";
import RowQRCode from "./RowQRCode";

export default class Row {

    public id?: number;
    public boxesCount?: number;
    public area?: Area;
    public qrCode?: RowQRCode;
    public created?: Date;

    constructor(
        public number: number,
        public reverse: boolean,
    ) { }

    static fromAPI(model?: any): Row {
        const row = new Row(
            model.number,
            model.reverse,
        )
        row.id = model.id;
        row.created = model.created;
        row.boxesCount = model.positions_count;
        if (model.area) {
            row.area = Area.fromAPI(model.area)
        }
        if (model.qrcode) {
            row.qrCode = RowQRCode.fromAPI(model.qrcode);
        }
        return row;
    }

    static toApi(row: Row): Object {
        let data:any = {
            number: row.number!,
            reverse: row.reverse
        }
        if(row.area){
            data.areaId = row.area!.id!
        }
        return data
    }

}