/**
* Created on Sat Jan 23 2021 17:32:25
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { PropsWithChildren } from 'react';

export default function Page({
    children
}: PropsWithChildren<{}>) {

    return(
        <div className="w-full lg:ml-72 ml-14">
            {children}
        </div>
    )
}