/**
* Created on Sat Feb 20 2021 16:12:20
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Pagination from "../../shared/models/Pagination";
import User from "../../shared/models/User";


export function getUsers(state: AppState): Pagination<User> {
    return state.users.data
}

export function loadedUsers(state: AppState): boolean {
    return state.users.load.loaded
}

export function loadingUsers(state: AppState): boolean {
    return state.users.load.loading
}