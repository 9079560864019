/**
* Created on Thu Feb 25 2021 11:06:41
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import Box from "./Box";
import BoxContent from "./BoxContent";
import Customer from "./Customer";
import DeliveryKind from "./DeliveryKind";
import DeliveryOption from "./DeliveryOption";
import DeliveryState from "./DeliveryState";
import {utcDate} from "../utils";

export default class Delivery {

    public id?: number;
    public code?: string;
    public created?: Date;
    public kind?: DeliveryKind;
    public deliveroption?: DeliveryOption;
    public time?: string;
    public state?: DeliveryState;
    public price?: number;

    constructor(
        public boxes: Box[],
        public boxcontents: BoxContent[],
        public customer: Customer,
        public comment: string = "",
    ) { }

    static fromAPI(model?: any): Delivery {
        const delivery = new Delivery(
            model.boxes.map((b: any) => Box.fromAPI(b)),
            model.boxcontents.map((c: any) => BoxContent.fromAPI(c)),
            Customer.fromAPI(model.customer),
            model.comment,
        )
        delivery.id = model.id;
        delivery.code = model.code;
        delivery.created = model.created;
        delivery.kind = DeliveryKind.fromAPI(model.kind);
        delivery.time = model.time;
        delivery.state = DeliveryState.fromAPI(model.state);
        delivery.price = model.price;
        return delivery
    }

    static toAPI(d: Delivery): object {
        if(d.deliveroption){
            return  {
                kind: 0,
                boxes: d.boxes,
                boxcontents: d.boxcontents,
                customer: d.customer.id,
                price: d.deliveroption?.price,
                date: DeliveryOption.nextDeliveryDate(d.deliveroption!),
                comment: d.comment
            }
        }
        return {
            price: d.price,
            time: utcDate(d.time!)
        }


    }

}