/**
* Created on Wed Mar 10 2021 09:51:16
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { combineReducers } from 'redux'
import boxesReducer from './boxes.reducer'
import createReducer from './create.reducer'
import customersReducer from './customers.reducer'
import deliveriesReducers from './deliveries.reducers'

export default combineReducers({
    deliveries: deliveriesReducers,
    possibleBoxes: boxesReducer,
    possibleCustomers: customersReducer,
    create: createReducer,
})