/**
* Created on Tue Apr 20 2021 21:27:52
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useState } from 'react';
import { Formik } from 'formik';
import Button from '../../../shared/components/buttons/Button';
import Input from '../../../shared/components/inputs/Input';
import WeekdaySelect from '../../../shared/components/inputs/WeekdaySelect';
import Table from '../../../shared/components/table';
import Customer from '../../../shared/models/Customer';
import DeliveryOption from '../../../shared/models/DeliveryOption';
import validationSchema from './validationSchemaDeliveryOption';
import { useDispatch, useSelector } from 'react-redux';
import { addDeliveryOptionAction, deleteDeliveryOptionAction, selectDeliveryOptionAction, updateDeliveryOptionAction } from '../../actions/customers.actions';
import { getSelectedDeliveryOption } from '../../selector/customer.selector';
import YesNo from '../../../shared/components/overlays/YesNo';

interface Props {
    customer: Customer
}

export default function DeliveryOptions({
    customer,
}: Props) {

    const columns = [
        { name: "Beschreibung", key: "description" },
        { name: "Tage", key: "days", render: (d: DeliveryOption) => <WeekdaySelect days={d.days} disabled /> },
        { name: "von", key: "from" },
        { name: "bis", key: "to" },
        { name: "h vor \"bis\"", key: "prior" },
        { name: "Fenster", key: "window" },
        { name: "Preis", key: "price" }
    ]

    const [showYesNoDelete, setShowYesNoDelete] = useState(false);
    const selectedDeliveryOption = useSelector(getSelectedDeliveryOption)
    const dispatch = useDispatch();

    return (
        <div>
            <YesNo
                title="Löschen"
                text={`Lieferoption "${selectedDeliveryOption?.description}" wirklich löschen?`}
                type="danger"
                open={showYesNoDelete}
                onClose={() => setShowYesNoDelete(false)}
                onYes={() => {
                    dispatch(deleteDeliveryOptionAction(selectedDeliveryOption));
                    setShowYesNoDelete(false);
                }}
                onNo={() => setShowYesNoDelete(false)}
            />
            <Table
                columns={columns}
                data={customer.deliveryoptions!}
                onClickRow={(r: DeliveryOption) => dispatch(selectDeliveryOptionAction(r))}
            />
            <hr className="border-b-4 border-adl-1"></hr>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={selectedDeliveryOption}
                onSubmit={(values, { setSubmitting }) => {
                    if (values.id) {
                        dispatch(updateDeliveryOptionAction(values))
                    } else {
                        dispatch(addDeliveryOptionAction(values))
                    }
                }}
            >
                {({
                    isSubmitting,
                    isValid,
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className="p-1">
                            <div className="p-1">
                                <Input
                                    title="Name"
                                    type="text"
                                    name="description"
                                    controlId="form-customer-description"
                                    value={values.description}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={touched.description && !!errors.description}
                                    isValid={touched.description && !errors.description}
                                    error={errors.description}
                                    required={true}
                                />
                            </div>
                            <div className="p-1">
                                <WeekdaySelect
                                    name="days"
                                    days={values.days}
                                    title="Wochentage"
                                    onChange={(days: any) => setFieldValue("days", days)}
                                    isInvalid={touched.days && !!errors.days}
                                    error={errors.days}
                                    required={true}
                                />
                            </div>
                            <div className="p-1">
                                <Input
                                    title="von"
                                    type="time"
                                    name="from"
                                    controlId="form-customer-from"
                                    value={values.from}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={touched.from && !!errors.from}
                                    isValid={touched.from && !errors.from}
                                    error={errors.from}
                                    required={true}
                                />
                            </div>
                            <div className="p-1">
                                <Input
                                    title="bis"
                                    type="time"
                                    name="to"
                                    controlId="form-customer-to"
                                    value={values.to}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={touched.to && !!errors.to}
                                    isValid={touched.to && !errors.to}
                                    error={errors.to}
                                    required={true}
                                />
                            </div>
                            <div className="p-1">
                                <Input
                                    title={`Letzte Bestellmöglichkeit vor "bis" in h`}
                                    type="number"
                                    name="prior"
                                    min={0}
                                    controlId="form-customer-prior"
                                    value={values.prior}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={touched.prior && !!errors.prior}
                                    isValid={touched.prior && !errors.prior}
                                    error={errors.prior}
                                    required={true}
                                />
                            </div>
                            <div className="p-1">
                                <Input
                                    title="Lieferzeitfenster in h"
                                    type="number"
                                    name="window"
                                    min={0}
                                    controlId="form-customer-window"
                                    value={values.window}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={touched.window && !!errors.window}
                                    isValid={touched.window && !errors.window}
                                    error={errors.window}
                                    required={true}
                                />
                            </div>
                            <div className="p-1">
                                <Input
                                    title="Preis"
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    name="price"
                                    controlId="form-customer-price"
                                    value={values.price}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isInvalid={touched.price && !!errors.price}
                                    isValid={touched.price && !errors.price}
                                    error={errors.price}
                                    required={true}
                                />
                            </div>
                            <div className="px-1 py-2">
                                {selectedDeliveryOption.id ?
                                    <div className="flex gap-x-2">
                                        <Button disabled={!isValid} type="submit">
                                            Aktualisieren
                                        </Button>
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => setShowYesNoDelete(true)}
                                        >
                                            Löschen
                                        </Button>
                                    </div>
                                    :
                                    <Button disabled={!isValid} type="submit">
                                        Hinzufügen
                                    </Button>
                                }
                            </div>
                        </div>
                    </form>
                )}

            </Formik>
        </div>
    )
}