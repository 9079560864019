/**
* Created on Sat Feb 20 2021 18:20:00
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import { Card } from '../../../shared/components/card';
import Input from '../../../shared/components/inputs/Input';
import Select from '../../../shared/components/inputs/Select';
import { PageContent } from '../../../shared/components/page';
import Gender from '../../../shared/models/Gender';
import User from '../../../shared/models/User';
import { addUserAction } from '../../actions/users.actions';
import validationSchema from './validationSchema';

export default function () {

    const initValues = new User("", "", "")
    const dispatch = useDispatch()

    return (
        <PageContent>
            <Card title="Neuer User">
                <div className="p-1">
                    <Formik
                        initialValues={initValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false)
                            dispatch(addUserAction(values))
                        }}
                    >
                        {({
                            isSubmitting,
                            isValid,
                            errors,
                            touched,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <div className="flex items-center w-full">
                                    <div className="w-full p-1">
                                        <Select
                                            title="Anrede"
                                            name="gender"
                                            controlId="create-user-gender"
                                            data={Gender.genders()}
                                            showKey="text"
                                            value={values.gender}
                                            onChangeObjectClass={ (o:any) => setFieldValue("gender", o)}
                                            onBlur={handleBlur}
                                            isInvalid={touched.gender && !!errors.gender}
                                            error={errors.gender}
                                            required={true}
                                        />
                                    </div>
                                    <div className="w-full p-1">
                                        <Input
                                            title="Vorname"
                                            type="text"
                                            name="firstName"
                                            controlId="create-user-firstName"
                                            value={values.firstName}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={touched.firstName && !!errors.firstName}
                                            isValid={touched.firstName && !errors.firstName}
                                            error={errors.firstName}
                                            required={true}
                                        />
                                    </div>
                                    <div className="w-full p-1">
                                        <Input
                                            title="Nachname"
                                            type="text"
                                            name="lastName"
                                            controlId="create-user-lastName"
                                            value={values.lastName}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={touched.lastName && !!errors.lastName}
                                            isValid={touched.lastName && !errors.lastName}
                                            error={errors.lastName}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="p-1">
                                    <Input
                                        title="E-Mail"
                                        type="text"
                                        name="email"
                                        controlId="create-user-email"
                                        value={values.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.email && !!errors.email}
                                        isValid={touched.email && !errors.email}
                                        error={errors.email}
                                        required={true}
                                    />
                                </div>
                                <div className="p-1">
                                    <Input
                                        title="Telefon 1"
                                        type="text"
                                        name="phone1"
                                        controlId="create-user-phone1"
                                        value={values.phone1}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.phone1 && !!errors.phone1}
                                        isValid={touched.phone1 && !errors.phone1}
                                        error={errors.phone1}
                                        required={true}
                                    />
                                </div>
                                <div className="p-1">
                                    <Input
                                        title="Telefon 2"
                                        type="text"
                                        name="phone2"
                                        controlId="create-user-phone2"
                                        value={values.phone2}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.phone2 && !!errors.phone2}
                                        isValid={touched.phone2 && !errors.phone2}
                                        error={errors.phone2}
                                    />
                                </div>
                                <div className="px-1 py-2">
                                    <Button
                                        type="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Hinzufügen
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Card>
        </PageContent>
    )
}