/**
* Created on Sat Jan 30 2021 23:13:27
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { AppState } from "../../reducers/rootReducer";
import Box from "../../shared/models/Box";
import Customer from "../../shared/models/Customer";
import Pagination from "../../shared/models/Pagination";

export function getBoxes(state: AppState): Pagination<Box> {
    return state.stock.boxes.data
}

export function loadedBoxes(state: AppState): boolean {
    return state.stock.boxes.load.loaded
}

export function loadingBoxes(state: AppState): boolean {
    return state.stock.boxes.load.loading
}

export function possibleCustomers(state: AppState): Customer[] {
    return state.stock.boxes.possibleCustomers
}

export function creatingBoxes(state: AppState): boolean {
    return state.stock.boxes.create.creating;
}