/**
 * Created on Wed Jan 20 2021 10:59:10
 *
 * @author: Mark Tschuden <mark@tschuden-itsolutions.de>
 */

import React, {PropsWithChildren} from 'react';
import LoadingBeat from "../LoadingBeat";

interface Props {
    type?: "submit" | "reset" | "button",
    disabled?: boolean,
    onClick?: () => void,
    pending?: boolean,
    icon?: React.ReactNode,
    color?: "primary" | "warning" | "danger" | 'default'
}

export default function Button(
    {
        children,
        onClick,
        type,
        disabled,
        icon,
        color = "primary",
        pending
    }: PropsWithChildren<Props>) {

    return (
        <button
            type={type}
            className={
                `h-full w-full px-3 py-1 rounded shadow focus:outline-none disabled:bg-gray-400
                ${color === "primary" && "bg-adl-2 hover:bg-adl-1"}
                ${color === "danger" && "bg-red-500 hover:bg-red-700"}
                ${color === "warning" && "bg-yellow-500 hover:bg-yellow-600"}
                ${color === "default" && "bg-gray-500 hover:bg-adl-1"}
                ${(disabled || pending) && "cursor-not-allowed"}
                `
            }
            onClick={onClick}
            disabled={disabled || pending}
        >
            <div className="flex items-center justify-center font-medium text-white whitespace-nowrap">
                {pending ?
                    <LoadingBeat/>
                    :
                    <>
                        {icon &&
                        <div className={children ? `pr-2` : ""}>
                            {icon}
                        </div>
                        }
                        {children}

                    </>
                }
            </div>
        </button>
    )
}