/**
* Created on Fri Apr 09 2021 08:50:43
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Area from "../../shared/models/Area";

export const LOAD_AREA = '@@area/LOAD_AREA';
export type LOAD_AREA = typeof LOAD_AREA;

export const LOAD_AREA_SUCCESS = '@@area/LOAD_AREA_SUCCESS';
export type LOAD_AREA_SUCCESS = typeof LOAD_AREA_SUCCESS;

export const DELETE_AREA = '@@area/DELETE_AREA';
export type DELETE_AREA = typeof DELETE_AREA;

export const DELETE_AREA_SUCCESS = '@@area/DELETE_AREA_SUCCESS';
export type DELETE_AREA_SUCCESS = typeof DELETE_AREA_SUCCESS;

export const loadAreaAction = createAction(LOAD_AREA)<number>();
export const loadAreaSuccessAction = createAction(LOAD_AREA_SUCCESS)<Area>();

export const deleteAreaAction = createAction(DELETE_AREA)<Area>();
export const deleteAreaSuccessAction = createAction(DELETE_AREA_SUCCESS)();

export type AreaAction = ActionType<
    | typeof loadAreaAction
    | typeof loadAreaSuccessAction
    | typeof deleteAreaAction
    | typeof deleteAreaSuccessAction
>;