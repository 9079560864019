/**
* Created on Mon Mar 15 2021 11:17:38
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';

interface Props {
    title: string,
    controlId?: string,
    onChange?: (e: React.ChangeEvent<any> | any) => void,
    value?: string | number,
    isError?: boolean,
    isInvalid?: boolean,
    isValid?: boolean,
    error?: string,
    onBlur?: (e: React.FocusEvent<any> | any) => void,
    name?: string,
    required?: boolean,
}

export default function TextArea({
    title,
    value,
    onChange,
    onBlur,
    isError,
    isInvalid,
    isValid,
    error,
    controlId,
    name,
    required
}: Props) {

    return (
        <div>
            <div className="flex items-baseline justify-between">
                <div className="font-medium text-gray-600">
                    {title}
                </div>
                <div className="text-sm text-red-500">

                </div>
            </div>
            <textarea
                name={name}
                id={controlId}
                rows={10}
                className="w-full px-2 py-2 leading-tight bg-gray-200 border-2 border-gray-200 rounded outline-none focus:outline-none focus:bg-white focus:border-adl-1"
                value={value ? value : ""}
                onChange={onChange}
                onBlur={onBlur}
            >
            </textarea>
        </div>
    )
}