/**
* Created on Mon Mar 15 2021 09:42:57
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import TextArea from '../../../shared/components/inputs/TextArea';
import Delivery from '../../../shared/models/Delivery';
import { addDeliveryAction } from '../../actions/delieveries.actions';
import { getBoxContents, getBoxes, getCustomer, getDeliveryoption } from '../../selectors/create.selector';
import {formatBoxCode} from "../../../shared/utils";

export default function CheckAndSubmitForm() {

    const dispatch = useDispatch()
    const boxes = useSelector(getBoxes)
    const boxcontents = useSelector(getBoxContents)
    const customer = useSelector(getCustomer)
    const deliveryoption = useSelector(getDeliveryoption)
    const [comment, setComment] = useState("")

    const handleOnClick = () => {
        const delivery = new Delivery(
            boxes,
            boxcontents,
            customer,
            comment
        )
        delivery.deliveroption = deliveryoption
        dispatch(addDeliveryAction(delivery))
    }

    return (
        <div className="p-2">
            <div className="my-2 text-xl font-bold text-gray-700 underline">
                Zusammenfassung
            </div>
            { boxes.length > 0 &&
                <>
                    <div className="font-semibold text-gray-700">
                        Angeforderte Boxen:
                    </div>
                    <ul className="mb-2 ml-2 list-inside">
                        {boxes.map((b, i) => <li key={`delivery-box-${i}`} className="list-disc"> {b.formatCode()} </li>)}
                    </ul>
                </>
            }
            { boxcontents.length > 0 &&
                <>
                    <div className="font-semibold text-gray-700">
                        Angeforderte Inhalte:
                    </div>
                    <ul className="mb-2 ml-2 list-inside">
                        {boxcontents.map((c, i) => <li key={`delivery-content-${i}`} className="list-disc"> {c.text} ({formatBoxCode(c.boxCode!)}) </li>)}
                    </ul>
                </>

            }
            <TextArea
                title="Kommentar / Bemerkungen" 
                onChange={(e) => setComment(e.target.value)}
                value={comment}
            />
            <Button onClick={() => handleOnClick()}>
                Abschicken
            </Button>
        </div>
    )
}