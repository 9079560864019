/**
* Created on Thu Feb 11 2021 10:01:43
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

export enum StatesText {
    CREATED = "erstellt",
    IN_STOCK = "eingelagert",
    NOT_IN_STOCK = "nicht eingelagert",
    INCONSISTENT = "inkonsistent",
    ORDERED = "angefordert",
    RETOUR = "retour",
    DESTROYED = "vernichtet",
    MARKED_FOR_DESTROYED = "markiert zur Vernichtung",
}

export enum StatesNum {
    CREATED = 0,
    IN_STOCK = 1,
    NOT_IN_STOCK = 2,
    INCONSISTENT = 3,
    ORDERED = 4,
    RETOUR = 5,
    DESTROYED = 6,
    MARKED_FOR_DESTROYED = 7,
}


export default class BoxState {

    constructor(
        public readonly key: keyof typeof StatesText,
        public readonly text: string,
        public readonly num: number,
    ) {}

    static fromAPI(key: string): BoxState {
        return new BoxState(
            key as keyof typeof StatesText,
            StatesText[key as keyof typeof StatesText],
            StatesNum[key as keyof typeof StatesNum]
        )
    }

    static fromNumber(num: number): BoxState | undefined {
        let index = this.states().findIndex( s => s.num === num )
        if (index !== -1){
            return this.states()[index]
        }
        return undefined
    }

    static fromKey(key: keyof typeof StatesText): BoxState {
        return new BoxState(
            key as keyof typeof StatesText,
            StatesText[key as keyof typeof StatesText],
            StatesNum[key as keyof typeof StatesNum]
        )
    }

    static states(): BoxState[] {
        return Object.entries(StatesText).map(
            ([key, state], i) => (
                new BoxState(
                    key as keyof typeof StatesText,
                    StatesText[key as keyof typeof StatesText],
                    StatesNum[key as keyof typeof StatesNum]
                )
            ))
            // Object.entries(States).map(([key, state], i) => ({ value: key, name: state }))
    }
}