/**
* Created on Thu Feb 25 2021 11:51:27
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Delivery from "../../shared/models/Delivery";
import Pagination from "../../shared/models/Pagination";
import { LoadState } from "../../shared/models/RequestState";
import {
    loadDeliveriesAction,
    loadDeliveriesSuccessAction,
    LOAD_DELIVERIES,
    LOAD_DELIVERIES_SUCCESS
} from "../actions/delieveries.actions";


export interface State {
    data: Pagination<Delivery>,
    load: LoadState,
}

export default function (
    state: State = {
        data: new Pagination<Delivery>(),
        load: LoadState.init()
    },
    action: ActionType<
        | typeof loadDeliveriesAction
        | typeof loadDeliveriesSuccessAction
    >
): State {

    switch (action.type) {
        case LOAD_DELIVERIES:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_DELIVERIES_SUCCESS:
            return update(state, {
                data: { $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        default:
            return state
    }

}