/**
* Created on Sun Apr 18 2021 09:10:36
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import { FaBackspace, FaMinus, FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import Box from '../../../shared/models/Box';
import { add24LabelAction, addLabelAction, removeLabelAction, resetLabelAction } from '../../actions/services.actions';

interface Props {
    boxes: Box[]
}

export default function ({
    boxes
}: Props) {

    const dispatch = useDispatch()

    return (
        <div className="flex gap-x-1">
            <div>
                <Button
                    color="default"
                    icon={<FaPlus />}
                    onClick={() => boxes.forEach(b => dispatch(addLabelAction(b))) }
                />
            </div>
            <div>
                <Button
                    color="default"
                    icon={<FaMinus />}
                    onClick={() => boxes.forEach(b => dispatch(removeLabelAction(b))) }
                />
            </div>
            <div className="text-sm">
                <Button
                    color="default"
                    onClick={() => boxes.forEach(b => dispatch(add24LabelAction(b))) }
                >
                    +24
        </Button>
            </div>
            <div>
                <Button
                    color="default"
                    icon={<FaBackspace />}
                    onClick={() => boxes.forEach(b => dispatch(resetLabelAction(b)))}
                />
            </div>
        </div>
    )
}