/**
* Created on Fri Mar 12 2021 11:43:04
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/components/buttons/Button';
import Select from '../../../shared/components/inputs/Select';
import Customer from '../../../shared/models/Customer';
import RequestParams from '../../../shared/models/RequestParams';
import { loadCustomersAction, setCustomerAction } from '../../actions/delieveries.actions';
import { getPossibleCustomers } from '../../selectors/deliveries.selector';

interface Props {
    nextStep: () => void;
    customer?: Customer;
}

export default function ({
    nextStep,
    customer,
}: Props) {

    const dispatch = useDispatch();
    useEffect(() => {
        const params = new RequestParams("query={id, name, code, deliveryoptions}")
        dispatch(loadCustomersAction(params))
    }, [])

    const customers = useSelector(getPossibleCustomers)

    return (
        <div className="px-2 py-4">
            <Select
                title="Kunde"
                data={customers}
                showKey="name"
                renderOption={o => `( ${o.code} ) - ${o.name}`}
                onChangeObjectClass={(c) => dispatch(setCustomerAction(c))}
                value={customer}
            />
            <div className="pt-2">
                <Button onClick={nextStep} disabled={customer === undefined}>
                    <div className="flex items-center">
                        <div>
                            Lieferung erstellen
                        </div>
                        <div className="ml-2">
                            <FaArrowRight />
                        </div>
                    </div>
                </Button>
            </div>
        </div>
    )
}