/**
* Created on Wed Mar 10 2021 15:09:54
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import BoxContentState from "./BoxContentState";

export default class BoxContent {
    
    public id?: number;
    public box?: number;
    public boxCode?: string; 
    constructor(
        public text: string,
        public state: BoxContentState,
    ) {}

    static fromAPI(model?: any): BoxContent {
        const content = new BoxContent(
            model.text,
            BoxContentState.fromAPI(model.state)
        )
        if (model.box) {
            if (typeof(model.box) === "number"){
                content.box = model.box
            } else {
                content.box = model.box.id
                content.boxCode = model.box.code
            }
        }
        content.id = model.id
        return content
    }

    static toAPI(c: BoxContent): object {
        const data = {
            box_id: c.box!,
            text: c.text
        }
        return data
    }
}