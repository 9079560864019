/**
* Created on Wed Mar 10 2021 09:44:54
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import Box from "../../shared/models/Box";
import Pagination from "../../shared/models/Pagination";
import { LoadState } from "../../shared/models/RequestState";
import { loadBoxesAction, loadBoxesSuccessAction, LOAD_BOXES, LOAD_BOXES_SUCCESS } from "../actions/delieveries.actions";

export interface State {
    data: Pagination<Box>,
    load: LoadState,
}

export default function(
    state: State = {
        data: new Pagination<Box>(),
        load: LoadState.init()
    },
    action: ActionType<
        | typeof loadBoxesAction
        | typeof loadBoxesSuccessAction
    >
): State {

    switch (action.type) {
        case LOAD_BOXES:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_BOXES_SUCCESS:
            return update(state, {
                data: {  $set: action.payload },
                load: { $set: LoadState.loaded() }
            })
        default:
            return state
    }
}