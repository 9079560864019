/**
* Created on Sun Apr 18 2021 22:42:32
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import update from 'immutability-helper';
import { ActionType } from "typesafe-actions";
import { LoadState } from "../../shared/models/RequestState";
import StockOverview from "../../shared/models/StockOverview";
import { loadOverviewAction, loadOverviewSuccessAction, LOAD_OVERVIEW, LOAD_OVERVIEW_SUCCESS } from "../actions/overview.actions";


export interface State {
    data: StockOverview,
    load: LoadState
}

export default function (
    state: State = {
        data: new StockOverview(),
        load: LoadState.init()
    },
    action: ActionType<
     | typeof loadOverviewAction
     | typeof loadOverviewSuccessAction
    >
): State {

    switch (action.type) {
        case LOAD_OVERVIEW:
            return update(state, {
                load: { $set: LoadState.loading() }
            })
        case LOAD_OVERVIEW_SUCCESS:
            return update(state, {
                load: { $set: LoadState.loaded() },
                data: { $set: action.payload }
            })
        default:
            return state
    }
}