/**
* Created on Wed Mar 10 2021 14:44:26
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import Box from '../../../shared/models/Box';
import BoxContent from '../../../shared/models/BoxContent';
import BoxContentState from '../../../shared/models/BoxContentState';
import { addBoxContentToDeliveryAction } from '../../actions/delieveries.actions';

interface Props {
    box: Box
}

export default function NewBoxContentInput({
    box
}: Props) {

    const initContent = new BoxContent("", new BoxContentState("INSIDE"))
    const [content, setContent] = useState(initContent)

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const content = new BoxContent("", new BoxContentState("INSIDE"))
        content.box = box.id
        content.text = e.target.value
        content.boxCode = box.code
        setContent(content)
    }

    const dispatch = useDispatch()

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(addBoxContentToDeliveryAction(content))
        setContent(initContent)
    }

    return (
        <form onSubmit={handleOnSubmit}>
            <div className="flex items-center px-2 py-1">
                <button
                    className="p-1 mr-2 text-white rounded shadow bg-adl-2 focus:outline-none disabled:bg-gray-400"
                    disabled={content.text === ""}
                    type="submit"
                >
                    <FaPlus />
                </button>
                <input
                    value={content.text}
                    className="w-full p-1 leading-tight bg-gray-200 border-2 border-gray-200 rounded outline-none focus:outline-none focus:bg-white focus:border-gray-200"
                    onChange={handleOnChange}
                >
                </input>
            </div>
        </form>
    )
}