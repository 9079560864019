/**
* Created on Tue Mar 30 2021 14:55:13
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import React from 'react';
import Toast from './Toast';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import { useSelector } from 'react-redux';
import { getNotifications } from '../../selectors/notify.selectors';

export default function ToastContainer() {

    const notifications = useSelector(getNotifications)
    return (
        <div className="fixed right-0 m-4 z-9999">
            <TransitionGroup>
                {notifications.map((n, i) => (
                    <CSSTransition
                        key={n.uuid}
                        classNames="toast"
                        timeout={300}
                    >
                        <Toast notification={n} />
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </div>
    )
}