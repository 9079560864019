/**
* Created on Mon Feb 01 2021 12:56:21
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import RequestParams from "./RequestParams";


export default class PageParams {

    constructor(
        public page: number = 1,
        public limit: number = 30,
    ){}

    public params(other?: string): string {
        const params = new URLSearchParams(other);
        params.delete('limit')
        params.delete('offset')
        params.set('limit', this.limit.toString())
        params.set('offset', ((this.page - 1) * this.limit).toString())
        return params.toString();
    }

    static fromSearch(search: string, requestParams: RequestParams) {
        const s = new URLSearchParams(search)
        const page = s.get('page')
        if (page) {
            requestParams.page = new PageParams(parseInt(page))
        } else {
            requestParams.page = new PageParams()
        }
    }

}