/**
* Created on Fri Feb 26 2021 09:25:59
* 
* @author: Mark Tschuden <mark@tschuden-itsolutions.de>
*/

import { ActionType, createAction } from "typesafe-actions";
import Customer from "../../shared/models/Customer";
import DeliveryOption from "../../shared/models/DeliveryOption";
import Pagination from "../../shared/models/Pagination";
import RequestParams from "../../shared/models/RequestParams";
import User from "../../shared/models/User";

export const LOAD_CUSTOMERS = '@@customers/LOAD_CUSTOMERS';
export type LOAD_CUSTOMERS = typeof LOAD_CUSTOMERS;

export const LOAD_CUSTOMERS_SUCCESS = '@@customers/LOAD_CUSTOMERS_SUCCESS';
export type LOAD_CUSTOMERS_SUCCESS = typeof LOAD_CUSTOMERS_SUCCESS;

export const ADD_CUSTOMER = '@@customers/ADD_CUSTOMER';
export type ADD_CUSTOMER = typeof ADD_CUSTOMER;

export const ADD_CUSTOMER_SUCCESS = '@@customers/ADD_CUSTOMER_SUCCESS';
export type ADD_CUSTOMER_SUCCESS = typeof ADD_CUSTOMER_SUCCESS;

export const UPDATE_CUSTOMER = '@@customers/UPDATE_CUSTOMER';
export type UPDATE_CUSTOMER = typeof UPDATE_CUSTOMER;

export const UPDATE_CUSTOMER_SUCCESS = '@@customers/UPDATE_CUSTOMER_SUCCESS';
export type UPDATE_CUSTOMER_SUCCESS = typeof UPDATE_CUSTOMER_SUCCESS;

export const DELETE_CUSTOMER = '@@customers/DELETE_CUSTOMER';
export type DELETE_CUSTOMER = typeof DELETE_CUSTOMER;

export const DELETE_CUSTOMER_SUCCESS = '@@customers/DELETE_CUSTOMER_SUCCESS';
export type DELETE_CUSTOMER_SUCCESS = typeof DELETE_CUSTOMER_SUCCESS;

export const LOAD_CUSTOMER = '@@customers/LOAD_CUSTOMER';
export type LOAD_CUSTOMER = typeof LOAD_CUSTOMER;

export const LOAD_CUSTOMER_SUCCESS = '@@customers/LOAD_CUSTOMER_SUCCESS';
export type LOAD_CUSTOMER_SUCCESS = typeof LOAD_CUSTOMER_SUCCESS;

export const LOAD_USERS = '@@customers/LOAD_USERS';
export type LOAD_USERS = typeof LOAD_USERS;

export const LOAD_USERS_SUCCESS = '@@customers/LOAD_USERS_SUCCESS';
export type LOAD_USERS_SUCCESS = typeof LOAD_USERS_SUCCESS;

export const ADD_DELIVERY_OPTION = '@@customers/ADD_DELIVERY_OPTION';
export type ADD_DELIVERY_OPTION = typeof ADD_DELIVERY_OPTION;

export const ADD_DELIVERY_OPTION_SUCCESS = '@@customers/ADD_DELIVERY_OPTION_SUCCESS';
export type ADD_DELIVERY_OPTION_SUCCESS = typeof ADD_DELIVERY_OPTION_SUCCESS;

export const SELECT_DELIVERY_OPTION = '@@customers/SELECT_DELIVERY_OPTION';
export type SELECT_DELIVERY_OPTION = typeof SELECT_DELIVERY_OPTION;

export const UPDATE_DELIVERY_OPTION = '@@customers/UPDATE_DELIVERY_OPTION';
export type UPDATE_DELIVERY_OPTION = typeof UPDATE_DELIVERY_OPTION;

export const UPDATE_DELIVERY_OPTION_SUCCESS = '@@customers/UPDATE_DELIVERY_OPTION_SUCCESS';
export type UPDATE_DELIVERY_OPTION_SUCCESS = typeof UPDATE_DELIVERY_OPTION_SUCCESS;

export const DELETE_DELIVERY_OPTION = '@@customers/DELETE_DELIVERY_OPTION';
export type DELETE_DELIVERY_OPTION = typeof DELETE_DELIVERY_OPTION;

export const DELETE_DELIVERY_OPTION_SUCCESS = '@@customers/DELETE_DELIVERY_OPTION_SUCCESS';
export type DELETE_DELIVERY_OPTION_SUCCESS = typeof DELETE_DELIVERY_OPTION_SUCCESS;

export const loadCustomersAction = createAction(LOAD_CUSTOMERS)<RequestParams>();
export const loadCustomersSuccessAction = createAction(LOAD_CUSTOMERS_SUCCESS)<Pagination<Customer>>();

export const addCustomerAction = createAction(ADD_CUSTOMER)<Customer>();
export const addCustomerSuccessAction = createAction(ADD_CUSTOMER_SUCCESS)<Customer>();

export const updateCustomerAction = createAction(UPDATE_CUSTOMER)<Customer>();
export const updateCustomerSuccessAction = createAction(UPDATE_CUSTOMER_SUCCESS)<Customer>();

export const deleteCustomerAction = createAction(DELETE_CUSTOMER)<Customer>();
export const deleteCustomerSuccessAction = createAction(DELETE_CUSTOMER_SUCCESS)();

export const loadCustomerAction = createAction(LOAD_CUSTOMER)<number>();
export const loadCustomerSuccessAction = createAction(LOAD_CUSTOMER_SUCCESS)<Customer>();

export const loadUsersAction = createAction(LOAD_USERS)<void>();
export const loadUsersSuccessAction = createAction(LOAD_USERS_SUCCESS)<User[]>();

export const addDeliveryOptionAction = createAction(ADD_DELIVERY_OPTION)<DeliveryOption>();
export const addDeliveryOptionSuccessAction = createAction(ADD_DELIVERY_OPTION_SUCCESS)<DeliveryOption>();

export const selectDeliveryOptionAction = createAction(SELECT_DELIVERY_OPTION)<DeliveryOption>();

export const updateDeliveryOptionAction = createAction(UPDATE_DELIVERY_OPTION)<DeliveryOption>();
export const updateDeliveryOptionSuccessAction =  createAction(UPDATE_DELIVERY_OPTION_SUCCESS)<DeliveryOption>();

export const deleteDeliveryOptionAction = createAction(DELETE_DELIVERY_OPTION)<DeliveryOption>();
export const deleteDeliveryOptionSuccessAction =  createAction(DELETE_DELIVERY_OPTION_SUCCESS)<number>();

export type CustomersAction = ActionType<
    | typeof loadCustomersAction
    | typeof loadCustomersSuccessAction
    | typeof addCustomerAction
    | typeof addCustomerSuccessAction
    | typeof loadCustomerAction
    | typeof loadCustomerSuccessAction
    | typeof loadUsersAction
    | typeof loadUsersSuccessAction
    | typeof addDeliveryOptionAction
    | typeof addDeliveryOptionSuccessAction
    | typeof selectDeliveryOptionAction
    | typeof updateDeliveryOptionAction
    | typeof updateDeliveryOptionSuccessAction
    | typeof deleteDeliveryOptionAction
    | typeof deleteDeliveryOptionSuccessAction
>