import { History } from 'history';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from '../shared/reducers/auth.reducer';
import stockReducer from '../stock/reducers/stock.reducer';
import usersReducer from '../users/reducers/users.reducer';
import deliveriesReducers from '../deliveries/reducers/';
import customerReducers from '../customers/reducers/customers.reducers';
import customerReducer from '../customers/reducers/customer.reducer';
import notifyReducer from '../shared/reducers/notify.reducer';
import deliveryReducer from '../deliveries/reducers/delivery.reducer';
import servicesReducer from '../services/reducers/';

export default function createRootReducer(history: History) {
    return combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        stock: stockReducer,
        users: usersReducer,
        deliveries: deliveriesReducers,
        delivery: deliveryReducer,
        customers: customerReducers,
        customer: customerReducer,
        notify: notifyReducer,
        services: servicesReducer,
    });
}

export type AppState = ReturnType<ReturnType<typeof createRootReducer>>;